
import React from "react";
import { Button, Table } from 'react-bootstrap';
import { connect } from "react-redux";
import BootstrapModals from "../components/BootstrapModals";
import CreateAllergen from "../form/CreateAllergen";
import EditAllergen from "../form/EditAllergen";
import { checkToken, deleteAllergens, getAllAllergens, getRestaurantList, getSingleAllergens, getRestaurantOfUser, getRestAllergen } from "../../services/services";
import { ALLERGEN_LIST, RESTAURENT_LIST, SINGLE_ALLERGEN, RESTAURANT_OF_USER, RESTAURANT_OF_ALLERGEN } from "../../constants/actions";
import { confirmAlert } from 'react-confirm-alert';
import { notification } from "../../services/utileServices";

const mapStateToProps = state => {
  return {
    ...state.allergen,
    app: state.app,
    rest: state.restaurant,
    login: state.login
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetAllergen: (token) => {
      getAllAllergens(token).then(result => {
        var payload = result.data;
        dispatch({ type: ALLERGEN_LIST, payload });
        if (result.data.success) {
          notification("Tutti gli allergeni sono caricati correttamente", 'success');
        }
      }).catch(function (err) {
        notification("Errore nel recupero dei dati sugli allergeni " + err.message, 'danger');
      })
    },
    onGetRestaurants: (token) => {
      getRestaurantList(token).then(result => {
        var payload = result.data;
        dispatch({ type: RESTAURENT_LIST, payload });
        if (result.data.success) {
          notification("Recupero dei dati del ristorante riuscito", 'success');
        }
      }).catch(function (err) {
        notification("Errore nel recupero dei dati del ristorante " + err.message, 'danger');
      })
    },
    async onGetSingleAllergen(id) {
      await getSingleAllergens(id).then(result => {
        if (result.data.success) {
          notification("Recupero dei dati dell'allergene singolo riuscito", 'success');
          var payload = result.data;
          dispatch({ type: SINGLE_ALLERGEN, payload })
        }
      }).catch(function (err) {
        notification("Errore durante il recupero dei dati sull'allergene singolo " + err.message, 'danger');
      })
    },

    onDelete: (token, id) => {
      deleteAllergens(token, id).then(result => {
        if (result.data.success) {
          notification("Allergene eliminato con successo", 'success');
          getAllAllergens(token).then(result => {
            var payload = result.data;
            dispatch({ type: ALLERGEN_LIST, payload });
            if (result.data.success) {
              notification("Recupero dei dati sugli allergeni riuscito", 'success');
            }
          }).catch(function (err) {
            notification("Errore nel recupero dei dati sugli allergeni " + err.message, 'danger');
          })
        }
      }).catch(function (err) {
        notification("Errore nell'eliminazione dell'allergene " + err.message, 'danger');
      })
    },

    onGetRestOfUser: (id) => {
      getRestaurantOfUser(id).then(result => {
        if (result.data.success) {
          var payload = result.data;
          dispatch({ type: RESTAURANT_OF_USER, payload });
        }
      }).catch(err => {
        notification("Errore in Ottieni ristorante per utente " + err.message, 'danger');
      })
    },

    onGetRestAllergen: (id) => {
      getRestAllergen(id).then(result => {
        if (result.status) {
          var payload = result.data;
          dispatch({ type: RESTAURANT_OF_ALLERGEN, payload });
        }
      }).catch(err => {
        notification("Errore nell'allergene a riposo " + err.message, 'danger');
      })
    },
    
  }

}

class Allergens extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      AddModalShow: false,
      EditModalShow: false,
      Filter: false,
      RestFilter: false
    }
    this.handleDelete = this.handleDelete.bind(this);
    this.handleCreateAllergen = this.handleCreateAllergen.bind(this);
    this.handleEditAllergen = this.handleEditAllergen.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidMount() {
    const token = checkToken(this.props.app.token);
    this.props.onGetAllergen(token);
    this.props.onGetRestaurants(token);
  }

  handleDelete(id) {
    confirmAlert({
      title: 'Conferma per eliminare',
      message: 'Sei sicuro di voler cancellare questo.',
      buttons: [
        {
          label: 'sì',
          onClick: () => this.props.onDelete(this.props.app.token, id)
        },
        {
          label: 'No',
          onClick: () => console.log("closed")
        }
      ]
    });
  }


  handleCreateAllergen() {
    this.setState({ AddModalShow: !this.state.AddModalShow });
    this.props.onGetRestaurants(this.props.app.token);
  }

  handleEditAllergen(id) {
    var that = this;
    this.props.onGetSingleAllergen(id).then(function () {
      that.setState({ EditModalShow: !that.state.EditModalShow });
    })
  }

  handleSelect = (e) => {
    var rest_id = e.target.value;
    if (rest_id != '' && rest_id != undefined) {
      this.setState({ Filter: true });
      this.props.onGetRestAllergen(rest_id)
    } else {
      this.setState({ Filter: false });
      this.props.onGetAllergen(this.props.app.token);
    }

  }


  render() {
    let allergenList = [];
    let restlist = '';
    if (this.props) {
      if (this.props.allergen && !this.state.Filter) {
        allergenList = this.props.allergen;
      } else if (this.props.restallergen && this.props.restallergen) {
        allergenList = this.props.restallergen;
      }
    }

    if (this.props.rest && this.props.rest.restaurant) {
      var rests = this.props.rest.restaurant;
      restlist = rests.map((rest, key) => (
        <option key={key} value={rest.RESTAURANT_ID}>{rest.NAME_ITALIAN}</option>
      ))
    }

  return(
      <>
  <div className="w-full" style={{ minHeight: '100vh' }}>
    <div className="px-3 py-4">
      <div className="d-flex justify-content-between items-center">
        <h4>Allergeni</h4>
        <button type="button" className="btn btn-primary" onClick={() => this.handleCreateAllergen()}>
        Aggiungi Allergeni
        </button>
      </div>
        <div style={{ width: '400px' }} >
          <div className="py-4">
            <h3>Ordina</h3>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <select className="form-control" name="rest_id" onChange={(e) => this.handleSelect(e)}>
                  <option value="">Tutti i ristoranti</option>
                  {
                    restlist
                  }
                </select>
              </div>
            </div>
          </div>
        </div>
    </div>
    <Table responsive className="align-items-center table-flush">
      <thead className="thead-light">

        <tr>
          <th>SNo</th>
          <th>Nome Ristorante</th>
          <th>Nome Allergene</th>
          <th>Azioni</th>
        </tr>
      </thead>
      <tbody className="bg-light">
        {
          (allergenList && allergenList != '' && allergenList != undefined) ? allergenList.map((item, key) =>
            <tr key={key}>
              <td>{key + 1}</td>
              <td>{item.NAME_ITALIAN}</td>
              <td>{item.ALLERGEN_ITALIAN}</td>
              <td>
                <a className="btn btn-success py-2 px-3 m-2" onClick={() => this.handleEditAllergen(item.ALLERGEN_ID)}>Modifica</a>
                <a className="btn btn-danger py-2 px-3" onClick={() => this.handleDelete(item.ALLERGEN_ID)}>Elimina</a>
              </td>
            </tr>
          ) : <tr><th colSpan={5} className="text-center">Nessun record disponibile</th></tr>
        }
      </tbody>
    </Table>
    <BootstrapModals component={<CreateAllergen onClose={() => this.setState({ AddModalShow: !this.state.AddModalShow })} />} heading="Aggiungi Allergeni" show={this.state.AddModalShow}
      onHide={() => this.setState({ AddModalShow: !this.state.AddModalShow })} className="allergen_create_modal" />
    <BootstrapModals component={<EditAllergen onClose={() => this.setState({ EditModalShow: !this.state.EditModalShow })} />} heading="Modifica Allergeni" show={this.state.EditModalShow}
      onHide={() => this.setState({ EditModalShow: !this.state.EditModalShow })} className="allergen_update_modal" />

  </div>

      </>
    );
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Allergens);
