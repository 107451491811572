
import React from "react";
import { Col, Row, Form, Button, Container } from '@themesberg/react-bootstrap';
import BgImage from "../../assets/img/illustrations/signin.svg";
import { connect } from "react-redux";
import { getRestaurantList, updateRastaurant } from "../../services/services";
import { RESTAURENT_LIST } from "../../constants/actions";
import { reduxForm, Field } from "redux-form";
import { renderField, textAreaField, notification, required } from "../../services/utileServices";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import Loader from "react-js-loader";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileEncode, FilePondPluginFileValidateType, FilePondPluginFileValidateSize);


const mapStateToProps = state => {
  return {
    ...state.restaurant,
    app: state.app,
    user: state.user,
    login: state.login
  }
}

const mapDispatchToProps = dispatch => {
  return {
    async onUpdateRestaurant(token, payload) {
      await updateRastaurant(token, payload).then(result => {
        if (result.data.success) {
          notification('Ristorante aggiornato correttamente', 'success');

          getRestaurantList(token).then(result => {
            if (result.data.success) {
              var payload = result.data;
              dispatch({ type: RESTAURENT_LIST, payload });
              console.log('fetch successfully');
            }
          }).catch(function (err) {
            notification('Errore nel recupero dei dati del ristorante ' + err.message, 'danger');
          })
        }
      }).catch(function (err) {
        notification("Errore nell'aggiornamento del ristorante " + err.message, 'danger');
      })
    }
  }
}

class CreateRestaurant extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notes: {},
      ldesc: {},
      loader: false
    }
    this.onSubmitting = this.onSubmitting.bind(this);
    this.handleTextArea = this.handleTextArea.bind(this);
    this.handleTextArea2 = this.handleTextArea2.bind(this);
  }

  componentDidMount() {
    if (this.props.restaurantdata) {
      let singleData = this.props.restaurantdata;
      this.props.autofill('id', singleData.RESTAURANT_ID);
      this.props.autofill('rnameen', singleData.NAME.en);
      this.props.autofill('rnameit', singleData.NAME.it);
      this.props.autofill('rnamefr', singleData.NAME.fr);
      this.props.autofill('rnamede', singleData.NAME.de);
      this.props.autofill('rnamees', singleData.NAME.es);
      this.props.autofill('owner_id', singleData.OWNER_ID);
      this.props.autofill('rtypeen', singleData.RESTAURANT_TYPE.en);
      this.props.autofill('rtypeit', singleData.RESTAURANT_TYPE.it);
      this.props.autofill('rtypede', singleData.RESTAURANT_TYPE.de);
      this.props.autofill('rtypefr', singleData.RESTAURANT_TYPE.fr);
      this.props.autofill('rtypees', singleData.RESTAURANT_TYPE.es);
      this.props.autofill('rslug', singleData.SLUG);
      this.props.autofill('sdescen', singleData.TEXT_SHORT.en);
      this.props.autofill('sdescit', singleData.TEXT_SHORT.it);
      this.props.autofill('sdescfr', singleData.TEXT_SHORT.fr);
      this.props.autofill('sdescde', singleData.TEXT_SHORT.de);
      this.props.autofill('sdesces', singleData.TEXT_SHORT.es);
      this.props.autofill('phone', singleData.MOBILE_NUMBER);
      this.setState({ notes: singleData.NOTES })
      this.setState({ ldesc: singleData.TEXT_LONG});
    }
  }

  async onSubmitting(values) {
    this.setState({ loader: true });
    let Image64 = '';
    let logo64 = '';
    if (this.pond.getFiles().length > 0) {
      Image64 = this.pond.getFiles()[0].getFileEncodeBase64String();
    }
    if (this.pondlogo.getFiles().length > 0) {
      logo64 = this.pondlogo.getFiles()[0].getFileEncodeBase64String();
    }

    let reg_form = {
      RESTAURANT_ID: values.id, NAME: values.rnameen, RESTAURANT_TYPE: values.rtypeen, SLUG: values.rslug, IMG_SPLASH_SCREEN: Image64, NOTES: values.rnotesen?values.rnotesen:this.state.notes.en, IMG_LOGO: logo64,
      NAME_FRENCH: values.rnamefr, NAME_ITALIAN: values.rnameit, NAME_GERMEN: values.rnamede, NAME_SPANISH: values.rnamees, RESTAURANT_TYPE_FR: values.rtypefr, RESTAURANT_TYPE_IT: values.rtypeit, RESTAURANT_TYPE_SP: values.rtypees
      , RESTAURANT_TYPE_GR: values.rtypede, NOTES_FR: values.rnotesfr?values.rnotesfr:this.state.notes.fr, NOTES_GR: values.rnotesde?values.rnotesde:this.state.notes.de, NOTES_SP: values.rnoteses?values.rnoteses:this.state.notes.es, NOTES_IT: values.rnotesit?values.rnotesit:this.state.notes.it, TEXT_SHORT_EN: values.sdescen ? values.sdescen : values.sdesc, TEXT_SHORT_ES: values.sdesces, TEXT_SHORT_FR: values.sdescfr,
      TEXT_SHORT_DE: values.sdescde, TEXT_SHORT_IT: values.sdescit, TEXT_LONG_EN: values.ldescen?values.ldescen:this.state.ldesc.en, TEXT_LONG_ES: values.ldesces?values.ldesces:this.state.ldesc.es, TEXT_LONG_FR: values.ldescfr?values.ldescfr:this.state.ldesc.fr, TEXT_LONG_DE: values.ldescde?values.ldescde: this.state.ldesc.de, TEXT_LONG_IT: values.ldescit?values.ldescit:this.state.ldesc.it, MOBILE_NUMBER: values.phone
    }

    if(this.props.login.admin){
      reg_form.ID = values.owner_id;
    }

    let that = this;
    const UpdateResto = async (data) => {
      await this.props.onUpdateRestaurant(this.props.app.token, data).then(function () {
        that.setState({ loader: false });
        that.props.onClose();
      });
    }
    if (Image64 != '' && Image64 != undefined && logo64 != '' && logo64 != undefined) {
      UpdateResto(reg_form);
    } else {
      that.setState({ loader: false });
      notification('Si prega di caricare entrambe le immagini', 'warning');
    }


  }


  handleTextArea = (e) => {
    let note = e.target.value;
    this.setState({ notes: note });
  }
  handleTextArea2 = (e) => {
    let long = e.target.value;
    this.setState({ ldesc: long });
  }

  render() {
    const { handleSubmit, submitting, error } = this.props;
    let singleData;
    if (this.props.restaurantdata) {
      singleData = this.props.restaurantdata;
    }
    let options = [];
    if (this.props && this.props.user.user) {
      const users = this.props.user.user;
      options = users.map((single, key) => (
        <option value={single.ID} key={key}>{single.FIRST_NAME + " " + single.LAST_NAME}</option>
      ))
    }
    const pond = {
      multiple: false,
      name: 'image',
      allowMultiple: false,
      instantUpload: false,
      fetch: null,
      revert: null,
      allowFileEncode: true,
      allowFileSizeValidation: true,
      maxFileSize: 1024000,
      labelMaxFileSizeExceeded: 'File is too large',
      labelIdle: "Trascina e rilascia qui  i tuoi file o Sfoglia"
    }

    const pondlogo = {
      multiple: false,
      name: 'logo',
      allowMultiple: false,
      instantUpload: false,
      fetch: null,
      revert: null,
      allowFileEncode: true,
      allowFileSizeValidation: true,
      maxFileSize: 1024000,
      labelMaxFileSizeExceeded: 'File is too large',
      labelIdle: "Trascina e rilascia qui  i tuoi file o Sfoglia"
    }

    return (
      <main>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h3 className="mb-0">Modifica Ristorante</h3>
                  </div>
                  {!this.state.loader ?
                    <Form className="mt-4" onSubmit={handleSubmit(this.onSubmitting)}>
                      {error && <span className='alert-danger'>{error}</span>}
                      <Field name="id" component={renderField} type="hidden" />
                      <Form.Group id="rname" className="mb-4">
                        <Form.Label>Nome Ristorante (it)</Form.Label>
                        <Field name="rnameit" component={renderField} type="text" label="Nome Ristorante" validate={[required]} />
                      </Form.Group>
                      <Form.Group id="rname" className="mb-4">
                        <Form.Label>Nome Ristorante (en)</Form.Label>
                        <Field name="rnameen" component={renderField} type="text" label="Nome Ristorante" validate={[required]} />
                      </Form.Group>
                      <Form.Group id="rname" className="mb-4">
                        <Form.Label>Nome Ristorante (fr)</Form.Label>
                        <Field name="rnamefr" component={renderField} type="text" label="Nome Ristorante" validate={[required]} />
                      </Form.Group>
                      <Form.Group id="rname" className="mb-4">
                        <Form.Label>Nome Ristorante (de)</Form.Label>
                        <Field name="rnamede" component={renderField} type="text" label="Nome Ristorante" validate={[required]} />
                      </Form.Group>
                      <Form.Group id="rname" className="mb-4">
                        <Form.Label>Nome Ristorante (es)</Form.Label>
                        <Field name="rnamees" component={renderField} type="text" label="Nome Ristorante" validate={[required]} />
                      </Form.Group>
                      <Form.Group id="rtype" className="mb-4">
                        <Form.Label>TIPO DI RISTORANTE (it)</Form.Label>
                        <Field name="rtypeit" component={renderField} type="text" label="TIPO DI RISTORANTE" validate={[required]} />
                      </Form.Group>
                      <Form.Group id="rtype" className="mb-4">
                        <Form.Label>TIPO DI RISTORANTE (en)</Form.Label>
                        <Field name="rtypeen" component={renderField} type="text" label="TIPO DI RISTORANTE" validate={[required]} />
                      </Form.Group>
                      <Form.Group id="rtype" className="mb-4">
                        <Form.Label>TIPO DI RISTORANTE (fr)</Form.Label>
                        <Field name="rtypefr" component={renderField} type="text" label="TIPO DI RISTORANTE" validate={[required]} />
                      </Form.Group>
                      <Form.Group id="rtype" className="mb-4">
                        <Form.Label>TIPO DI RISTORANTE (de)</Form.Label>
                        <Field name="rtypede" component={renderField} type="text" label="TIPO DI RISTORANTE" validate={[required]} />
                      </Form.Group>
                      <Form.Group id="rtype" className="mb-4">
                        <Form.Label>TIPO DI RISTORANTE (es)</Form.Label>
                        <Field name="rtypees" component={renderField} type="text" label="TIPO DI RISTORANTE" validate={[required]} />
                      </Form.Group>
                      <Form.Group id="user" className={this.props.login.admin?"mb-4":'d-none'}>
                          <Form.Label>Seleziona Proprietario</Form.Label>
                          <Field className="form-control"
                            name="owner_id"
                            type="select"
                            component="select"
                            onChange={() => this.handleSelect}
                           >
                            <option value="">Seleziona Proprietario</option>
                            {
                              options
                            }
                          </Field>
                        </Form.Group>
                      <Form.Group id="rslug" className="mb-4">
                        <Form.Label>percorso Url </Form.Label>
                        <Field name="rslug" component={renderField} label="percorso Url" type="text" validate={[required]} />
                      </Form.Group>
                      <Form.Group id="rnotes" className="mb-4">
                        <Form.Label>Note (it)</Form.Label>
                        <Field name="rnotesit" component={textAreaField} dataValue={this.state.notes.it} onChange={(e) => this.handleTextArea(e)} label="Inserisci informazioni  sul tuo ristorante" />
                      </Form.Group>
                      <Form.Group id="rnotes" className="mb-4">
                        <Form.Label>Note (en)</Form.Label>
                        <Field name="rnotesen" component={textAreaField} dataValue={this.state.notes.en} onChange={(e) => this.handleTextArea(e)} label="Inserisci informazioni  sul tuo ristorante" />
                      </Form.Group>
                      <Form.Group id="rnotes" className="mb-4">
                        <Form.Label>Note (fr)</Form.Label>
                        <Field name="rnotesfr" component={textAreaField} dataValue={this.state.notes.fr} onChange={(e) => this.handleTextArea(e)} label="Inserisci informazioni  sul tuo ristorante" />
                      </Form.Group>
                      <Form.Group id="rnotes" className="mb-4">
                        <Form.Label>Note (de)</Form.Label>
                        <Field name="rnotesde" component={textAreaField} dataValue={this.state.notes.de} onChange={(e) => this.handleTextArea(e)} label="Inserisci informazioni  sul tuo ristorante" />
                      </Form.Group>
                      <Form.Group id="rnotes" className="mb-4">
                        <Form.Label>Note (es)</Form.Label>
                        <Field name="rnoteses" component={textAreaField} dataValue={this.state.notes.es} onChange={(e) => this.handleTextArea(e)} label="Inserisci informazioni  sul tuo ristorante" />
                      </Form.Group>
                      <Form.Group id="ldesc" className="mb-4">
                        <Form.Label>Descrizione lunga (it)</Form.Label>
                        <Field name="ldescit" type="textarea" component={textAreaField} dataValue={this.state.ldesc.it} onChange={(e) => this.handleTextArea2(e)} label="Scrivi una descrizione qui" />
                      </Form.Group>
                      <Form.Group id="ldesc" className="mb-4">
                        <Form.Label>Descrizione lunga (en)</Form.Label>
                        <Field name="ldescen" type="textarea" component={textAreaField} dataValue={this.state.ldesc.en} onChange={(e) => this.handleTextArea2(e)} label="Scrivi una descrizione qui" />
                      </Form.Group>
                      <Form.Group id="ldesc" className="mb-4">
                        <Form.Label>Descrizione lunga (fr)</Form.Label>
                        <Field name="ldescfr" type="textarea" component={textAreaField} dataValue={this.state.ldesc.fr} onChange={(e) => this.handleTextArea2(e)} label="Scrivi una descrizione qui" />
                      </Form.Group>
                      <Form.Group id="ldesc" className="mb-4">
                        <Form.Label>Descrizione lunga (de)</Form.Label>
                        <Field name="ldescde" type="textarea" component={textAreaField} dataValue={this.state.ldesc.de} onChange={(e) => this.handleTextArea2(e)} label="Scrivi una descrizione qui" />
                      </Form.Group>
                      <Form.Group id="ldesc" className="mb-4">
                        <Form.Label>Descrizione lunga (es)</Form.Label>
                        <Field name="ldesces" type="textarea" component={textAreaField} dataValue={this.state.ldesc.es} onChange={(e) => this.handleTextArea2(e)} label="Scrivi una descrizione qui" />
                      </Form.Group>
                      <Form.Group id="sdesc" className="mb-4">
                        <Form.Label>Piccola descrizione (it)</Form.Label>
                        <Field name="sdescit" type="text" component={renderField} label="Scrivi una descrizione qui" validate={[required]} />
                      </Form.Group>
                      <Form.Group id="sdesc" className="mb-4">
                        <Form.Label>Piccola descrizione (en)</Form.Label>
                        <Field name="sdescen" type="text" component={renderField} label="Scrivi una descrizione qui" validate={[required]} />
                      </Form.Group>
                      <Form.Group id="sdesc" className="mb-4">
                        <Form.Label>Piccola descrizione (fr)</Form.Label>
                        <Field name="sdescfr" type="text" component={renderField} label="Scrivi una descrizione qui" validate={[required]} />
                      </Form.Group>
                      <Form.Group id="sdesc" className="mb-4">
                        <Form.Label>Piccola descrizione (de)</Form.Label>
                        <Field name="sdescde" type="text" component={renderField} label="Scrivi una descrizione qui" validate={[required]} />
                      </Form.Group>
                      <Form.Group id="sdesc" className="mb-4">
                        <Form.Label>Piccola descrizione (es)</Form.Label>
                        <Field name="sdesces" type="text" component={renderField} label="Scrivi una descrizione qui" validate={[required]} />
                      </Form.Group>
                      <Form.Group id="sdesc" className="mb-4">
                          <Form.Label>Numero di telefono</Form.Label>
                          <Field name="phone" type="tel" component={renderField} validate={[required]} label="Inserisci il tuo numero di telefono" />
                        </Form.Group>
                      <Form.Group id="rsplash" className="mb-4">
                        <Form.Label>Carica immagine</Form.Label>
                        <FilePond  {...pond} ref={ref => (this.pond = ref)} files={[{ source: (singleData && singleData.IMG_SPLASH_SCREEN != '') ? process.env.REACT_APP_API_URL + singleData.IMG_SPLASH_SCREEN : '/images.jpg' }]} acceptedFileTypes={['image/*']} />
                      </Form.Group>
                      <Form.Group id="rlogo" className="mb-4">
                        <Form.Label>Carica il tuo Logo</Form.Label>
                        <FilePond  {...pondlogo} ref={ref => (this.pondlogo = ref)} files={[{ source: (singleData && singleData.IMG_LOGO != '') ? process.env.REACT_APP_API_URL + singleData.IMG_LOGO : '/images.png' }]} acceptedFileTypes={['image/*']} />
                      </Form.Group>

                      <Button variant="primary" disabled={submitting} type="submit" className="w-100">
                        Applica
                      </Button>
                    </Form> : <div className="d-flex align-items-center justify-content-center" style={{ height: '100vh' }}><Loader type="bubble-scale" bgColor={"#BE1622"} color={'#BE1622'} size={100} /></div>}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    );
  }
};

CreateRestaurant = connect(mapStateToProps, mapDispatchToProps)(CreateRestaurant);

export default reduxForm({
  form: 'update restaurant form'
})(CreateRestaurant);
