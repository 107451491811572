import { ORDER_DETAILS, REST_ORDER_DETAILS } from "../constants/actions";

export default (state = [], action) =>{
    switch(action.type){
        case ORDER_DETAILS:
            return{
                ...state,
                orders: action.payload.data
            }
        case REST_ORDER_DETAILS:
            return{
                ...state,
                restorder: action.payload.data
            }        
        default:
            {
                return {
                    ...state,
                };
            }
    }
}