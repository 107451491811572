import React from "react";
import { Button, Table } from 'react-bootstrap';
import { connect } from "react-redux";
import BootstrapModals from "../components/BootstrapModals";
import { deleteRastaurant, getReataurantById, getRestaurantList, getAllUsers, checkToken, getRestaurantOfUser } from "../../services/services";
import { RESTAURANT_OF_USER, RESTAURENT_LIST, SINGLE_RESTAURANT, USER_LIST } from "../../constants/actions";
import CreateRestaurant from "../form/CreateRestaurant";
import EditRestaurant from '../form/EditRestaurant';
import { confirmAlert } from 'react-confirm-alert';
import { notification } from '../../services/utileServices';


const mapStateToProps = state => {
  return {
    ...state.restaurant,
    app: state.app,
    login: state.login,
    user: state.user
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onGetRestaurants: (token) => {
      getRestaurantList(token).then(result => {
        if (result.data.success) {
          notification('Tutti i dati del ristorante sono stati recuperati correttamente', 'success');
          var payload = result.data;
          dispatch({ type: RESTAURENT_LIST, payload });
        }
      }).catch(function (err) {
        notification('Errore nel recupero dei dati del ristorante ' + err.message, 'danger');
      })
    },
    async onGetSingleRestaurant(id) {
      await getReataurantById(id).then(result => {
        if (result.data.success) {
          notification('Recupero dei dati del singolo ristorante riuscito', 'success');
          var payload = result.data;
          dispatch({ type: SINGLE_RESTAURANT, payload });
        }
      }).catch(function (err) {
        notification('Errore durante il recupero dei dati del singolo ristorante ' + err.message, 'danger');
      });
    },

    onDelete: (token, id) => {
      deleteRastaurant(token, id).then(result => {
        if (result.data.success) {
          notification('Ristorante eliminato con successo', 'success');
          getRestaurantList(token).then(result => {
            if (result.data.success) {
              var payload = result.data;
              dispatch({ type: RESTAURENT_LIST, payload });
              notification('Tutti i dati del ristorante sono stati recuperati correttamente', 'success');
            }
          }).catch(function (err) {
            notification('Errore nel recupero dei dati del ristorante ' + err.message, 'danger');
          })
        }
      }).catch(function (err) {
        notification('Errore in Elimina ristorante ' + err.message, 'danger');
      })
    },
    onGetUserList: () => {
      getAllUsers().then(result => {
        if (result.data.success) {
          // notification('All user data fetch successfully', 'success');
          var payload = result.data;
          dispatch({ type: USER_LIST, payload });
        }
      }).catch(function (err) {
        notification("Errore nei dati di recupero dell'utente " + err.message, 'danger');
      })
    },
    onGetRestOfUser: (token, id) => {
      getRestaurantOfUser(token, id).then(result => {
        if (result.data.success) {
          var payload = result.data;
          dispatch({ type: RESTAURANT_OF_USER, payload });
        }
      }).catch(err => {
        notification('Errore nel recupero dei dati del ristorante degli utenti ' + err.message, 'danger');
      })
    },
  }
}

class RestaurantList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      AddModalShow: false,
      EditModalShow: false,
      Filter: false,
      Rest: false,
    }
    this.handleDelete = this.handleDelete.bind(this);
    this.onGetRestaurantById = this.onGetRestaurantById.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidMount() {
    const token = checkToken(this.props.app.token);
    this.props.onGetRestaurants(token);
    this.props.onGetUserList();
  }

  handleSelect = (e) => {
    const token = checkToken(this.props.app.token);
    var user_id = e.target.value;
    if (user_id != '' && user_id != undefined) {
      this.setState({ Filter: true });
      this.props.onGetRestOfUser(token, user_id);
    } else {
      this.setState({ Filter: false });
      this.props.onGetRestaurants(token);
    }
  }

  handleDelete(id) {
    confirmAlert({
      title: 'Conferma per eliminare',
      message: 'Sei sicuro di voler cancellare questo.',
      buttons: [
        {
          label: 'sì',
          onClick: () => this.props.onDelete(this.props.app.token, id)
        },
        {
          label: 'No',
          onClick: () => console.log("closed")
        }
      ]
    });
  }

  onGetRestaurantById(update_id) {
    var that = this;
    this.props.onGetSingleRestaurant(update_id).then(function () {
      that.setState({ EditModalShow: !that.state.EditModalShow });
    });
  }

  render() {
    let restaurantList = [];
    let userList = [];

    if (this.props) {
      if (this.props.restaurant && !this.state.Filter) {
        restaurantList = this.props.restaurant;
      } else if (this.props.user_rest && this.state.Filter) {
        restaurantList = this.props.user_rest;
      }
    }
    if (this.props.user.user) {
      let users = this.props.user.user;
      userList = users.map((user, key) => (
        <option key={key} value={user.ID}>{user.FIRST_NAME + " " + user.LAST_NAME}</option>
      ))
    }


    return (
      <>
        <>
          <div className="w-full" style={{ minHeight: '100vh' }}>
            <div className="px-3 py-4">
              <div className="d-flex justify-content-between items-center">
                <h4>Elenco dei ristoranti</h4>
                <button type="button" className={this.props.login.admin?"btn btn-primary":'d-none'} onClick={() => this.setState({ AddModalShow: !this.state.AddModalShow })}>
                Aggiungi Ristorante
                </button>
              </div>
                <div className={this.props.login.admin?"py-4":'d-none'}>
                  <h3>Ordina</h3>
                  <div className="py-4" style={{ width: '150px' }}>
                    <select className="form-control" name="user_id" onChange={(e) => this.handleSelect(e)}>
                      <option value="">Tutti gli utenti</option>
                      {
                        userList
                      }
                    </select>
                  </div>
                </div>
            </div>
            <Table responsive className="align-items-center table-flush">
              <thead className="thead-light">
                <tr>
                  <th>SNo</th>
                  <th>Nome Ristorante</th>
                  <th>NOME UTENTE</th>
                  <th>Tipo di ristorante</th>
                  <th>percorso Url</th>
                  <th>Logo</th>
                  <th>Azioni</th>
                </tr>
              </thead>
              <tbody>
                {
                  ((restaurantList && restaurantList != '' && restaurantList != undefined) ? restaurantList.map((restList, key) => (
                    <tr key={key}>
                      <td>{key + 1}</td>
                      <td>{restList.NAME_ITALIAN}</td>
                      <td>{restList.FIRST_NAME + " " + restList.LAST_NAME}</td>
                      <td>{restList.RESTAURANT_TYPE_IT}</td>
                      <td>{restList.SLUG}</td>
                      <td><img src={`${process.env.REACT_APP_API_URL + restList.IMG_LOGO}`} alt="rest-logo" style={{ width: '100px', height: '100px' }} /></td>
                      <td>
                        <a className="btn btn-success py-2 px-3 m-2" onClick={() => this.onGetRestaurantById(restList.RESTAURANT_ID)}>Modifica</a>
                        {this.props.login.admin ? <a className="btn btn-danger py-2 px-3" onClick={() => this.handleDelete(restList.RESTAURANT_ID)}>Elimina</a> : null}
                      </td>
                    </tr >
                  )) : <tr><th colSpan={7} className="text-center">Nessun record disponibile</th></tr>)
                }

              </tbody>
            </Table>
            <BootstrapModals component={<CreateRestaurant onClose={() => this.setState({ AddModalShow: !this.state.AddModalShow })} />} heading="Aggiungi Ristorante" show={this.state.AddModalShow} onHide={() => this.setState({ AddModalShow: !this.state.AddModalShow })} className="rest_create_modal" />
            <BootstrapModals component={<EditRestaurant edit={this.state.update_id} onClose={() => this.setState({ EditModalShow: !this.state.EditModalShow })} />} heading="Modifica ristorante" show={this.state.EditModalShow} onHide={() => this.setState({ EditModalShow: !this.state.EditModalShow })} className="rest_update_modal" />
          </div>
        </>

      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantList);
