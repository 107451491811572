
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Nav, Form, Image, Navbar, Dropdown, Container, InputGroup } from '@themesberg/react-bootstrap';
import Profile3 from "../assets/img/team/profile-picture-3.jpg";
import { connect } from "react-redux";
import { getSessionItem, removeSessionItem } from "../helpers/sessionHelper";
import { APP_LOAD } from "../constants/actions";
import { Store } from "react-notifications-component";
import { logout } from "../services/services";
import { Link } from 'react-router-dom';
import { Routes } from "../routes";

const mapStateToProps = state => {
  const { token, user, fname, lname, id, profile } = getSessionItem();
  return {
    ...state.app,
    login: state.login,
    token: token,
    user: user,
    fname: fname,
    lname: lname,
    profile: profile,
    sdata: state.settingdata
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLogOut: (token) => {
      logout(token).then(result => {
        if (result.status) {
          var payload = { token: null, user: null };
          dispatch({ type: APP_LOAD, payload });
          Store.addNotification({
            message: "Disconnessione riuscita",
            type: 'success',
            container: 'top-right',
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 3000
            }
          })
        }
      })

    }
  }
}

class Navbars extends React.Component {
  constructor(props) {
    super(props);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleLogout() {
    removeSessionItem()
    this.props.onLogOut(this.props.token);
  }

  render() {
    let fname = '';
    let lname = '';
    if (this.props.sdata.adminSettingData) {
      fname = this.props.sdata.adminSettingData.FIRST_NAME;
      lname = this.props.sdata.adminSettingData.LAST_NAME;
    }
    return (
      <Navbar variant="dark" expanded className="px-3 mt-3 py-2 bg-light">
        <Container fluid className="px-0">
          <div className="d-flex justify-content-between w-100">
            <div className="d-flex align-items-center">
              <Form className="navbar-search">
                <Form.Group id="topbarSearch">
                  <InputGroup className="input-group-merge search-bar">
                    <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                    <Form.Control type="text" placeholder="Cerca" />
                  </InputGroup>
                </Form.Group>
              </Form>
            </div>
            <Nav className="align-items-center">
              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                  <div className="media d-flex align-items-center">
                    {/* <Image src={Profile3} className="user-avatar md-avatar rounded-circle" /> */}
                    <img src={`${process.env.REACT_APP_API_URL + this.props.profile}`} className="user-avatar md-avatar rounded-circle" alt="profile-image" />
                    <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                      <span className="mb-0 font-small fw-bold">{fname != '' ? fname : this.props.fname} {lname != '' ? lname : this.props.lname}</span>
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                  <Dropdown.Item className="fw-bold" as={Link} to={Routes.Settings.path}>
                      My Profile
                  </Dropdown.Item>
                  <Dropdown.Item className="fw-bold" onClick={() => this.handleLogout()}>
                    <span>Logout</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </div>
        </Container>
      </Navbar>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbars);
