
import React from "react";
import { Table } from 'react-bootstrap';
import { connect } from "react-redux";
import { getOrderDetails, checkToken, getRestaurantList, deleteOrder, getOrderDetailsByRestId } from "../../services/services";
import { ORDER_DETAILS, RESTAURENT_LIST, REST_ORDER_DETAILS } from "../../constants/actions";
import { notification } from "../../services/utileServices";
import ItemDetails from "../../components/ItemDetails";
import BootstrapModals from "../components/BootstrapModals";
import { confirmAlert } from 'react-confirm-alert';

const mapStateToProps = state => {
    return {
        ...state.orders,
        app: state.app,
        restaurant: state.restaurant,
        login: state.login
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onGetOrderDetails: (token) => {
            getOrderDetails(token).then(result => {
                if (result.data.success) {
                    let payload = {};
                    payload = result.data;
                    dispatch({ type: ORDER_DETAILS, payload });
                    notification('Tutti gli ordini sono stati recuperati correttamente', 'success');
                }
            }).catch(err => {
                notification("Errore nel recupero dei dettagli dell'ordine " + err.message, 'danger');
            })
        },
        onGetRestaurants: (token) => {
            getRestaurantList(token).then(result => {
                var payload = result.data;
                dispatch({ type: RESTAURENT_LIST, payload });
                if (result.data.success) {
                    console.log('Recupero del ristorante riuscito');
                }
            }).catch(function (err) {
                notification("Errore nel recupero dei dati del ristorante " + err.message, 'danger');
            })
        },

        onGetRestOrders: (token, id) => {
            getOrderDetailsByRestId(token, id).then(result => {
                if (result.data && result.data.success) {
                    let payload = {};
                    payload = result.data;
                    dispatch({ type: REST_ORDER_DETAILS, payload });
                    notification("I dettagli dell'ordine del ristorante sono stati recuperati correttamente", 'success');
                } else {
                    notification(result.data.error, 'warning');
                }
            }).catch(err => {
                notification("Errore durante il recupero dei dati dell'ordine del ristorante " + err.message, 'danger');
            })
        },
        onDelete: (token, id) => {
            deleteOrder(token, id).then(result => {
                if (result.data.success) {
                    notification("Ordine eliminato con successo", 'success');
                    getOrderDetails(token).then(result => {
                        var payload = result.data;
                        dispatch({ type: ORDER_DETAILS, payload });
                        if (result.data.success) {
                            console.log("Tutti i dati dell'ordine vengono recuperati correttamente")
                        }
                    }).catch(function (err) {
                        notification("Errore nel recupero dei dati dell'ordine " + err.message, 'danger');
                    })
                }
            }).catch(function (err) {
                notification("Errore nell'ordine di eliminazione " + err.message, 'danger');
            })
        },

    }
};

class OrderDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Filter: false,
            ShowModal: false,
            Items: []
        }
        this.handleSelect = this.handleSelect.bind(this);
        this.onSetItemDetails = this.onSetItemDetails.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }
    componentDidMount() {
        const token = checkToken(this.props.app.token);
        this.props.onGetOrderDetails(token);
        this.props.onGetRestaurants(token);
    }

    handleSelect = (e) => {
        var rest_id = e.target.value;
        if (rest_id != '' && rest_id != undefined) {
            this.setState({ Filter: true });
            this.props.onGetRestOrders(this.props.app.token, rest_id)
        } else {
            this.setState({ Filter: false });
            this.props.onGetOrderDetails(this.props.app.token);
        }
    }

    handleDelete(id) {
        confirmAlert({
            title: 'Conferma per eliminare',
            message: 'Sei sicuro di voler cancellare questo.',
            buttons: [
                {
                    label: 'sì',
                    onClick: () => this.props.onDelete(this.props.app.token, id)
                },
                {
                    label: 'No',
                    onClick: () => console.log("closed")
                }
            ]
        });
    }

    onSetItemDetails(data) {
        this.setState({ Items: data });
        this.setState({ ShowModal: !this.state.ShowModal });
    }

    render() {
        let orders = [];
        let restOrders = [];
        let restlist = '';
        if (this.props) {
            if (this.props.orders && !this.state.Filter) {
                orders = this.props.orders;
            } else if (this.props.restorder && this.state.Filter) {
                orders = this.props.restorder;
            }
        }

        if (this.props && this.props.restaurant && this.props.restaurant.restaurant) {
            var rests = this.props.restaurant.restaurant;
            restlist = rests.map((rest, key) => (
                <option key={key} value={rest.RESTAURANT_ID}>{rest.NAME}</option>
            ))
        }

        return (
            <>
                <div className="w-full" style={{ minHeight: '100vh' }}>
                    <div className="w-full">
                        <div className="px-3 py-4">
                            <div className="d-flex justify-content-between items-center">
                                <h4>Dettagli Ordine</h4>
                            </div>
                        </div>
                        {this.props.login.admin ?
                            <div style={{ width: '400px' }} >
                                <div className="py-4">
                                    <h3>Ordina</h3>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <select className="form-control" name="rest_id" onChange={(e) => this.handleSelect(e)}>
                                                <option value="">Tutti i ristoranti</option>
                                                {
                                                    restlist
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div> : null
                        }
                    </div>
                    <Table responsive className="table-centered table-nowrap rounded mb-0">
                        <thead className="thead-light">
                            <tr>
                                <th>SNo</th>
                                <th>Nome Ristorante</th>
                                <th>Nome Cliente</th>
                                <th>Numero di Cellulare</th>
                                <th>Email</th>
                                <th>Prezzo Totale</th>
                                <th>Azioni</th>
                                <th>Numero del posto</th>
                            </tr>

                        </thead>
                        <tbody className="bg-light">
                            {
                                ((orders && orders != undefined && orders != '' && orders != null) ? orders.map((order, key) =>
                                    <tr key={key}>
                                        <td>{key + 1}</td>
                                        <td>{order.NAME}</td>
                                        <td>{order.FIRST_NAME + " " + order.LAST_NAME}</td>
                                        <td>{order.PHONE_NO}</td>
                                        <td>{order.EMAIL_ID}</td>
                                        <td>{order.PRICE}</td>
                                        <td>{order.SEAT_NO}</td>
                                        <td><a className="btn btn-success py-2 px-3 m-2" onClick={() => this.onSetItemDetails(order.ITEM_DETAILS)}>Visualizza</a>
                                            {this.props.login.admin ? <a className="btn btn-danger py-2 px-3 m-2" onClick={() => this.handleDelete(order.ORDER_ID)}>Elimina</a> : null}</td>
                                    </tr>
                                ) : <tr><th colSpan={7} className="text-center">Nessun record disponibile</th></tr>)

                            }

                        </tbody>
                    </Table>
                    <BootstrapModals component={<ItemDetails dataFromParent={this.state.Items} />} heading="Dettagli dell'oggetto" show={this.state.ShowModal}
                        onHide={() => this.setState({ ShowModal: !this.state.ShowModal })} className="Item-details" />
                </div>
            </>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
