
import React from "react";
import { Button, Table } from 'react-bootstrap';
import { connect } from "react-redux";
import { CATEGORY_LIST, RESTAURENT_LIST, SINGLE_CATEGORY, RESTAURANT_OF_USER, RESTAURANT_CATEGORY_LIST, USER_LIST } from "../../constants/actions";
import { checkToken, deleteCategory, updateCatPosition, getAllUsers, getAllCategories, getRestaurantOfUser, getRestaurantList, getSingleCategory, getRestaurantCategories } from "../../services/services";
import BootstrapModals from "../components/BootstrapModals";
import CreateCategory from "../form/CreateCategory";
import EditCategory from "../form/EditCategory";
import { confirmAlert } from 'react-confirm-alert';
import { notification } from "../../services/utileServices";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Redirect } from "react-router-dom";

const mapStateToProps = state => {
    return {
        ...state.category,
        app: state.app,
        rest: state.restaurant,
        login: state.login,
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetCategory: (token) => {
            getAllCategories(token).then(result => {
                var payload = result.data;
                dispatch({ type: CATEGORY_LIST, payload });
                if (result.data.success) {
                    notification("Tutti i dati sono caricati correttamente", 'success');
                }
            }).catch(function (err) {
                notification("Errore nel recupero dei dati della categoria ", 'danger');
            })
        },
        onGetRestaurants: (token) => {
            getRestaurantList(token).then(result => {
                var payload = result.data;
                dispatch({ type: RESTAURENT_LIST, payload });
                if (result.data.success) {
                    console.log('Recupero del ristorante riuscito');
                }
            }).catch(function (err) {
                notification("Errore nel recupero dei dati del ristorante ", 'danger');
            })
        },
        async onGetSingleCategory(id) {
            await getSingleCategory(id).then(result => {
                if (result.data.success) {
                    notification("Recupero dei dati di una singola categoria riuscito", 'success');
                    var payload = result.data
                    dispatch({ type: SINGLE_CATEGORY, payload });
                }
            }).catch(function (err) {
                notification("Errore durante il recupero dei dati di una singola categoria ", 'danger');
            })
        },

        onDelete: (token, id) => {
            deleteCategory(token, id).then(result => {
                if (result.data.success) {
                    notification("Categoria eliminata con successo", 'success');
                    getAllCategories(token).then(result => {
                        var payload = result.data;
                        dispatch({ type: CATEGORY_LIST, payload });
                        if (result.data.success) {
                            console.log("Tutti i dati della categoria vengono recuperati correttamente")
                        }
                    }).catch(function (err) {
                        notification("Errore nel recupero dei dati della categoria ", 'danger');
                    })
                }
            }).catch(function (err) {
                notification("Errore nella categoria di eliminazione ", 'danger');
            })
        },
        onGetRestCategory: (id) => {
            getRestaurantCategories(id).then(result => {
                var payload = result.data;
                dispatch({ type: RESTAURANT_CATEGORY_LIST, payload });
                if (result.data.success) {
                    notification("Tutti gli elenchi delle categorie di ristoranti sono stati recuperati correttamente", 'success');
                }
            }).catch(function (err) {
                notification("Errore nel recupero dell'elenco delle categorie di ristoranti ", 'danger');
            })
        },
        onGetUserList: () => {
            getAllUsers().then(result => {
                if (result.data.success) {
                    var payload = result.data;
                    dispatch({ type: USER_LIST, payload });
                    console.log('Gli utenti recuperano correttamente');
                }
            }).catch(function (err) {
                notification("Errore nel recupero dell'elenco utenti ", 'danger');
            })
        },

        onGetRestOfUser: (id) => {
            getRestaurantOfUser(id).then(result => {
                if (result.data.success) {
                    var payload = result.data;
                    dispatch({ type: RESTAURANT_OF_USER, payload });
                }
            }).catch(err => {
                notification("Errore durante il recupero dell'elenco degli utenti di riposot ", 'danger');
            })
        },

        onUpdateCatPosition: (token, data) => {
            updateCatPosition(token, data).then(result => {
                if (result.data.success) {
                    let payload = {};
                    payload = result.data;
                    dispatch({ type: RESTAURANT_CATEGORY_LIST, payload });
                }
            })
        }

    }
}

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            AddModalShow: false,
            EditModalShow: false,
            update_id: '',
            delete_id: '',
            Filter: false,
            RestFilter: false,
        }
        this.handleDelete = this.handleDelete.bind(this);
        this.onGetCategoryById = this.onGetCategoryById.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.handleReorder = this.handleReorder.bind(this);
    }

    componentDidMount() {
        const token = checkToken(this.props.app.token);
        this.props.onGetCategory(token);
        this.props.onGetRestaurants(token);
        if (this.props.login.admin) {
            this.props.onGetUserList();
        }
    }

    handleDelete(id) {
        confirmAlert({
            title: 'Conferma per eliminare',
            message: 'Sei sicuro di voler cancellare questo.',
            buttons: [
                {
                    label: 'sì',
                    onClick: () => this.props.onDelete(this.props.app.token, id)
                },
                {
                    label: 'No',
                    onClick: () => console.log("closed")
                }
            ]
        });
    }

    onGetCategoryById(id) {
        var that = this;
        this.props.onGetSingleCategory(id).then(function () {
            that.setState({ EditModalShow: !that.state.EditModalShow });
        });
    }

    handleSelect = (e) => {
        var cat_id = e.target.value;
        if (cat_id != '' && cat_id != undefined) {
            this.setState({ Filter: true });
            this.props.onGetRestCategory(cat_id)
        } else {
            this.setState({ Filter: false });
            this.props.onGetCategory(this.props.app.token);
        }
    }

    onDragEnd(result) {
        if (!result.destination) return;
        if (result.destination.index === result.source.index) return;
        const projects = this.handleReorder(
            this.props.restcat ? this.props.restcat : [],
            result.source.index,
            result.destination.index
        );
        let tempResult = projects;
        for(let i=0; i<tempResult.length; i++){
            tempResult[i]['POSITION_MENU'] = `${i+1}`;
        }
        // console.log(tempResult);
        this.props.onUpdateCatPosition(this.props.app.token, tempResult);
    }

    handleReorder(list, startIndex, endIndex) {
        if(startIndex < endIndex){
            endIndex-=1
        }
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    }


    render() {
        let catList = [];
        let restlist = '';
        if (this.props) {
            if (this.props.category && !this.state.Filter) {
                catList = this.props.category;
            } else if (this.props.restcat && this.state.Filter) {
                catList = this.props.restcat;
            }
        }

        if (this.props.rest && this.props.rest.restaurant) {
            let rests = this.props.rest.restaurant;
            restlist = rests.map((rest, key) => (
                <option key={key} value={rest.RESTAURANT_ID}>{rest.NAME_ITALIAN}</option>
            ))
        }

        return (
            <>
                <div style={{ minHeight: '100vh' }}>
                    <div className="w-full">
                        <div className="px-3 py-4">
                            <div className="d-flex justify-content-between align-items-center">
                                <h4>Elenco di categoria</h4>
                                <button type="button" className="btn btn-primary" onClick={() => this.setState({ AddModalShow: !this.state.AddModalShow })}>
                                    Aggiungi Categoria
                                </button>
                            </div>
                        </div>
                        <div style={{ width: '400px' }} >
                            <div className="py-4">
                                <h3>Ordina</h3>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <select className="form-control" name="rest_id" onChange={(e) => this.handleSelect(e)}>
                                            <option value="">Tutti i ristoranti</option>
                                            {
                                                restlist
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="thead-light">

                            <div className="drag-table-head">
                                <span style={{ width: '20px' }}>SNo</span>
                                <span style={{ width: '250px' }}>Nome Ristorante</span>
                                <span style={{ width: '250px' }}>Nome Categoria</span>
                                <span style={{ width: '180px' }}>Azioni</span>
                            </div>

                        </div>

                        <div className="bg-light w-100">
                            <DragDropContext onDragEnd={this.onDragEnd}>

                                {this.state.Filter ? ((catList && catList != '' && catList != undefined) ? catList.map((item, index) =>
                                    <Droppable droppableId={item.POSITION_MENU} style={{ minHeight: '250px' }} key={index}>
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}

                                            >
                                                <Draggable draggableId={item.POSITION_MENU} key={item.CATEGORY_ID} index={index}>
                                                    {(provided) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className="drag-table-body"
                                                        >
                                                            <span style={{ width: '20px' }}>{index + 1}</span>
                                                            <span style={{ width: '250px' }}>{item.NAME_ITALIAN}</span>
                                                            <span style={{ width: '250px' }}>{item.CATEGORY_NAME_ITALIAN}</span>
                                                            <span className="d-flex align-items-center">
                                                                <a className="btn btn-success py-2 px-3 m-2" onClick={() => this.onGetCategoryById(item.CATEGORY_ID)}>Modifica</a>
                                                                <a className="btn btn-danger py-2 px-3" onClick={() => this.handleDelete(item.CATEGORY_ID)}>Elimina</a>
                                                            </span>
                                                        </div>
                                                    )}
                                                </Draggable>
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                ) : (<div className="text-center py-3">Nessun record disponibile</div>)) :
                                    (catList && catList != '' && catList != undefined) ? catList.map((item, index) =>
                                        <div className="drag-table-body" key={index}>
                                            <span style={{ width: '20px' }}>{index + 1}</span>
                                            <span style={{ width: '250px' }}>{item.NAME_ITALIAN}</span>
                                            <span style={{ width: '250px' }}>{item.CATEGORY_NAME_ITALIAN}</span>
                                            <span className="d-flex align-items-center">
                                                <a className="btn btn-success py-2 px-3 m-2" onClick={() => this.onGetCategoryById(item.CATEGORY_ID)}>Modifica</a>
                                                <a className="btn btn-danger py-2 px-3" onClick={() => this.handleDelete(item.CATEGORY_ID)}>Elimina</a>
                                            </span>
                                        </div>
                                    ) : (<div className="text-center py-3">Nessun record disponibile</div>)
                                }
                            </DragDropContext>
                        </div>
                    </div>
                    <BootstrapModals component={<CreateCategory onClose={() => this.setState({ AddModalShow: !this.state.AddModalShow })} />} heading="Aggiungi Categoria" show={this.state.AddModalShow}
                        onHide={() => this.setState({ AddModalShow: !this.state.AddModalShow })} />
                    <BootstrapModals component={<EditCategory edit={this.state.update_id} onClose={() => this.setState({ EditModalShow: !this.state.EditModalShow })} />} heading="Modifica Categoria" show={this.state.EditModalShow}
                        onHide={() => this.setState({ EditModalShow: !this.state.EditModalShow })} />
                </div>

            </>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Category);
