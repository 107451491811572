
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

const mapStateToProps = state => {
  return {
    ...state.app,
  }
}

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>{this.props.token ?
        // <Redirect to="/bootstrap-tables" />
        <Redirect to="/restaurant-list" />
        // <div style={{ minHeight: '100vh' }}>
        //   <div className="d-flex justify-content-between my-4">
        //     <div style={{ width: '30%', minHeight: '200px', padding: "20px", backgroundColor: '#e55770', borderRadius: '10px', color:'#fff' }}>
        //       <div>
        //         <h3>Restaurants</h3>
        //         <p>Recently Opened Restaurents: <span>20</span></p>
        //         <p>Closed Restaurants: <span>10</span></p>
        //         <p>Total Restaurants: <span>110</span></p>
        //       </div>
        //     </div>
        //     <div style={{ width: '30%', minHeight: '200px',padding: "20px", backgroundColor: '#4949f2', borderRadius: '10px', color:'#fff' }}>
        //       <div>
        //         <h3>Users</h3>
        //         <p>Recently Joined Users: <span>20</span></p>
        //         <p>Recently Added Users: <span>30</span></p>
        //         <p>Total Users: <span>110</span></p>
        //       </div>
        //     </div>
        //     <div style={{ width: '30%', minHeight: '200px',padding: "20px", backgroundColor: '#e33232', borderRadius: '10px' , color:'#fff'}}>
        //       <div>
        //         <h3>Items</h3>
        //         <p>Total Chinese Items: <span>50</span></p>
        //         <p>Total Western Items: <span>25</span></p>
        //         <p>Total Asian Items: <span>55</span></p>
        //         <p>Total Korean Items: <span>15</span></p>
        //       </div>
        //     </div>
        //   </div>
        //   <div className="d-flex justify-content-between">
        //     <div style={{ width: '30%', minHeight: '200px',padding: "20px", backgroundColor: '#b0b045', borderRadius: '10px' , color:'#fff'}}>
        //       <div>
        //         <h3>Categories</h3>
        //         <p>Recently Opened Restaurents: <span>20</span></p>
        //         <p>Closed Restaurants: <span>10</span></p>
        //         <p>Total Restaurants: <span>110</span></p>
        //       </div>
        //     </div>
        //     <div style={{ width: '30%', minHeight: '200px',padding: "20px", backgroundColor: '#6cdb6c', borderRadius: '10px' , color:'#fff'}}>
        //       <div>
        //         <h3>Allergens</h3>
        //         <p>Recently Opened Restaurents: <span>20</span></p>
        //         <p>Closed Restaurants: <span>10</span></p>
        //         <p>Total Restaurants: <span>110</span></p>
        //       </div>
        //     </div>
        //     <div style={{ width: '30%', minHeight: '200px',padding: "20px", backgroundColor: 'orange', borderRadius: '10px' , color:'#fff'}}>
        //       <div>
        //         <h3>Restaurants</h3>
        //         <p>Recently Opened Restaurents: <span>20</span></p>
        //         <p>Closed Restaurants: <span>10</span></p>
        //         <p>Total Restaurants: <span>110</span></p>
        //       </div>
        //     </div>
        //   </div>
        // </div>
        : this.props.history.push('/sign-in')
      }
      </>
    );
  }
};

export default connect(mapStateToProps)(Dashboard);
