import { UPDATED_USER_DATA, USER_DATA_BY_ID, USER_LIST} from '../constants/actions';

export default (state = { openAuthLogin: false }, action = {}) => {
    switch (action.type) {
        case USER_LIST:{
            return{
                ...state,
                user: action.payload.data
            }
        }
        case USER_DATA_BY_ID:{
            return{
                ...state,
                single: action.payload.data
            }
        }
        case UPDATED_USER_DATA:{
            return{
                ...state,
                edituser: action.payload.data
            }
        }
            
        default:
            {
                return {
                    ...state,
                };
            }
    }
}