
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Routes } from "../../routes";
import BgImage from "../../assets/img/illustrations/signin.svg";
import { connect } from "react-redux";
import { userLoginAuth, verifyAdminToken } from "../../services/services";
import { setSessionItem } from "../../helpers/sessionHelper";
import { APP_LOGIN_AUTH, APP_LOAD, VERIFY_ADMIN } from "../../constants/actions";
import { reduxForm, Field } from "redux-form";
import { renderField, email, required } from "../../services/utileServices";
import { Store } from "react-notifications-component";
import { Link } from 'react-router-dom';
import { notification } from "../../services/utileServices";


const mapStateToProps = state => {
  return {
    ...state.login,
    app: state.app
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLoginAuth: (form_data, that) => {
      userLoginAuth(form_data).then(result => {
        if (result.data.success) {
          let payload = result.data;
          dispatch({ type: APP_LOGIN_AUTH, payload })
          notification('Accedi con successo', 'success');
          if (result.data.data && result.data.data.token) {
            verifyAdminToken(result.data.data.token).then(result => {
              let payload = result.data;
              dispatch({ type: VERIFY_ADMIN, payload });
            })
            let udata = result.data.data.userDetails;
            setSessionItem(result.data.data.token, udata.EMAIL_ID, udata.FIRST_NAME, udata.LAST_NAME, udata.ID, udata.IMAGE_NAME);
            payload = { token: result.data.data.token, user: udata };
            dispatch({ type: APP_LOAD, payload });
            that.history.push('/');
          }

        } else {
          notification('Email e password non corrispondono!', 'warning');
        }
      }).catch(function (err) {
        notification(err.response.data.error, 'danger');
      })
    }
  }
}

class Signin extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmitting = this.onSubmitting.bind(this);
  }

  onSubmitting = (values) => {
    var login_form = { EMAIL_ID: values.signInEmail, PASSWORD: values.signInPassword }
    this.props.onLoginAuth(login_form, this.props);
  }
  render() {
    const { handleSubmit, submitting, error } = this.props;
    return (
      <>
        {!this.props.app.token ?
          <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
            <Container>
              <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
                <Col xs={12} className="d-flex align-items-center justify-content-center">
                  <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                    <div className="text-center text-md-center mb-4 mt-md-0">
                      <h3 className="mb-0">Sign in to our platform</h3>
                    </div>
                    <Form className="mt-4" onSubmit={handleSubmit(this.onSubmitting)}>
                      <Form.Group id="signInEmail" className="mb-4">
                        <Form.Label>Email</Form.Label>
                        <Field name="signInEmail" type="email" label="Enter your Email" component={renderField} validate={[required, email]} />
                      </Form.Group>
                      <Form.Group>
                        <Form.Group id="signInPassword" className="mb-4">
                          <Form.Label>Password</Form.Label>
                          <Field name="signInPassword" type="password" label="Enter Your Password" component={renderField} validate={[required]} />
                        </Form.Group>
                        <div className="d-flex justify-content-between align-items-center mb-4">
                          <Form.Check type="checkbox">
                            <FormCheck.Input id="defaultCheck5" className="me-2" />
                            <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Remember me</FormCheck.Label>
                          </Form.Check>
                          <Card.Link className="small text-end" as={Link} to={Routes.ForgotPassword.path}>Forgot password?</Card.Link>
                        </div>
                      </Form.Group>
                      <Button variant="primary" disabled={submitting} type="submit" className="w-100">
                        Sign in
                      </Button>
                    </Form>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          : this.props.history.push('/')
        }
      </>
    );
  }
};

Signin = connect(mapStateToProps, mapDispatchToProps)(Signin);

export default reduxForm({
  form: 'signInForm'
})(Signin);
