
import React from "react";
import { Col, Row, Form, Button, Container } from '@themesberg/react-bootstrap';
import BgImage from "../../assets/img/illustrations/signin.svg";
import { connect } from "react-redux";
import { getAllCategories, updateCategory } from "../../services/services";
import { CATEGORY_LIST } from "../../constants/actions";
import { reduxForm, Field } from "redux-form";
import { renderField, notification } from "../../services/utileServices";
import Loader from "react-js-loader";

const mapStateToProps = state => {
  return {
    ...state.category,
    app: state.app,
    restaurant: state.restaurant
  }
}

const mapDispatchToProps = dispatch => {
  return {
    async onUpdateCategory(token, payload) {
      await updateCategory(token, payload).then(result => {
        if (result.data.success) {
          notification('Categoria Aggiornata con successo', 'success');

          getAllCategories(token).then(result => {
            if (result.data.success) {
              var payload = result.data;
              dispatch({ type: CATEGORY_LIST, payload });
              console.log('fetch successfully');
            }
          }).catch(function (err) {
            notification('Errore nel recupero dei dati della categoria ' + err.message, 'danger');
          })
        }
      }).catch(function (err) {
        notification("Errore nell'aggiornamento degli allergeni " + err.message, 'danger');
      })
    },
  }
}

class EditCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uId: '',
      loader: false
    }
    this.onSubmitting = this.onSubmitting.bind(this);
  }

  componentDidMount() {
    if (this.props.categorydata) {
      var singleData = this.props.categorydata;
      this.props.autofill('id', singleData.CATEGORY_ID);
      this.props.autofill('cnameen', singleData.CATEGORY_NAME);
      this.props.autofill('cnameit', singleData.CATEGORY_NAME_ITALIAN);
      this.props.autofill('cnamefr', singleData.CATEGORY_NAME_FRENCH);
      this.props.autofill('cnamees', singleData.CATEGORY_NAME_SPANISH);
      this.props.autofill('cnamede', singleData.CATEGORY_NAME_GERMEN);
      this.props.autofill('cat_pos', singleData.POSITION_MENU);
    }
  }

  async onSubmitting(values) {
    this.setState({ loader: true });
    let that = this;
    // let input_data = await Translation(values.cname);
    let reg_form = {
      CATEGORY_ID: values.id, CATEGORY_NAME: values.cnameen, POSITION_MENU: values.cat_pos, CATEGORY_NAME_FRENCH: values.cnamefr,
      CATEGORY_NAME_SPANISH: values.cnamees, CATEGORY_NAME_GERMEN: values.cnamede, CATEGORY_NAME_ITALIAN: values.cnameit
    }

    const UpdateCat = async (data) => {
      await this.props.onUpdateCategory(this.props.app.token, data).then(function () {
        that.setState({ loader: false });
        that.props.onClose();
      });
    }
    UpdateCat(reg_form);
    // var reg_form = { CATEGORY_ID: values.id, CATEGORY_NAME: values.cname, POSITION_MENU: values.cat_pos }

  }

  render() {

    let catlist = [];
    let singleCat = '';
    if (this.props.categorydata) {
      singleCat = this.props.categorydata;
    }
    const { handleSubmit, submitting, error } = this.props;
    return (
      <main>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h3 className="mb-0">Modifica Categoria</h3>
                  </div>
                  {!this.state.loader ?
                    <Form className="mt-4" onSubmit={handleSubmit(this.onSubmitting)}>
                      {error && <span className='alert-danger'>{error}</span>}
                        <Field name="id" component={renderField} type="hidden" />
                      <Form.Group id="cname" className="mb-4">
                        <Form.Label>Nome categoria (it)</Form.Label>
                        <Field name="cnameit" component={renderField} type="text" label="Nome categoria" />
                      </Form.Group>
                      <Form.Group id="cname" className="mb-4">
                        <Form.Label>Nome categoria (en)</Form.Label>
                        <Field name="cnameen" component={renderField} type="text" label="Nome categoria" />
                      </Form.Group>
                      <Form.Group id="cname" className="mb-4">
                        <Form.Label>Nome categoria (fr)</Form.Label>
                        <Field name="cnamefr" component={renderField} type="text" label="Nome categoria" />
                      </Form.Group>
                      <Form.Group id="cname" className="mb-4">
                        <Form.Label>Nome categoria (de)</Form.Label>
                        <Field name="cnamede" component={renderField} type="text" label="Nome categoria" />
                      </Form.Group>
                      <Form.Group id="cname" className="mb-4">
                        <Form.Label>Nome categoria (es)</Form.Label>
                        <Field name="cnamees" component={renderField} type="text" label="Nome categoria" />
                      </Form.Group>
                      <Form.Group id="cat_pos" className="mb-4">
                        <Form.Label>Posizione</Form.Label>
                        <Field name="cat_pos" component={renderField} label="Posizione" type="number" />
                      </Form.Group>

                      <Button variant="primary" disabled={submitting} type="submit" className="w-100">
                        Applica
                      </Button>
                    </Form> : <div className="d-flex align-items-center justify-content-center" style={{ height: '100vh' }}><Loader type="bubble-scale" bgColor={"#BE1622"} color={'#BE1622'} size={100} /></div>}


                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    );
  }
};

EditCategory = connect(mapStateToProps, mapDispatchToProps)(EditCategory);

export default reduxForm({
  form: 'update user'
})(EditCategory);
