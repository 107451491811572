import React from "react";
import ResetUserPassword from "./Validation/ResetUserPassword";
import UserProfileImage from "./components/UserProfileImage";
import PersonalData from "./components/PersonalData";
import BootstrapModals from "./components/BootstrapModals";
import AddressUpdate from "./components/AddressUpdate";
import { connect } from "react-redux";
import { userSettingData, adminSettingData, checkToken } from "../services/services";
import { USER_SETTING_DATA, ADMIN_SETTING_DATA } from "../constants/actions";
import { Store } from "react-notifications-component";


const mapStateToProps = state => {
  return {
    ...state.settingdata,
    login: state.login,
    app: state.app
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetUserSettingData: (token) =>{
      userSettingData(token).then(result =>{
        if(result.status){
          var payload = result.data;
          dispatch({type: USER_SETTING_DATA, payload});
          Store.addNotification({
            message: "Le impostazioni sono caricate correttamente",
            type: 'success',
            container:'top-right',
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 3000 
            }
          })
        }
      }).catch(err =>{
        Store.addNotification({
          message: err.message,
          type: 'warning',
          container:'top-right',
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000 
          }
        })
      })
    },
    onGetAdminSettingData: (token) =>{
      adminSettingData(token).then(result =>{
        if(result.status){
          var payload = result.data;
          dispatch({type: ADMIN_SETTING_DATA, payload});
          Store.addNotification({
            message: "Le impostazioni sono caricate correttamente",
            type: 'success',
            container:'top-right',
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 3000 
            }
          })
        }
      }).catch(err =>{
        Store.addNotification({
          message: err.message,
          type: 'warning',
          container:'top-right',
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000 
          }
        })
      })
    }
  }
}

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ResetModalShow: false,
      AddressModalShow: false,
      PersonalModalShow: false
    }
    this.handleAddressModalHide = this.handleAddressModalHide.bind(this);
    this.handlePerosnalModalHide = this.handlePerosnalModalHide.bind(this);
    this.handleResetModalHide = this.handleResetModalHide.bind(this);
  }

  componentDidMount() {
    const token = checkToken(this.props.app.token);
    if(this.props.login.admin){
      this.props.onGetAdminSettingData(token);
    }else{
      this.props.onGetUserSettingData(token);
    }
  }

  handleAddressModalHide(){
    this.setState({AddressModalShow: !this.state.AddressModalShow})
  }

  handlePerosnalModalHide(){
    this.setState({PersonalModalShow: !this.state.PersonalModalShow})
  }

  handleResetModalHide(){
    this.setState({ResetModalShow: !this.state.ResetModalShow})
  }

  render() {
    let singleData = ''
    if(this.props){
      if(this.props.adminSettingData){
        singleData = this.props.adminSettingData;
      }else if(this.props.userSettingData){
        singleData = this.props.userSettingData;
      }
    }

    return (
      <>
        <div className="py-4 px-2" style={{ minHeight: "100vh" }}>
          <div className="py-3 mb-4">

            <div className="p-4 mx-auto rounded shadow" style={{ width: '60%', backgroundColor: '#fff' }}>
              <h2 className="text-center text-danger">Informazioni</h2>
              <div>
                <UserProfileImage />
              </div>
              <div className="personal-information">
                <div className="p-4 shadow rounded mb-5">
                  <h3 className="text-center">Informazioni Personali</h3>
                  <label>Nome</label><br />
                  <input type="text" placeholder="Nome" value={singleData.FIRST_NAME?singleData.FIRST_NAME: ''} className="form-control" disabled /><br />
                  <label>Cognome</label><br />
                  <input type="text" placeholder="Cognome" value={singleData.LAST_NAME?singleData.LAST_NAME:''} className="form-control" disabled /><br />
                  <label>Email</label><br />
                  <input type="text" placeholder="Email" value={singleData.EMAIL_ID?singleData.EMAIL_ID:''} className="form-control" disabled /><br />
                  <label>Data di nascita</label><br />
                  <input type="text" placeholder="Data di nascita" value={singleData.DOB?singleData.DOB:''} className="form-control" disabled /><br />
                  <label>Numero di Cellulare</label><br />
                  <input type="text" placeholder="Numero di Cellulare" value={singleData.MOBILE_NO?singleData.MOBILE_NO:''} className="form-control" disabled /><br />
                </div>

               
                <div className="d-flex justify-content-around mt-4">
                  <span className="btn btn-info" onClick={() => this.setState({PersonalModalShow: !this.state.PersonalModalShow})}>Modifica Informazioni Personali</span>
                  <span className="btn btn-info" onClick={() => this.setState({ResetModalShow: !this.state.ResetModalShow})}>Modifica Password</span>
                </div>
              </div>
            </div>
          </div>
          { this.state.PersonalModalShow?
            <BootstrapModals component={<PersonalData onClose={() => this.handlePerosnalModalHide()} />} heading="Modulo di aggiornamento delle informazioni personali" show={this.state.PersonalModalShow}
            onHide={() => this.handlePerosnalModalHide()} /> : null
          }
         
          { this.state.ResetModalShow?
            <BootstrapModals component={<ResetUserPassword onClose={() => this.handleResetModalHide()} />} heading="Modulo di aggiornamento password" show={this.state.ResetModalShow}
            onHide={() => this.handleResetModalHide()} /> : null
          }
          
        </div>
      </>
    );
  }
};

export default Settings = connect(mapStateToProps, mapDispatchToProps)(Settings);


