import axios from 'axios';
import { getSessionItem } from '../helpers/sessionHelper';
const { token, user, fname, lname, id } = getSessionItem();

function userSettingData(token=''){
    var config = {};
    if(token && token != '' && token != 'undefined'){
        config = {
            headers: {Authorization: token}
        }
    }
    return axios.get(`${process.env.REACT_APP_API_URL}user/setting`, config);
}

function adminSettingData(token=''){
    var config = {};
    if(token && token != '' && token != 'undefined'){
        config = {
            headers: {Authorization: token}
        }
    }
    return axios.get(`${process.env.REACT_APP_API_URL}admin/setting`, config);
}

function getAdminList(){
    return axios.get(`${process.env.REACT_APP_API_URL}admin/list`);
}

function getAdminById(id){
    return axios.get(`${process.env.REACT_APP_API_URL}admin/get/${id}`);
}

function createAdmin(data){
    return axios.post(`${process.env.REACT_APP_API_URL}admin/create`, data);
}

function updateAdmin(token,data){
    var config = {};
    if(token && token != '' && token != 'undefined'){
        config = {
            headers: {Authorization: token}
        }
    }
    return axios.put(`${process.env.REACT_APP_API_URL}admin/update`, data, config);
}

function deleteAdmin(token = '', id){
    var config = {};
    if(token && token != '' && token != 'undefined'){
        config = {
            headers: {Authorization: token}
        }
    }

    return axios.delete(`${process.env.REACT_APP_API_URL}admin/delete/${id}`, config);
}

function updateAdminPassword(token = '', data){
    var config = {};
    if(token && token != '' && token != 'undefined'){
        config = {
            headers: {Authorization: token}
        }
    }

    return axios.put(`${process.env.REACT_APP_API_URL}admin/change/password`, data, config);
}

function AdminLoginAuth(data){
    return axios.post(`${process.env.REACT_APP_API_URL}admin/login`, data);
}

function getAllUsers(){
    return axios.get(`${process.env.REACT_APP_API_URL}user/get/all`);
}

function getUserById(id){
    return axios.get(`${process.env.REACT_APP_API_URL}user/getbyid/${id}`);
}

function createUser(token, data){
    var config = {};
    if(token && token != '' && token != 'undefined'){
        config = {
            headers: {Authorization: token}
        }
    }
    return axios.post(`${process.env.REACT_APP_API_URL}user/create`, data, config);
}

function updateUser(token,data){
    var config = {};
    if(token && token != '' && token != 'undefined'){
        config = {
            headers: {Authorization: token}
        }
    }
    return axios.put(`${process.env.REACT_APP_API_URL}user/update`,data, config);
}

function deleteUser(token = '', id){
    var config = {};
    if(token && token != '' && token != 'undefined'){
        config = {
            headers: {Authorization: token}
        }
    }
    return axios.delete(`${process.env.REACT_APP_API_URL}user/delete/${id}`, config);
}

function userLoginAuth(data){
    return axios.post(`${process.env.REACT_APP_API_URL}user/login`, data);
}

function updateUserPassword(token = '', data){
    var config = {};
    if(token && token != '' && token != 'undefined'){
        config = {
            headers: {Authorization: token}
        }
    }

    return axios.put(`${process.env.REACT_APP_API_URL}user/password/update`, data, config);
}


function getRestaurantList(token = ''){
    var config = {};
    if(token && token != '' && token != 'undefined'){
        config = {
            headers: {Authorization: token}
        }
    }
    return axios.get(`${process.env.REACT_APP_API_URL}restaurant/get`, config);
}

function createRestaurant(token = '',data){
    var config = {};
    if(token && token != '' && token != 'undefined'){
        config = {
            headers: {Authorization: token}
        }
    }

    return axios.post(`${process.env.REACT_APP_API_URL}restaurant/create`, data, config);
}

function getReataurantById(id){
    return axios.get(`${process.env.REACT_APP_API_URL}restaurant/getbyid/${id}`);
}

function updateRastaurant(token = '', data){
    var config = {};
    if(token && token != '' && token != 'undefined'){
        config = {
            headers: {Authorization: token}
        }
    }

    return axios.put(`${process.env.REACT_APP_API_URL}restaurant/update`,data, config);
}

function deleteRastaurant(token = '', id){
    var config = {};
    if(token && token != '' && token != 'undefined'){
        config = {
            headers: {Authorization: token}
        }
    }
    return axios.delete(`${process.env.REACT_APP_API_URL}restaurant/delete/${id}`, config);
}

function getItems(token = ''){
    var config = {};
    if(token && token != '' && token != 'undefined'){
        config = {
            headers: {Authorization: token}
        }
    }

    return axios.get(`${process.env.REACT_APP_API_URL}item/get`, config);
}

function createItem(token = '',data){
    var config = {};
    if(token && token != '' && token != 'undefined'){
        config = {
            headers: {Authorization: token}
        }
    }

    return axios.post(`${process.env.REACT_APP_API_URL}item/create`, data, config);
}

function getItemById(id){
    return axios.get(`${process.env.REACT_APP_API_URL}item/getbyid/${id}`);
}

function updateItem(token = '', data){
    var config = {};
    if(token && token != '' && token != 'undefined'){
        config = {
            headers: {Authorization: token}
        }
    }

    return axios.put(`${process.env.REACT_APP_API_URL}item/update`, data, config);
}

function deleteItem(token = '', id){
    var config = {};
    if(token && token != '' && token != 'undefined'){
        config = {
            headers: {Authorization: token}
        }
    }
    return axios.delete(`${process.env.REACT_APP_API_URL}item/delete/${id}`, config);
}

function getAllCategories(token = ''){
    var config = {};
    if(token && token != '' && token != 'undefined'){
        config = {
            headers: {Authorization: token}
        }
    }
    return axios.get(`${process.env.REACT_APP_API_URL}category/getall`, config);
}

function getSingleCategory(id){
    return axios.get(`${process.env.REACT_APP_API_URL}category/getbyid/${id}`);
}

function createCategory(token = '',data){
    var config = {};
    if(token && token != '' && token != 'undefined'){
        config = {
            headers: {Authorization: token}
        }
    }
    return axios.post(`${process.env.REACT_APP_API_URL}category/create`, data, config);
}

function updateCategory(token = '',data){
    var config = {};
    if(token && token != '' && token != 'undefined'){
        config = {
            headers: {Authorization: token}
        }
    }
    return axios.put(`${process.env.REACT_APP_API_URL}category/update`,data, config);
}

function deleteCategory(token = '', id){
    var config = {};
    if(token && token != '' && token != 'undefined'){
        config = {
            headers: {Authorization: token}
        }
    }

    return axios.delete(`${process.env.REACT_APP_API_URL}category/delete/${id}`, config)
}


function getAllAllergens(token = ''){
    var config = {};
    if(token && token != '' && token != 'undefined'){
        config = {
            headers: {Authorization: token}
        }
    }
    return axios.get(`${process.env.REACT_APP_API_URL}allergen/getall`, config);
}

function getSingleAllergens(id){
    return axios.get(`${process.env.REACT_APP_API_URL}allergen/getbyid/${id}`);
}

function createAllergens(token = '',data){
    var config = {};
    if(token && token != '' && token != 'undefined'){
        config = {
            headers: {Authorization: token}
        }
    }
    return axios.post(`${process.env.REACT_APP_API_URL}allergen/create`, data, config);
}

function updateAllergens(token = '',data){
    var config = {};
    if(token && token != '' && token != 'undefined'){
        config = {
            headers: {Authorization: token}
        }
    }
    return axios.put(`${process.env.REACT_APP_API_URL}allergen/update`,data, config);
}

function deleteAllergens(token = '', id){
    var config = {};
    if(token && token != '' && token != 'undefined'){
        config = {
            headers: {Authorization: token}
        }
    }

    return axios.delete(`${process.env.REACT_APP_API_URL}allergen/delete/${id}`, config);
}

function verifyAdminToken(token){
    return axios.post(`${process.env.REACT_APP_API_URL}session/verifytoken`,{},{
      headers: {
        'Authorization': token
      }
    });
  }

function forgotPassword(data){
    return axios.post(`${process.env.REACT_APP_API_URL}forgot-password`, data);
}

function getRestaurantCategories(id){
    return axios.get(`${process.env.REACT_APP_API_URL}category/getbyrestid/${id}`);
}

function getRestaurantOfUser(token = '',id){
    var config = {};
    if(token && token != '' && token != 'undefined'){
        config = {
            headers: {Authorization: token}
        }
    }
    return axios.get(`${process.env.REACT_APP_API_URL}restaurant/getbyownerid/${id}`, config);
}

function getCatItem(id){
    return axios.get(`${process.env.REACT_APP_API_URL}item/getcateanditem/${id}`);
}
function getRestItem(id){
    return axios.get(`${process.env.REACT_APP_API_URL}item/getbyrestid/${id}`);
}
function getRestAllergen(id){
    return axios.get(`${process.env.REACT_APP_API_URL}allergen/getbyrestid/${id}`);
}

function verifyUser(data){
    return axios.post(`${process.env.REACT_APP_API_URL}verify/user`, data);
}

function resendOtp(data){
    return axios.post(`${process.env.REACT_APP_API_URL}send/otp`, data);
}

function ResetUserPassword(data){
    return axios.put(`${process.env.REACT_APP_API_URL}resetuser/password`, data);
}

function submitOtp(data){
    return axios.post(`${process.env.REACT_APP_API_URL}submit-otp`, data);
}

function uploadProfileImage(token = '',data){
    var config = {};
    if(token && token != '' && token != 'undefined'){
        config = {
            headers: {Authorization: token}
        }
    }
    return axios.post(`${process.env.REACT_APP_API_URL}upload`, data, config);
}

function uploadAdminImage(token = '',data){
    var config = {};
    if(token && token != '' && token != 'undefined'){
        config = {
            headers: {Authorization: token}
        }
    }
    return axios.post(`${process.env.REACT_APP_API_URL}admin/upload`, data, config);
}

function logout(token = ''){
    var config = {};
    if(token && token != '' && token != 'undefined'){
        config = {
            headers: {Authorization: token}
        }
    }
    return axios.delete(`${process.env.REACT_APP_API_URL}user/logout`, config);

}

function checkToken(rtoken=null){
    var config = '';
    if(rtoken != null && rtoken != 'undefined' && rtoken != ''){
        config = rtoken;
    }
    else{
        config = token;
    }
    return config;
}

function getOrderDetails(token = ''){
    var config = {};
    if(token && token != '' && token != 'undefined'){
        config = {
            headers: {Authorization: token}
        }
    }
    return axios.get(`${process.env.REACT_APP_API_URL}orders/show`, config);
}

function getOrderDetailsByRestId(token = '', id){
    var config = {};
    if(token && token != '' && token != 'undefined'){
        config = {
            headers: {Authorization: token}
        }
    }
    return axios.get(`${process.env.REACT_APP_API_URL}orders/showbyrestid/${id}`, config);
}

function deleteOrder(token = '', id){
    var config = {};
    if(token && token != '' && token != 'undefined'){
        config = {
            headers: {Authorization: token}
        }
    }
    return axios.delete(`${process.env.REACT_APP_API_URL}order/delete/${id}`, config);
}
function updateCatPosition(token = '', data){
    var config = {};
    if(token && token != '' && token != 'undefined'){
        config = {
            headers: {Authorization: token}
        }
    }
    return axios.put(`${process.env.REACT_APP_API_URL}category/updateposition`,{data: data}, config);
}

function getAllergenByItemId(id, rest_id){
    return axios.get(`${process.env.REACT_APP_API_URL}getallergenmap/${id}/${rest_id}`);
}

function getSingleAllergenMap(id){
    return axios.get(`${process.env.REACT_APP_API_URL}allergenmap/getbyid/${id}`);
}

function createAllergenMap(token = '',data){
    var config = {};
    if(token && token != '' && token != 'undefined'){
        config = {
            headers: {Authorization: token}
        }
    }
    return axios.post(`${process.env.REACT_APP_API_URL}itemallergenmap/create`, data, config);
}

function updateAllergenMap(token = '',data){
    var config = {};
    if(token && token != '' && token != 'undefined'){
        config = {
            headers: {Authorization: token}
        }
    }
    return axios.put(`${process.env.REACT_APP_API_URL}allergenmap/update`,data, config);
}

function deleteAllergenmap(token = '', id, rest_id){
    var config = {};
    if(token && token != '' && token != 'undefined'){
        config = {
            headers: {Authorization: token}
        }
    }
    return axios.delete(`${process.env.REACT_APP_API_URL}itemallergenmap/delete/${id}/${rest_id}`, config);
}

function Translate(inputText, detectLanguageKey, selectedLanguageKey){
    let data = {
        q : inputText,
        source: detectLanguageKey,
        target: selectedLanguageKey
    }
    return axios.post(`https://libretranslate.de/translate`, data);
}

function detectLang(inputText){
    return axios.post(`https://libretranslate.de/detect`, {q: inputText});
}

export {
    Translate,
    detectLang,
    createAllergenMap,
    updateAllergenMap,
    deleteAllergenmap,
    getSingleAllergenMap,
    getAllergenByItemId,
    deleteOrder,
    updateCatPosition,
    resendOtp,
    ResetUserPassword,
    uploadProfileImage,
    submitOtp,
    verifyUser,
    getCatItem,
    getRestaurantOfUser,
    getRestItem,
    deleteUser,
    forgotPassword,
    getAdminList,
    getRestaurantList,
    getItems,
    verifyAdminToken,
    getAllCategories,
    getSingleCategory,
    createCategory,
    updateCategory,
    deleteCategory,
    deleteAdmin,
    getAllUsers,
    createUser,
    updateUser,
    userLoginAuth,
    getUserById,
    updateUserPassword,
    createRestaurant,
    getReataurantById,
    updateRastaurant,
    deleteRastaurant,
    createItem,
    getItemById,
    updateItem,
    deleteItem,
    getAllAllergens,
    getSingleAllergens,
    createAllergens,
    updateAllergens,
    deleteAllergens,
    updateAdminPassword,
    getAdminById,
    createAdmin,
    updateAdmin,
    AdminLoginAuth,
    checkToken,
    getRestaurantCategories,
    getRestAllergen,
    userSettingData,
    adminSettingData,
    logout,
    getOrderDetails,
    uploadAdminImage,
    getOrderDetailsByRestId
}