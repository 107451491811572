import { APP_LOGIN_AUTH, VERIFY_ADMIN, DOB_CHANGE } from '../constants/actions';

export default (state = { openAuthLogin: false }, action = {}) => {
    switch (action.type) {
        case APP_LOGIN_AUTH:
            {
                return {
                    ...state,
                    Authenticator: action.payload.Authenticator,
                   user: action.payload.data
                };
            }
        case VERIFY_ADMIN:
            {
                return {
                    ...state,
                    admin: action.payload.data.admin
                }
            }
        case DOB_CHANGE:
            {
                return {
                    ...state,
                    dobDate: action.payload
                };
            }
        default:
            {
                return {
                    ...state,
                };
            }
    }
}