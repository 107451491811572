
import React from "react";
import { Table } from 'react-bootstrap';
import { connect } from "react-redux";
import { deleteUser, getAllUsers, getUserById } from "../../services/services";
import { USER_LIST, USER_DATA_BY_ID } from "../../constants/actions";
import CreateUser from "../form/CreateUser";
import EditUser from "../form/EditUser";
import BootstrapModals from "../components/BootstrapModals";
import { confirmAlert } from 'react-confirm-alert';
import { notification } from "../../services/utileServices";

const mapStateToProps = state => {
    return {
        ...state.user,
        app: state.app
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onGetUserList: () => {
            getAllUsers().then(result => {
                if (result.data.success) {
                    notification('Tutti i dati utente vengono recuperati correttamente', 'success');
                    var payload = result.data;
                    dispatch({ type: USER_LIST, payload });
                }
            }).catch(function (err) {
                notification('Errore durante il recupero dei dati utente ' + err.message, 'danger');
            })
        },
        async onGetSingleUser(payload) {
            await getUserById(payload).then(result => {
                if (result.data.success) {
                    notification('Recupero dati utente singolo riuscito', 'success');
                    var payload = result.data;
                    dispatch({ type: USER_DATA_BY_ID, payload });
                }
            }).catch(function (err) {
                notification("Errore durante il recupero dei dati dell'utente singolo " + err.message, 'danger');
            })
        },
        onDelete: (token, id) => {
            deleteUser(token, id).then(result => {
                if (result.data.success) {
                    notification('Utente eliminato con successo', 'success');
                    getAllUsers().then(result => {
                        var payload = result.data;
                        dispatch({ type: USER_LIST, payload });
                        if (result.data.success) {
                            console.log('Recupero utente riuscito')
                        }
                    }).catch(function (err) {
                        notification('Errore durante il recupero dei dati utente ' + err.message, 'danger');
                    })
                }
            }).catch(function (err) {
                notification("Errore nell'eliminazione dell'utente " + err.message, 'danger');
            })
        }

    }
};

class UsersList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            AddModalShow: false,
            EditModalShow: false,
            update_id: '',
            delete_id: '',
        }

        this.handleDelete = this.handleDelete.bind(this);
        this.onGetUserById = this.onGetUserById.bind(this);
        this.handleHide = this.handleHide.bind(this);
        this.handleEditHide = this.handleEditHide.bind(this);
    }

    componentDidMount() {
        this.props.onGetUserList();
    }

    onGetUserById(update_id) {
        var that = this;
        this.props.onGetSingleUser(update_id).then(function () {
            that.setState({ EditModalShow: !that.state.EditModalShow });
        })

    }

    handleDelete(id) {
        confirmAlert({
            title: 'Conferma per eliminare',
            message: 'Sei sicuro di voler cancellare questo.',
            buttons: [
                {
                    label: 'sì',
                    onClick: () => this.props.onDelete(this.props.app.token, id)
                },
                {
                    label: 'No',
                    onClick: () => console.log("closed")
                }
            ]
        });
    }

    handleHide() {
        this.setState({ AddModalShow: !this.state.AddModalShow });
    }

    handleEditHide() {
        this.setState({ EditModalShow: !this.state.EditModalShow });
    }

    render() {

        return (
            <>
                <div className="w-full" style={{ minHeight: '100vh' }}>
                    <div className="px-3 py-4">
                        <div className="d-flex justify-content-between items-center">

                            <h4>elenco di utenti</h4>
                            <button type="button" className="btn btn-primary" onClick={() => this.setState({ AddModalShow: !this.state.AddModalShow })}>
                                Aggiungi Utente
                            </button>

                        </div>
                    </div>
                    <Table responsive className="table-centered table-nowrap rounded mb-0">
                        <thead className="thead-light">
                            <tr>
                                <th>SNo</th>
                                <th>Nome Utente</th>
                                <th>Numero di Telefono</th>
                                <th>Indirizzo</th>
                                <th>Email</th>
                                <th>Azioni</th>
                            </tr>
                        </thead>
                        <tbody className="bg-light">
                            {
                                this.props.user ? this.props.user.map((userList, key) =>
                                    <tr key={key}>
                                        <td>{key + 1}</td>
                                        <td>{userList.FIRST_NAME + " " + userList.LAST_NAME}</td>
                                        <td>{userList.MOBILE_NO}</td>
                                        <td>{userList.ADDRESS}</td>
                                        <td>{userList.EMAIL_ID}</td>
                                        <td>
                                            <a className="btn btn-success py-2 px-3 m-2" onClick={() => this.onGetUserById(userList.ID)}>Modifica</a>
                                            <a className="btn btn-danger py-2 px-3" onClick={() => this.handleDelete(userList.ID)}>Elimina</a>
                                        </td>
                                    </tr>
                                ) : <tr><td colSpan={7} className="text-center">Nessun record disponibile</td></tr>
                            }
                        </tbody>
                    </Table>
                    <BootstrapModals component={<CreateUser onClose={() => this.handleHide()} />} heading="Aggiungi Utente" show={this.state.AddModalShow}
                        onHide={() => this.handleHide()} className="user_create_modal" />
                    <BootstrapModals component={<EditUser edit={this.state.update_id} onClose={() => this.handleEditHide()} />} heading="Modifica Utente" show={this.state.EditModalShow}
                        onHide={() => this.handleEditHide()} className="user_update_modal" />


                </div>

            </>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
