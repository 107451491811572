import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import Footer from './Footer';
import { connect } from 'react-redux';

const mapStateToProps = state =>{
  return {
    ...state.login
  }
}
class PrivateRoute extends React.Component {
  constructor(props){
    super(props);
  }
  render(){
    const { component: Component, authed, ...rest } = this.props
    return (<Route
      {...rest}
      render={props => (
        authed
          ? <>
            <Sidebar admin={this.props.admin} />
            <main className="content">
              <Navbar />
              <Component {...props} />
              <Footer />
            </main>
          </>
          : <Redirect to="/sign-in" />
      )}
    />);
  }
}

export default connect(mapStateToProps)(PrivateRoute);