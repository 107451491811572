import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import {reducer as formReducer} from 'redux-form';
import login from './reducers/login';
import adminlist from './reducers/adminlist';
import app from './reducers/app';
import allergen from './reducers/allergen';
import category from './reducers/category';
import item from './reducers/item';
import restaurant from './reducers/restaurant';
import user from './reducers/user';
import settingdata from './reducers/settingdata';
import orders from './reducers/orders';

export default combineReducers({
    login,adminlist,app,allergen, category, item, restaurant, user, settingdata, orders,
    router: routerReducer,
    form: formReducer
  });