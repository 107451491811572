import React from "react";
import { Form } from 'react-bootstrap';
import Select from "react-select";
import DatePicker from "react-datepicker";
import Cleave from 'cleave.js/react';
import { Store } from "react-notifications-component";

const renderField = field => (
  <>
    <Form.Control type={field.type} placeholder={field.label}  {...field.input} />
    {field.meta.touched &&
      ((field.meta.error && <span className="cd-error-message" style={{ color: '#F90606', fontSize: '13px', fontWeight: '600' }}>Error : {field.meta.error}</span>) ||
        (field.meta.warning && <span>{field.meta.warning}</span>))}
  </>
)
const renderEditField = field => (
  <Form.Control type={field.type} {...field.input} />
)

export const renderSelectField = props => {
  const { input, options } = props;
  return (
    <>
      <Select
        {...input}
        onChange={value => input.onChange(value)}
        onBlur={() => input.onBlur(input.value)}
        options={options}
      />
      {props.meta.touched &&
        ((props.meta.error && <span className="cd-error-message" style={{ color: '#F90606', fontSize: '13px', fontWeight: '600' }}>Error : {props.meta.error}</span>) ||
          (props.meta.warning && <span>{props.meta.warning}</span>))}
    </>
  )
}

const renderDatePickerField = field => (
  <>
    <DatePicker dateFormat="yyyy-MM-dd" maxDate={new Date(getCalendarMinDate())} peekNextMonth="true" showMonthDropdown="true" showYearDropdown="true" dropdownMode="select" withPortal className="form-control" autoComplete="off" placeholderText="YYYY/MM/DD" {...field.input} selected={field.input.value ? field.input.value : null} />
    {field.meta.touched &&
      ((field.meta.error && <span className="cd-error-message" style={{ color: '#F90606', fontSize: '13px', fontWeight: '600' }}>Error : {field.meta.error}</span>) ||
        (field.meta.warning && <span>{field.meta.warning}</span>))}
  </>
)
const renderDatePickerEditField = field => (
  <>
    <DatePicker dateFormat="yyyy-MM-dd" maxDate={new Date(getCalendarMinDate())} peekNextMonth="true" showMonthDropdown="true" showYearDropdown="true" dropdownMode="select" withPortal className="form-control" placeholderText="YYYY/MM/DD" {...field.input} value={field.dataValue} />
    {field.meta.touched &&
      ((field.meta.error && <span className="cd-error-message" style={{ color: '#F90606', fontSize: '13px', fontWeight: '600' }}>Error : {field.meta.error}</span>) ||
        (field.meta.warning && <span>{field.meta.warning}</span>))}
  </>
)


const renderHtmlDatePickerField = field => (
  <>
    <Cleave placeholder="Date of Birth" className="full-width has-padding has-border"  {...field.input} options={{ date: true, delimiter: '-', datePattern: ['Y', 'm', 'd'] }} />
    {field.meta.touched &&
      ((field.meta.error && <span className="cd-error-message" style={{ color: '#F90606', fontSize: '13px', fontWeight: '600' }}>Error : {field.meta.error}</span>) ||
        (field.meta.warning && <span>{field.meta.warning}</span>))}
  </>
)

const textAreaField = field => (
  <>
    <Form.Control as="textarea" rows={3} className={field.tagclass} placeholder={field.label} maxLength={field.maxchar}  {...field.input} value={field.dataValue} />
    {field.meta.touched &&
      ((field.meta.error && <span className="cd-error-message" style={{ color: '#F90606', fontSize: '13px', fontWeight: '600' }}>Error : {field.meta.error}</span>) ||
        (field.meta.warning && <span>{field.meta.warning}</span>))}
  </>
)

export const parseCookie = str =>
  str
    .split(';')
    .map(v => v.split('='))
    .reduce((acc, v) => {
      acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
      return acc;
    }, {});

const required = value => value ? undefined : 'required';

const imageIsRequired = value => (!value ? "Required" : undefined);

const email = value => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Invalid Email Address' : undefined;

const dobCheck = value =>
  value && ((new Date(value)).getTime() > (new Date(getCalendarMinDate())).getTime() || (new Date(value)).getTime() < (new Date(getCalendarMaxDate())).getTime()) ? 'Date must between 1900-01-01 and ' + String(getCalendarMinDate()) : undefined

function getCalendarMinDate() {
  var date = new Date();
  var intYear = date.getFullYear() - 13;
  return (intYear + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2));
}
function setCalendarDate(date) {
  return (date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2));
}
function getCalendarMaxDate() {
  var date = new Date('1900-01-01');
  return (date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2));
}

function notification(msg, type) {
  Store.addNotification({
    message: msg,
    type: type,
    container: 'top-right',
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 3000
    }
  })
}

export {
  notification,
  renderField,
  renderEditField,
  required,
  email,
  dobCheck,
  getCalendarMinDate,
  textAreaField,
  imageIsRequired,
  renderDatePickerField,
  renderHtmlDatePickerField,
  setCalendarDate,
  renderDatePickerEditField

}