import { RESTAURENT_LIST, SINGLE_RESTAURANT, RESTAURANT_ITEMS,  UPDATED_RESTAURANT_DATA, RESTAURANT_OF_USER} from '../constants/actions';

export default (state = { openAuthLogin: false }, action = {}) => {
    switch (action.type) {
        case RESTAURENT_LIST:{
            return{
                ...state,
                restaurant: action.payload.data
            }
        }
        case SINGLE_RESTAURANT:{
            return{
                ...state,
                restaurantdata: action.payload.data
            }
        }
        case UPDATED_RESTAURANT_DATA:{
            return{
                ...state,
                editedrestaurant: action.payload.data
            }
        }
        case RESTAURANT_OF_USER:{
            return{
                ...state,
                user_rest: action.payload.data
            }
        }
            
        default:
            {
                return {
                    ...state,
                };
            }
    }
}