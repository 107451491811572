
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from "redux-form";
import { Routes } from "../../routes";
import { email, renderField, required } from "../../services/utileServices";
import { connect } from "react-redux";
import { resendOtp, submitOtp, ResetUserPassword } from "../../services/services";
import { Store } from "react-notifications-component";

const mapStateToProps = state => {
  return {
    ...state
  }
}

const mapDispatchToProps = dispatch => {
  return {
    async onPasswordReset (payload) {
      await ResetUserPassword(payload).then(result => {
        if (result.status) {
          Store.addNotification({
            message: "Password Reset successfully",
            type: 'success',
            container: 'top-right',
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 3000
            }
          })
        }
      }).catch(err => {
        Store.addNotification({
          message: "Error in reset password api ",
          type: 'warning',
          container: 'top-right',
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000
          }
        })
      })
    },

    async onFormSubmit(payload, that) {
      await resendOtp(payload).then(result => {
        if (result.data.success) {
          that.setState({ showOtpSection: true });
          Store.addNotification({
            message: "Otp Send successfully",
            type: 'success',
            container: 'top-right',
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 3000
            }
          })
        }else{
          Store.addNotification({
            message: result.data.error,
            type: 'warning',
            container: 'top-right',
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 3000
            }
          })
        }
      }).catch(function (err) {
        Store.addNotification({
          message: "Error in resendOtp api " + err.message,
          type: 'success',
          container: 'top-right',
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000
          }
        })
      })
    },

    async onOtpSubmit(payload) {
      await submitOtp(payload).then(result => {
        if (result.status) {
          Store.addNotification({
            message: "Otp Resend successfully",
            type: 'success',
            container: 'top-right',
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 3000
            }
          })
        }
      }).catch(err => {
        Store.addNotification({
          message: "Error in resendOtp api " + err.message,
          type: 'success',
          container: 'top-right',
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000
          }
        })
      })
    },

    onResendOtp: (payload) => {
      resendOtp(payload).then(result => {
        if (result.status) {
          Store.addNotification({
            message: "Otp Invia nuovamente con successo",
            type: 'success',
            container: 'top-right',
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 3000
            }
          })
        }
      }).catch(err => {
        Store.addNotification({
          message: "Errore nel reinvio Otp " + err.message,
          type: 'success',
          container: 'top-right',
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000
          }
        })
      })
    }
  }
}

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showOtpSection: false,
      otp: '',
      email: '',
      otpVerified: false
    }
    this.onSubmitting = this.onSubmitting.bind(this);
    this.otpVerification = this.otpVerification.bind(this);
    this.resendOtp = this.resendOtp.bind(this);
    this.handleOtp = this.handleOtp.bind(this);
  }

  onSubmitting = (values) => {
    if (this.state.otpVerified) {
      var that = this;
      var reset_pass = { EMAIL: this.state.email, NEW_PASSWORD: values.new_password, CONFIRM_PASSWORD: values.conf_password, OTP: this.state.otp };
      if (reset_pass.NEW_PASSWORD === reset_pass.CONFIRM_PASSWORD) {
        this.props.onPasswordReset(reset_pass).then(()=>{
          that.props.history.push('/sign-in');
        });
      } else {
        console.log("password Does not match");
      }
    } else {
      var email = { EMAIL: values.email };
      var that = this;
      this.setState({ email: email.EMAIL });
      this.props.onFormSubmit(email, this).then(() => {
      });
    }

  }

  handleOtp = (e) => {
    var enterOtp = e.target.value;
    this.setState({ otp: enterOtp });
  }

  otpVerification = () => {
    var that = this;
    this.props.onOtpSubmit({ OTP: this.state.otp }).then(() => {
      that.setState({ showOtpSection: false });
      that.setState({ otpVerified: true });
    });
  }

  resendOtp = () => {
    var that = this;
    this.props.onFormSubmit({ EMAIL: this.state.email }).then(() => {
      that.setState({ showOtpSection: true });
    });
  }

  render() {
    const { handleSubmit, submitting, error } = this.props;
    return (
      <main>
        <section className="vh-lg-100 mt-4 mt-lg-0 bg-soft d-flex align-items-center">
          <Container>
            <Row className="justify-content-center">
              <p className="text-center">
                <Card.Link as={Link} to={Routes.Signin.path} className="text-gray-700">
                  <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to sign in
                </Card.Link>
              </p>
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                <div className="signin-inner my-3 my-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">


                  {
                    this.state.showOtpSection ?
                      <div className="p-4">
                        <h3>Enter Otp</h3>
                        <input className="form-control my-4" type="text" onChange={(e) => this.handleOtp(e)} />
                        <div className="d-flex justify-content-between align-items-center py-3">
                          <Button variant="success" onClick={() => this.otpVerification()}>
                            Submit OTP
                          </Button>
                          <Button variant="danger" onClick={() => this.resendOtp()}>
                            Resend
                          </Button>
                        </div>
                      </div>
                      :
                      <div>
                        {
                          !this.state.otpVerified ?
                            <div>
                              <h3>Forgot your password?</h3>
                              <p className="mb-4">Don't fret! Just type in your email and we will send you a code to reset your password!</p>
                              <Form onSubmit={handleSubmit(this.onSubmitting)}>
                                <div className="mb-4">
                                  <Form.Label htmlFor="email">Your Email</Form.Label>
                                  <InputGroup id="email">
                                    <Field type="email" name="email" label="Enter Your Email" validate={[required, email]} component={renderField} />
                                  </InputGroup>
                                </div>
                                <Button variant="primary" disabled={submitting} type="submit" className="w-100">
                                  Send Otp
                                </Button>
                              </Form>
                            </div>
                            :
                            <div>
                              <h3 className="mb-4">Reset password</h3>
                              <Form onSubmit={handleSubmit(this.onSubmitting)}>
                                {error && <span className='alert-danger'>{error}</span>}
                                <Form.Group id="password" className="mb-4">
                                  <Form.Label>New Password</Form.Label>
                                  <InputGroup>
                                    <InputGroup.Text>
                                      <FontAwesomeIcon icon={faUnlockAlt} />
                                    </InputGroup.Text>
                                    <Field type="password" name="new_password" label="Enter New Password" validate={[required]} component={renderField} />
                                  </InputGroup>
                                </Form.Group>
                                <Form.Group id="confirmPassword" className="mb-4">
                                  <Form.Label>Confirm New Password</Form.Label>
                                  <InputGroup>
                                    <InputGroup.Text>
                                      <FontAwesomeIcon icon={faUnlockAlt} />
                                    </InputGroup.Text>
                                    <Field type="password" name="conf_password" label="Confirm New Password" validate={[required]} component={renderField} />
                                  </InputGroup>
                                </Form.Group>
                                <Button variant="primary" disabled={submitting} type="submit" className="float-end">
                                  Reset password
                                </Button>
                              </Form>
                            </div>
                        }
                      </div>
                  }
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main >
    );
  }
};

ForgotPassword = connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
export default reduxForm({
  form: 'forgot-password'
})(ForgotPassword);


