import React from 'react';
import { Table } from 'react-bootstrap';

export default (props) => {
    let data = [];
    if (props && props.dataFromParent) {
        data = JSON.parse(props.dataFromParent);
    }
    return (
        <>
            <Table responsive className="align-items-center table-flush">
                <thead className="thead-light">
                    <tr>
                        <th>SNo</th>
                        <th>Item Name</th>
                        <th>Position</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Total Price</th>
                        <th>Image</th>
                    </tr>

                </thead>
                <tbody>
                    {
                        (data != null && data != undefined && data != '') ? data.map((item, key) =>
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td>{item.ITEM_NAME}</td>
                                <td>{item.POSITION_MENU}</td>
                                <td>{item.PRICE}</td>
                                <td>{item.quantity}</td>
                                <td>{item.total}</td>
                                <td><img src={`${process.env.REACT_APP_API_URL + item.IMG_ITEM}`} alt="rest-logo" style={{ width: '100px', height: '100px' }} /></td>
                            </tr>
                        ) : null
                    }
                </tbody>
            </Table>

        </>
    )
}