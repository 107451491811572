
import React from "react";
import { Col, Row, Form, Button, Container } from '@themesberg/react-bootstrap';
import BgImage from "../../assets/img/illustrations/signin.svg";
import { connect } from "react-redux";
import { createAllergens, getAllAllergens, getRestItem } from "../../services/services";
import { ALLERGEN_LIST, RESTAURANT_ITEMS } from "../../constants/actions";
import { reduxForm, Field } from "redux-form";
import { renderField, required, notification } from "../../services/utileServices";
import Loader from "react-js-loader";


const mapStateToProps = state => {
    return {
        ...state.item,
        login: state.login,
        app: state.app,
        restaurant: state.restaurant
    }
}

const mapDispatchToProps = dispatch => {
    return {
        async onCreateAllergen(token, payload) {
            await createAllergens(token, payload).then(result => {
                if (result.data.success) {
                    notification('Allergene creato con successo', 'success');
                    getAllAllergens(token).then(result => {
                        if(result.data.success){
                            var payload = result.data;
                            dispatch({ type: ALLERGEN_LIST, payload });
                            console.log('Recupero dei dati sugli allergeni riuscito');
                        }
                    })
                }
            }).catch(function (err) {
                notification('Errore in Crea allergene ' + err.message, 'danger');
            })
        },
        onGetRestItem: (id) => {
            getRestItem(id).then(result => {
                if (result.data.success) {
                    var payload = result.data;
                    dispatch({ type: RESTAURANT_ITEMS, payload });
                }
            }).catch(function (err) {
                notification("Errore durante il recupero dell'articolo del ristorante " + err.message, 'danger');
            })
        },
    }
}

class CreateAllergen extends React.Component {
    constructor(props) {
        super(props);
        this.state ={
            loader:false
        }
        this.onSubmitting = this.onSubmitting.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    async onSubmitting(values) {
        this.setState({loader: true});
        // let al_name = await Translation(values.aname);
        var input_data = { RESTAURANT_ID: values.rest_id, ALLERGEN: values.anameen, ALLERGEN_FRENCH: values.anamefr, ALLERGEN_GERMEN: values.anamede, ALLERGEN_SPANISH: values.anamees, ALLERGEN_ITALIAN: values.anameit}
        var that = this;
        
        const CreateAllergen = async (data) => {
            await this.props.onCreateAllergen(this.props.app.token, data).then(function () {
                that.setState({loader: false});
                that.props.onClose();
            });
          }
      
          CreateAllergen(input_data);
    }

    handleSelect(e){
        let rest_id = e.target.value;
        this.props.onGetRestItem(rest_id);
    }

    render() {
        const { handleSubmit, submitting, error } = this.props;
        let restlist = [];
        let itemlist = [];
        if (this.props.restaurant.restaurant) {
            let rests = this.props.restaurant.restaurant;
            restlist = rests.map((rest, key) => (
                <option key={key} value={rest.RESTAURANT_ID}>{rest.NAME_ITALIAN}</option>
            ))
        }
      
        return (
            <main>
                <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
                    <Container>
                        <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
                            <Col xs={12} className="d-flex align-items-center justify-content-center">
                                <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                                    <div className="text-center text-md-center mb-4 mt-md-0">
                                        <h3 className="mb-0">Crea Allergene</h3>
                                    </div>
                                    {!this.state.loader?
                                    <Form className="mt-4" onSubmit={handleSubmit(this.onSubmitting)}>
                                        {error && <span className='alert-danger'>{error}</span>}
                                        <Form.Group id="item_name" className="mb-4">
                                            <Form.Label>Seleziona Ristorante</Form.Label>
                                            <Field className="form-control"
                                                name="rest_id"
                                                type="select"
                                                component="select"
                                                onChange={(e) => this.handleSelect(e)}
                                                validate={[required]}>
                                                <option value="">Seleziona Ristorante</option>
                                                {
                                                    restlist
                                                }
                                            </Field>
                                        </Form.Group>
                                        
                                        <Form.Group id="aname" className="mb-4">
                                            <Form.Label>Nome Allergene (it)</Form.Label>
                                            <Field name="anameit" component={renderField} type="text" label="Nome Allergene" validate={[required]} />
                                        </Form.Group>
                                        <Form.Group id="aname" className="mb-4">
                                            <Form.Label>Nome Allergene (en)</Form.Label>
                                            <Field name="anameen" component={renderField} type="text" label="Nome Allergene" validate={[required]} />
                                        </Form.Group>
                                        <Form.Group id="aname" className="mb-4">
                                            <Form.Label>Nome Allergene (es)</Form.Label>
                                            <Field name="anamees" component={renderField} type="text" label="Nome Allergene" validate={[required]} />
                                        </Form.Group>
                                        <Form.Group id="aname" className="mb-4">
                                            <Form.Label>Nome Allergene (de)</Form.Label>
                                            <Field name="anamede" component={renderField} type="text" label="Nome Allergene" validate={[required]} />
                                        </Form.Group>
                                        <Form.Group id="aname" className="mb-4">
                                            <Form.Label>Nome Allergene (fr)</Form.Label>
                                            <Field name="anamefr" component={renderField} type="text" label="Nome Allergene" validate={[required]} />
                                        </Form.Group>
                                        <Button variant="primary" disabled={submitting} type="submit" className="w-100">
                                            Crea
                                        </Button>
                                    </Form>: <div className="d-flex align-items-center justify-content-center" style={{ height: '100vh' }}><Loader type="bubble-scale" bgColor={"#BE1622"} color={'#BE1622'} size={100} /></div>}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
        );
    }
};

CreateAllergen = connect(mapStateToProps, mapDispatchToProps)(CreateAllergen);

export default reduxForm({
    form: 'create allergen form'
})(CreateAllergen);
