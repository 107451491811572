import { applyMiddleware, createStore } from "redux";
import reducer from "./reducer";
import { routerMiddleware } from "react-router-redux";
const createHistory = require('history').createBrowserHistory;

export const history = createHistory();

const myRouterMiddleware = routerMiddleware(history);

const getMiddleware = () =>{
    return applyMiddleware(myRouterMiddleware);
};

export const store = createStore(
    reducer, getMiddleware()
);