import React from "react";
import { Col, Row, Form, Button } from '@themesberg/react-bootstrap';
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { updateAdmin, updateUser, adminSettingData, userSettingData } from "../../services/services";
import { Store } from "react-notifications-component";
import { renderField, dobCheck, renderDatePickerEditField, setCalendarDate } from "../../services/utileServices";
import { ADMIN_SETTING_DATA, USER_SETTING_DATA } from "../../constants/actions";
import { setSessionItem } from "../../helpers/sessionHelper";

const mapStateToProps = state => {
    return {
        ...state.settingdata,
        app: state.app,
        login: state.login
    }
}

const mapDispatchToProps = dispatch => {
    return {
        async OnAdminUpdate(token, payload) {
            await updateAdmin(token, payload).then(result => {
                if (result.status) {
                    Store.addNotification({
                        message: "Amministratore aggiornato correttamente",
                        type: 'success',
                        container: 'top-right',
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 3000
                        }
                    })
                    adminSettingData(token).then(result => {
                        if (result.status) {
                            var payload = result.data;
                            dispatch({ type: ADMIN_SETTING_DATA, payload });
                        }
                    }).catch(function (err) {
                        Store.addNotification({
                            message: err.message,
                            type: 'warning',
                            container: 'top-right',
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 3000
                            }
                        })
                    })
                }
            }).catch(function (err) {
                Store.addNotification({
                    message: err.message,
                    type: 'warning',
                    container: 'top-right',
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 3000
                    }
                })
            })
        },
        async onUserUpdate(token, payload) {
            await updateUser(token, payload).then(result => {
                if (result.status) {
                    Store.addNotification({
                        message: "Utente aggiornato correttamente",
                        type: 'success',
                        container: 'top-right',
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 3000
                        }
                    })

                    userSettingData(token).then(result => {
                        if (result.status) {
                            var payload = result.data;
                            dispatch({ type: USER_SETTING_DATA, payload });
                        }
                    }).catch(function (err) {
                        Store.addNotification({
                            message: err.message,
                            type: 'warning',
                            container: 'top-right',
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 3000
                            }
                        })
                    })
                }
            }).catch(function (err) {
                Store.addNotification({
                    message: err.message,
                    type: 'warning',
                    container: 'top-right',
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 3000
                    }
                })
            })
        },

    }
}

class PersonalData extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dob: ''
        }
        this.onSubmitting = this.onSubmitting.bind(this);
        this.handleDateClick = this.handleDateClick.bind(this);
    }

    componentDidMount() {
        if (this.props.userSettingData != undefined) {
            var singleData = this.props.userSettingData;
            this.props.autofill('fname', singleData.FIRST_NAME);
            this.props.autofill('lname', singleData.LAST_NAME);
            this.props.autofill('id', singleData.ID);
            this.props.autofill('phone', singleData.MOBILE_NO);
            this.setState({ dob: singleData.DOB });
        } else if (this.props.adminSettingData != undefined) {
            var singleData = this.props.adminSettingData;
            this.props.autofill('fname', singleData.FIRST_NAME);
            this.props.autofill('lname', singleData.LAST_NAME);
            this.props.autofill('id', singleData.ID);
            this.props.autofill('phone', singleData.MOBILE_NO);
            this.setState({ dob: singleData.DOB });
        }
    }

    handleDateClick = (e) => {
        var datestring = setCalendarDate(new Date(e));
        this.setState({ dob: datestring });
    }

    onSubmitting = (values) => {
        var reg_form = { ID: values.id, FIRST_NAME: values.fname, LAST_NAME: values.lname, DOB: this.state.dob, MOBILE_NO: values.phone }
        var that = this;
        if (this.props.login.admin) {
            this.props.OnAdminUpdate(this.props.app.token, reg_form).then(() => {
                that.props.onClose();
            });
        } else {
            this.props.onUserUpdate(this.props.app.token, reg_form).then(() => {
                that.props.onClose();
            });;
        }
    }

    render() {
        const { handleSubmit, submitting, error } = this.props;
        return (
            <>
                <Row>
                    <Col>
                        <div style={{ padding: '20px' }}>
                            <div>
                                <div className="text-center text-md-center mb-4 mt-md-0">
                                    <h3 className="mb-0">Aggiorna le tue informazioni</h3>
                                </div>
                                <Form className="mt-4" onSubmit={handleSubmit(this.onSubmitting)}>
                                    {error && <span className='alert-danger'>{error}</span>}
                                    <Form.Group id="fname" className="mb-4">
                                        <Field name="id" component={renderField} type="hidden" />
                                        <Form.Label>Nome</Form.Label>
                                        <Field name="fname" component={renderField} type="text" label="Nome" />
                                    </Form.Group>
                                    <Form.Group id="lname" className="mb-4">
                                        <Form.Label>Cognome</Form.Label>
                                        <Field name="lname" component={renderField} type="text" label="Cognome" />
                                    </Form.Group>
                                    <Form.Group id="dob" className="mb-4">
                                        <Form.Label>Data di nascita</Form.Label>
                                        <Field label="Data di nascita" component={renderDatePickerEditField} name="dob" dataValue={this.state.dob ? this.state.dob : ''} validate={[dobCheck]} onChange={(e) => this.handleDateClick(e)} />
                                    </Form.Group>
                                    <Form.Group id="phone" className="mb-4">
                                        <Form.Label>Numero di Cellulare</Form.Label>
                                        <Field name="phone" component={renderField} type="text" label="Numero di Cellulare" />
                                    </Form.Group>

                                    <Button variant="primary" disabled={submitting} type="submit" className="float-end">
                                        Modifica
                                    </Button>
                                </Form>
                            </div>

                        </div>
                    </Col>
                </Row>
            </>
        )
    }
}

PersonalData = connect(mapStateToProps, mapDispatchToProps)(PersonalData);
export default reduxForm({
    form: 'user update form'
})(PersonalData);
