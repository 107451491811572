
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faGithub, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "../../routes";
import BgImage from "../../assets/img/illustrations/signin.svg";
import { connect } from "react-redux";
import { createAdmin } from "../../services/services";
import { APP_LOGIN_AUTH } from "../../constants/actions";
import {reduxForm, Field, SubmissionError} from "redux-form";
import { renderField, email, required } from "../../services/utileServices";
import { Store } from "react-notifications-component";

const mapStateToProps = state => {
  return {
    ...state,
    login: state.login,
    app: state.app
  }
}

const mapDispatchToProps = dispatch => {
  return {
    OnRegistration: (payload) => {
      createAdmin(payload).then(result => {
        var payload = result.data;
        dispatch({ type: APP_LOGIN_AUTH, payload });
        if (result.status) {
        }
      }).catch(function (err) {
        Store.addNotification({
          message: err.message,
          type: 'warning',
          container:'top-left',
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000 
          }
        })
      })
    }
  }
}

class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmitting = this.onSubmitting.bind(this);
  }

  onSubmitting = (values) => {
    var reg_form = {FIRST_NAME: values.fname, LAST_NAME: values.lname, EMAIL_ID: values.regEmail, PASSWORD: values.regPassword, CONFIRM_PASSWORD: values.regConfirmPassword}
    if (reg_form.PASSWORD === reg_form.CONFIRM_PASSWORD) {
      this.props.OnRegistration(reg_form);
    }else{
      console.log('Password Does not match');
    }
  }

  render() {
    const { handleSubmit, submitting, error } = this.props;
    return (
      <main>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h3 className="mb-0">Create an account</h3>
                  </div>
                  <Form className="mt-4" onSubmit={handleSubmit(this.onSubmitting)}>
                    <Form.Group id="fname" className="mb-4">
                      <Form.Label>First Name</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text>
                        <Field name="fname" component={renderField} type="text" label="Enter First Name" validate={[required]} />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group id="lname" className="mb-4">
                      <Form.Label>Last Name</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text>
                        <Field name="lname" component={renderField} type="text" label="Enter Last Name" validate={[required]} />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group id="regEmail" className="mb-4">
                      <Form.Label>Your Email</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text>
                        <Field name="regEmail" component={renderField} label="Enter Your Email" validate={[required, email]} type="email" />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group id="regPassword" className="mb-4">
                      <Form.Label>Your Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Field name="regPassword" type="password" component={renderField} validate={[required]} label="Enter Password" />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group id="regConfirmPassword" className="mb-4">
                      <Form.Label>Confirm Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Field name="regConfirmPassword" type="password" component={renderField} validate={[required]} label="Re-Enter Password" />
                      </InputGroup>
                    </Form.Group>
                    <FormCheck type="checkbox" className="d-flex mb-4">
                      <FormCheck.Input required id="terms" className="me-2" />
                      <FormCheck.Label htmlFor="terms">
                        I agree to the <Card.Link>terms and conditions</Card.Link>
                      </FormCheck.Label>
                    </FormCheck>

                    <Button variant="primary" type="submit" className="w-100">
                      Sign up
                    </Button>
                  </Form>

                  <div className="mt-3 mb-4 text-center">
                    <span className="fw-normal">or</span>
                  </div>
                  <div className="d-flex justify-content-center my-4">
                    <Button variant="outline-light" className="btn-icon-only btn-pill text-facebook me-2">
                      <FontAwesomeIcon icon={faFacebookF} />
                    </Button>
                    <Button variant="outline-light" className="btn-icon-only btn-pill text-twitter me-2">
                      <FontAwesomeIcon icon={faTwitter} />
                    </Button>
                    <Button variant="outline-light" className="btn-icon-only btn-pil text-dark">
                      <FontAwesomeIcon icon={faGithub} />
                    </Button>
                  </div>
                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <span className="fw-normal">
                      Already have an account?
                      <Card.Link as={Link} to={Routes.Signin.path} className="fw-bold">
                        {` Login here `}
                      </Card.Link>
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    );
  }
};

Signup = connect(mapStateToProps, mapDispatchToProps)(Signup);

export default reduxForm({
  form: 'registration form'
})(Signup);
