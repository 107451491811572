export const APP_LOGIN_AUTH = 'APP_LOGIN_AUTH';
export const ADMIN_LIST = 'ADMIN_LIST';
export const APP_LOAD = 'APP_LOAD';
export const APP_LOAD_TOKEN = 'APP_LOAD_TOKEN';
export const RESTAURENT_LIST = 'RESTAURENT_LIST';
export const ITEMS_LIST = 'ITEMS_LIST';
export const ALLERGEN_LIST = 'ALLERGEN_LIST';
export const CATEGORY_LIST = 'CATEGORY_LIST';
export const USER_LIST = 'USER_LIST';
export const USER_DATA_BY_ID = 'USER_DATA_BY_ID';
export const ADMIN_DATA_BY_ID = 'ADMIN_DATA_BY_ID';
export const SINGLE_CATEGORY = 'SINGLE_CATEGORY';
export const SINGLE_ITEM = 'SINGLE_ITEM';
export const SINGLE_RESTAURANT = 'SINGLE_RESTAURANT';
export const SINGLE_ALLERGEN = 'SINGLE_ALLERGEN';
export const UPDATED_USER_DATA = 'UPDATED_USER_DATA';
export const UPDATED_ADMIN_DATA = 'UPDATED_ADMIN_DATA';
export const UPDATED_RESTAURANT_DATA = 'UPDATED_RESTAURANT_DATA';
export const UPDATED_ITEM_DATA = 'UPDATED_ITEM_DATA';
export const VERIFY_ADMIN = 'VERIFY_ADMIN';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const RESTAURANT_CATEGORY_LIST = 'RESTAURANT_CATEGORY_LIST';
export const RESTAURANT_ITEMS = 'RESTAURANT_ITEMS';
export const CATEGORY_ITEMS = 'CATEGORY_ITEMS';
export const RESTAURANT_OF_USER = 'RESTAURANT_OF_USER';
export const RESTAURANT_OF_ALLERGEN = 'RESTAURANT_OF_ALLERGEN';
export const PROFILE_IMAGE = 'PROFILE_IMAGE';
export const DOB_CHANGE = 'DOB_CHANGE';
export const USER_SETTING_DATA = 'USER_SETTING_DATA';
export const ADMIN_SETTING_DATA = 'ADMIN_SETTING_DATA';
export const ORDER_DETAILS = 'ORDER_DETAILS';
export const REST_ORDER_DETAILS = 'REST_ORDER_DETAILS';
export const ALLERGEN_MAP_LIST = 'ALLERGEN_MAP_LIST';
export const SINGLE_ALLERGEN_MAP = 'SINGLE_ALLERGEN_MAP';
export const RESTAURANT_OF_ALLERGEN_MAP = 'RESTAURANT_OF_ALLERGEN_MAP';
export const ITEM_ALLERGEN = 'ITEM_ALLERGEN';
export const CLEAR_ITEM_ALLERGEN = 'CLEAR_ITEM_ALLERGEN';