
import React from "react";
import { Col, Row, Form, Button, Container } from '@themesberg/react-bootstrap';
import BgImage from "../../assets/img/illustrations/signin.svg";
import { connect } from "react-redux";
import { updateAllergens, getAllAllergens } from "../../services/services";
import { ALLERGEN_LIST } from "../../constants/actions";
import { reduxForm, Field } from "redux-form";
import { renderField, notification } from "../../services/utileServices";
import Loader from "react-js-loader";

const mapStateToProps = state => {
  return {
    ...state.allergen,
    app: state.app,
    restaurant: state.restaurant,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    async onUpdateAllergen(token, payload) {
      await updateAllergens(token, payload).then(result => {
        if (result.data.success) {
          notification('Allergene Aggiornato con successo', 'success');

          getAllAllergens(token).then(result => {
            if (result.data.success) {
              var payload = result.data;
              dispatch({ type: ALLERGEN_LIST, payload });
              notification('Tutti i dati relativi agli allergeni vengono recuperati correttamente', 'success');
            }
          }).catch(function (err) {
            notification('Errore nel recupero dei dati sugli allergeni ' + err.message, 'danger');
          })
        }
      }).catch(function (err) {
        notification("Errore nell'aggiornamento degli allergeni" + err.message, 'danger');
      })
    }
  }
}

class EditAllergen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false
    }
    this.onSubmitting = this.onSubmitting.bind(this);
  }

  componentDidMount() {
    if (this.props.allergendata) {
      let singleData = this.props.allergendata;
      this.props.autofill('id', singleData.ALLERGEN_ID);
      this.props.autofill('anameen', singleData.ALLERGEN);
      this.props.autofill('anamefr', singleData.ALLERGEN_FRENCH);
      this.props.autofill('anamede', singleData.ALLERGEN_GERMEN);
      this.props.autofill('anamees', singleData.ALLERGEN_SPANISH);
      this.props.autofill('anameit', singleData.ALLERGEN_ITALIAN);
    }
  }

  onSubmitting = async (values) => {
    this.setState({loader: true});
    let that = this;
    // let al_name = await Translation(values.aname);
    let input_data = { ALLERGEN_ID: values.id, ALLERGEN: values.anameen, ALLERGEN_FRENCH: values.anamefr, ALLERGEN_GERMEN: values.anamede, ALLERGEN_SPANISH: values.anamees, ALLERGEN_ITALIAN: values.anameit }

    const UpdateAllergen = async (data) => {
      await this.props.onUpdateAllergen(this.props.app.token, data).then(function () {
        that.setState({loader: false});
        that.props.onClose();
      });
    }

    UpdateAllergen(input_data);
    // var input_data = { ALLERGEN_ID: values.id, ALLERGEN: values.aname }

  }

  render() {
    console.log(this.props.allergendata);
    let single = '';
    if (this.props.allergendata) {
      single = this.props.allergendata;
    }
    let restlist = [];
    if (this.props.restaurant.restaurant) {
      var rests = this.props.restaurant.restaurant;
      restlist = rests.map((rest, key) => (
        <option key={key} value={rest.RESTAURANT_ID}>{rest.NAME}</option>
      ))
    }
    const { handleSubmit, submitting, error } = this.props;
    return (
      <main>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h3 className="mb-0">Modifica Allergene</h3>
                  </div>
                  {!this.state.loader?
                    <Form className="mt-4" onSubmit={handleSubmit(this.onSubmitting)}>
                      {error && <span className='alert-danger'>{error}</span>}
                      <Form.Group id="aname" className="mb-4">
                        <Form.Label>Nome Allergene (it)</Form.Label>
                        <Field name="id" component={renderField} type="hidden" />
                        <Field name="anameit" component={renderField} type="text" label="Nome Allergene" />
                      </Form.Group>
                      <Form.Group id="aname" className="mb-4">
                        <Form.Label>Nome Allergene (en)</Form.Label>
                        <Field name="anameen" component={renderField} type="text" label="Nome Allergene" />
                      </Form.Group>
                      <Form.Group id="aname" className="mb-4">
                        <Form.Label>Nome Allergene (fr)</Form.Label>
                        <Field name="anamefr" component={renderField} type="text" label="Nome Allergene" />
                      </Form.Group>
                      <Form.Group id="aname" className="mb-4">
                        <Form.Label>Nome Allergene (de)</Form.Label>
                        <Field name="anamede" component={renderField} type="text" label="Nome Allergene" />
                      </Form.Group>
                      <Form.Group id="aname" className="mb-4">
                        <Form.Label>Nome Allergene (es)</Form.Label>
                        <Field name="anamees" component={renderField} type="text" label="Nome Allergene" />
                      </Form.Group>
                      <Button variant="primary" disabled={submitting} type="submit" className="w-100">
                      Applica
                      </Button>
                    </Form> : <div className="d-flex align-items-center justify-content-center" style={{ height: '100vh' }}><Loader type="bubble-scale" bgColor={"#BE1622"} color={'#BE1622'} size={100} /></div>}


                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    );
  }
};

EditAllergen = connect(mapStateToProps, mapDispatchToProps)(EditAllergen);

export default reduxForm({
  form: 'update user'
})(EditAllergen);
