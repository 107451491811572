
import React from "react";
import { Col, Row, Form, Table, Container } from '@themesberg/react-bootstrap';
import { connect } from "react-redux";
import { createAllergenMap, deleteAllergenmap, getAllergenByItemId } from "../../services/services";
import { ITEM_ALLERGEN } from "../../constants/actions";
import { notification } from "../../services/utileServices";

const mapStateToProps = state => {
    return {
        ...state.allergen,
        app: state.app
    }
}

const mapDispatchToProps = dispatch => {
    return {
        async onCreateAllergenMap(token, payload, id, rest_id) {
            await createAllergenMap(token, payload).then(result => {
                if (result.data.success) {
                    notification('Allergene oggetto creato con successo', 'success');
                    getAllergenByItemId(id, rest_id).then(result => {
                        if (result.data.success) {
                            var payload = result.data;
                            dispatch({ type: ITEM_ALLERGEN, payload });
                            notification("Recupero dell'allergene dell'oggetto riuscito", 'success');
                        }
                    }).catch(function (err) {
                        notification("Errore nel recupero dei dati sugli allergeni dell'articolo ", 'danger');
                    })
                }
            }).catch(function (err) {
                notification('Errore in Crea allergene ' + err.message, 'danger');
            })
        },

        onDelete: (token, id, item_id, rest_id) => {
            deleteAllergenmap(token, id, rest_id).then(result => {
                if (result.data.success) {
                    notification("Articolo Allergene eliminato con successo", 'success');
                    getAllergenByItemId(item_id, rest_id).then(result => {
                        if (result.data.success) {
                            var payload = result.data;
                            dispatch({ type: ITEM_ALLERGEN, payload });
                            notification("Recupero dell'allergene dell'oggetto riuscito", 'success');
                        }
                    }).catch(function (err) {
                        notification("Errore durante il recupero dei dati sugli allergeni dell'articolo ", 'danger');
                    })
                }
            }).catch(function (err) {
                notification("Errore nell'eliminazione della mappa degli allergeni " + err.message, 'danger');
            })
        },
    }
}

class CreateAllergenMap extends React.Component {
    constructor(props) {
        super(props);
        this.handleSelect = this.handleSelect.bind(this);
    }

    handleDelete(id) {
        this.props.onDelete(this.props.app.token, id, this.props.itemId ? this.props.itemId : null, this.props.restId ? this.props.restId : null)
    }

    handleSelect(e) {
        let al_id = e.target.value;
        let data = { ALLERGEN_ID: al_id, RESTAURANT_ID: this.props.restId ? this.props.restId : null, ITEM_ID: this.props.itemId ? this.props.itemId : null }
        let that = this;
        this.props.onCreateAllergenMap(this.props.app.token, data, data.ITEM_ID, data.RESTAURANT_ID).then(() => {
        })
    }

    render() {
        let itemAllergen = [];
        let allergenList = [];

        if (this.props && this.props.restallergen) {
            let allergen = this.props.restallergen;
            allergenList = allergen.map((item, key) => (
                <option key={key} value={item.ALLERGEN_ID}>{item.ALLERGEN_ITALIAN}</option>
            ))
        }

        if (this.props && this.props.itemallergen) {
            itemAllergen = this.props.itemallergen;
        }

        return (
            <main>
                <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
                    <Container>
                        <div className="mx-auto my-3" style={{ width: '50%' }}>
                            <div className="text-center text-md-center mb-4 mt-md-0">
                                <h3 className="mb-0">Crea allergene per articolo</h3>
                            </div>
                            <Form.Select onChange={(e) => this.handleSelect(e)}>
                                <option value="">Seleziona Allergeni</option>
                                {
                                    allergenList
                                }
                            </Form.Select>
                        </div>
                        <Table responsive className="align-items-center table-flush mt-5">
                            <thead className="thead-light">
                                <tr>
                                    <th>SNo</th>
                                    <th>Allergeni</th>
                                    <th>Elimina</th>
                                </tr>
                            </thead>
                            <tbody className="bg-light">

                                {(itemAllergen && itemAllergen != '' && itemAllergen != undefined) ? itemAllergen.map((allergen, key) =>
                                    <tr key={key}>
                                        <td>{key + 1}</td>
                                        <td>{allergen.ALLERGEN.it}</td>
                                        <td><span style={{ cursor: 'pointer', color: 'red' }} onClick={() => this.handleDelete(allergen.ID)}>Elimina</span></td>
                                    </tr>
                                ) : <tr><th colSpan={3} className="text-center">Nessun record disponibile</th></tr>
                                }
                            </tbody>
                        </Table>
                    </Container>
                </section>
            </main>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAllergenMap);

