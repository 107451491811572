
import React from "react";
import { Col, Row, Form, Button, Container } from '@themesberg/react-bootstrap';
import BgImage from "../../assets/img/illustrations/signin.svg";
import { connect } from "react-redux";
import { createItem, getAllCategories, getItems, getRestaurantCategories } from "../../services/services";
import { CATEGORY_LIST, ITEMS_LIST, RESTAURANT_CATEGORY_LIST } from "../../constants/actions";
import { reduxForm, Field } from "redux-form";
import { renderField, required, textAreaField, notification } from "../../services/utileServices";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import Loader from "react-js-loader";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileEncode, FilePondPluginFileValidateType, FilePondPluginFileValidateSize);


const mapStateToProps = state => {
  return {
    ...state.category,
    app: state.app,
    restaurant: state.restaurant
  }
}

const mapDispatchToProps = dispatch => {
  return {
    async onCreateItem(token, payload) {
      await createItem(token, payload).then(result => {
        if (result.data.success) {
          notification('Elemento Aggiunto', 'success');
          getItems(token).then(result => {
            if (result.data.success) {
              var payload = result.data;
              dispatch({ type: ITEMS_LIST, payload });
              console.log('fetch successfully');
            }
          }).catch(function (err) {
            notification('Errore nel recupero degli elementi ' + err.message, 'danger');
          })

        }
      }).catch(function (err) {
        notification('Errore nella creazione di elementi ' + err.message, 'danger');
      })
    },
    onGetCategory: (id) => {
      getRestaurantCategories(id).then(result => {
        if (result.data.success) {
          var payload = result.data;
          dispatch({ type: RESTAURANT_CATEGORY_LIST, payload });
          notification('Tutti gli elenchi delle categorie di ristoranti sono stati recuperati correttamente', 'success');
        }
      }).catch(function (err) {
        notification("Errore nel recupero dell'elenco delle categorie di ristoranti " + err.message, 'danger');
      })
    },
    onGetAllCategory: (token) => {
      getAllCategories(token).then(result => {
        if (result.data.success) {
          var payload = result.data;
          dispatch({ type: CATEGORY_LIST, payload });
          notification('Tutti i dati sono caricati correttamente', 'success');
        }
      }).catch(function (err) {
        notification("Errore nel recupero dell'elenco delle categorie " + err.message, 'danger');
      })
    },


  }
}

class CreateItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      RestCat: true,
      loader: false
    }
    this.onSubmitting = this.onSubmitting.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  async onSubmitting(values) {
    let form64 = '';
    this.setState({loader: true});
    if (this.pond.getFiles().length > 0) {
      form64 = this.pond.getFiles()[0].getFileEncodeBase64String();
    }
    var that = this;

    var reg_form = {
      RESTAURANT_ID: values.rest_id, CATEGORY_ID: values.cat_id, ITEM_NAME: values.inameen, PRICE: values.iprice, DESCRIPTION: values.idescen, IMG_ITEM: form64, AVAILABLE: values.iavail, POSITION_MENU: values.ipos,
      ITEM_NAME_FRENCH: values.inamefr, ITEM_NAME_ITALIAN: values.inameit, ITEM_NAME_SPANISH: values.inamees, ITEM_NAME_GERMEN: values.inamede, DESCRIPTION_FRENCH: values.idescfr, DESCRIPTION_ITALIAN: values.idescit, DESCRIPTION_SPANISH: values.idesces, DESCRIPTION_GERMEN: values.idescde
    }

    const CreateItem = async (data) => {
      await this.props.onCreateItem(this.props.app.token, data).then(() => {
        that.setState({loader: false});
        that.props.onClose();
      });
    }

      if (reg_form.CATEGORY_ID != undefined && reg_form.RESTAURANT_ID != undefined) {
        if (reg_form.AVAILABLE == undefined) {
          reg_form.AVAILABLE = 1;
        }
        CreateItem(reg_form)
      } else {
        notification('Category Field or Restaurant Field Should Be selected', 'warning');
      }
  }

  handleSelect = (e) => {
    var rest_id = e.target.value;
    if (rest_id == '' || rest_id == undefined) {
      this.setState({ RestCat: false });
      this.props.onGetAllCategory(this.props.app.token);
    } else {
      this.props.onGetCategory(rest_id);
    }
  }

  render() {
    let restlist = [];
    let catlist = [];
    if (this.props) {
      if (this.props.restcat && this.state.RestCat) {
        var cats = this.props.restcat;
        catlist = cats.map((cat, key) => (
          <option key={key} value={cat.CATEGORY_ID}>{cat.CATEGORY_NAME_ITALIAN}</option>
        ))
      }
      else {
        if (this.props.category) {
          var cats = this.props.category;
          catlist = cats.map((cat, key) => (
            <option key={key} value={cat.CATEGORY_ID}>{cat.CATEGORY_NAME_ITALIAN}</option>
          ))
        }
      }
    }
    if (this.props.restaurant.restaurant) {
      var rests = this.props.restaurant.restaurant;
      restlist = rests.map((rest, key) => (
        <option key={key} value={rest.RESTAURANT_ID}>{rest.NAME_ITALIAN}</option>
      ))
    }
    const { handleSubmit, submitting, error } = this.props;

    const pond = {
      multiple: false,
      name: 'image',
      allowMultiple: false,
      instantUpload: false,
      fetch: null,
      revert: null,
      allowFileEncode: true,
      allowFileSizeValidation: true,
      maxFileSize: 1024000,
      labelMaxFileSizeExceeded: 'File is too large',
      labelIdle: "Trascina e rilascia qui  i tuoi file o Sfoglia"
    }

    return (
      <main>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h3 className="mb-0">Crea Voce di Menu</h3>
                  </div>
                  {!this.state.loader?
                    <Form className="mt-4" onSubmit={handleSubmit(this.onSubmitting)}>
                      {error && <span className='alert-danger'>{error}</span>}
                      <Form.Group id="item_name" className="mb-4">
                        <Form.Label>Seleziona Ristorante</Form.Label>
                        <Field className="form-control"
                          name="rest_id"
                          type="select"
                          component="select"
                          onChange={(e) => this.handleSelect(e)}
                          required>
                          <option value="">Seleziona Ristorante</option>
                          {
                            restlist
                          }
                        </Field>
                      </Form.Group>
                      <Form.Group id="item_name" className="mb-4">
                        <Form.Label>Seleziona Categoria</Form.Label>
                        <Field className="form-control"
                          name="cat_id"
                          type="select"
                          component="select"
                          onChange={() => this.handleSelectCat}
                          required>
                          <option value="">Seleziona Categoria</option>
                          {
                            catlist
                          }
                        </Field>
                      </Form.Group>
                      <Form.Group id="iname" className="mb-4">
                        <Form.Label>Voce di Menu (it)</Form.Label>
                        <Field name="inameit" component={renderField} type="text" label="Voce di Menu" validate={[required]} />
                      </Form.Group>
                      <Form.Group id="iname" className="mb-4">
                        <Form.Label>Voce di Menu (en)</Form.Label>
                        <Field name="inameen" component={renderField} type="text" label="Voce di Menu" validate={[required]} />
                      </Form.Group>
                      <Form.Group id="iname" className="mb-4">
                        <Form.Label>Voce di Menu (fr)</Form.Label>
                        <Field name="inamefr" component={renderField} type="text" label="Voce di Menu" validate={[required]} />
                      </Form.Group>
                      <Form.Group id="iname" className="mb-4">
                        <Form.Label>Voce di Menu (de)</Form.Label>
                        <Field name="inamede" component={renderField} type="text" label="Voce di Menu" validate={[required]} />
                      </Form.Group>
                      <Form.Group id="iname" className="mb-4">
                        <Form.Label>Voce di Menu (es)</Form.Label>
                        <Field name="inamees" component={renderField} type="text" label="Voce di Menu" validate={[required]} />
                      </Form.Group>
                      <Form.Group id="iprice" className="mb-4">
                        <Form.Label>Prezzo</Form.Label>
                        <Field name="iprice" component={renderField} type="number" label="Prezzo" validate={[required]} />
                      </Form.Group>
                      <Form.Group id="idesc" className="mb-4">
                        <Form.Label>Descrizione (it)</Form.Label>
                        <Field name="idescit" type="textarea" component={textAreaField} validate={[required]} label="Scrivi una descrizione qui" />
                      </Form.Group>
                      <Form.Group id="idesc" className="mb-4">
                        <Form.Label>Descrizione (en)</Form.Label>
                        <Field name="idescen" type="textarea" component={textAreaField} validate={[required]} label="Scrivi una descrizione qui" />
                      </Form.Group>
                      <Form.Group id="idesc" className="mb-4">
                        <Form.Label>Descrizione (fr)</Form.Label>
                        <Field name="idescfr" type="textarea" component={textAreaField} validate={[required]} label="Scrivi una descrizione qui" />
                      </Form.Group>
                      <Form.Group id="idesc" className="mb-4">
                        <Form.Label>Descrizione (de)</Form.Label>
                        <Field name="idescde" type="textarea" component={textAreaField} validate={[required]} label="Scrivi una descrizione qui" />
                      </Form.Group>
                      <Form.Group id="idesc" className="mb-4">
                        <Form.Label>Descrizione (es)</Form.Label>
                        <Field name="idesces" type="textarea" component={textAreaField} validate={[required]} label="Scrivi una descrizione qui" />
                      </Form.Group>
                      <Form.Group id="i_image" className="mb-4">
                        <Form.Label>Carica immagine</Form.Label>
                        <FilePond  {...pond} ref={ref => (this.pond = ref)} acceptedFileTypes={['image/*']} />
                      </Form.Group>
                      <Form.Group id="iavail" className="mb-4">
                        <Form.Label>Disponibilità prodotto</Form.Label>
                        {/* <Field name="iavail" type="number" component={renderField} validate={[required]} label="Enter Number of items available" /> */}
                        <Field className="form-control"
                          name="iavail"
                          type="select"
                          component="select"
                        >
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </Field>
                      </Form.Group>
                      <Form.Group id="ipos" className="mb-4">
                        <Form.Label>Posizione</Form.Label>
                        <Field name="ipos" type="number" component={renderField} validate={[required]} label="Posizione" />
                      </Form.Group>

                      <Button variant="primary" disabled={submitting} type="submit" className="w-100">
                        Crea
                      </Button>
                    </Form> : <div className="d-flex align-items-center justify-content-center" style={{ height: '100vh' }}><Loader type="bubble-scale" bgColor={"#BE1622"} color={'#BE1622'} size={100} /></div>}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    );
  }
};

CreateItem = connect(mapStateToProps, mapDispatchToProps)(CreateItem);

export default reduxForm({
  form: 'create item form'
})(CreateItem);
