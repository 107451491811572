import { USER_SETTING_DATA, ADMIN_SETTING_DATA} from '../constants/actions';

export default (state, action = {}) => {
    switch (action.type) {
        case USER_SETTING_DATA:
            {
                return { userSettingData: action.payload.data }
            }
            
        case ADMIN_SETTING_DATA:
            {
                return { adminSettingData: action.payload.data }
            }
            
        default:
            {
                return {
                    ...state,
                };
            }
    }
}