import jsCookie from 'js-cookie';

function setSessionItem(token, user, fname, lname, id, profile) {
  jsCookie.set(`scevoo_jwt${process.env.REACT_APP_SESSION_KEY}`, token);
  jsCookie.set(`scevoo_user${process.env.REACT_APP_SESSION_KEY}`, user);
  jsCookie.set(`scevoo_fname${process.env.REACT_APP_SESSION_KEY}`, fname);
  jsCookie.set(`scevoo_lname${process.env.REACT_APP_SESSION_KEY}`, lname);
  jsCookie.set(`scevoo_id${process.env.REACT_APP_SESSION_KEY}`, id);
  jsCookie.set(`scevoo_profile${process.env.REACT_APP_SESSION_KEY}`, profile);
  
}
function updateSessionUser(fname, lname) {
  jsCookie.set(`scevoo_fname${process.env.REACT_APP_SESSION_KEY}`, fname);
  jsCookie.set(`scevoo_lname${process.env.REACT_APP_SESSION_KEY}`, lname);
}

function updateProfilePic(profile){
  jsCookie.set(`scevoo_profile${process.env.REACT_APP_SESSION_KEY}`, profile);
}
function getSessionItem() {
  return (
    {
      token: jsCookie.get(`scevoo_jwt${process.env.REACT_APP_SESSION_KEY}`) ? jsCookie.get(`scevoo_jwt${process.env.REACT_APP_SESSION_KEY}`) : null,
      user: jsCookie.get(`scevoo_user${process.env.REACT_APP_SESSION_KEY}`) ? jsCookie.get(`scevoo_user${process.env.REACT_APP_SESSION_KEY}`) : null,
      fname: jsCookie.get(`scevoo_fname${process.env.REACT_APP_SESSION_KEY}`) ? jsCookie.get(`scevoo_fname${process.env.REACT_APP_SESSION_KEY}`) : null,
      lname: jsCookie.get(`scevoo_lname${process.env.REACT_APP_SESSION_KEY}`) ? jsCookie.get(`scevoo_lname${process.env.REACT_APP_SESSION_KEY}`) : null,
      id: jsCookie.get(`scevoo_id${process.env.REACT_APP_SESSION_KEY}`) ? jsCookie.get(`scevoo_id${process.env.REACT_APP_SESSION_KEY}`) : null,
      profile: jsCookie.get(`scevoo_profile${process.env.REACT_APP_SESSION_KEY}`) ? jsCookie.get(`scevoo_profile${process.env.REACT_APP_SESSION_KEY}`) : null,
    }
  );
}

function removeSessionItem() {
  jsCookie.remove(`scevoo_jwt${process.env.REACT_APP_SESSION_KEY}`);
  jsCookie.remove(`scevoo_user${process.env.REACT_APP_SESSION_KEY}`);
  jsCookie.remove(`scevoo_fname${process.env.REACT_APP_SESSION_KEY}`);
  jsCookie.remove(`scevoo_lname${process.env.REACT_APP_SESSION_KEY}`);
  jsCookie.remove(`scevoo_id${process.env.REACT_APP_SESSION_KEY}`);
  jsCookie.remove(`scevoo_profile${process.env.REACT_APP_SESSION_KEY}`);

}

export {
  setSessionItem,
  getSessionItem,
  removeSessionItem,
  updateSessionUser,
  updateProfilePic
}
