
import React from "react";
import { Col, Row, Button, Container } from '@themesberg/react-bootstrap';
import { connect } from "react-redux";
import { verifyUser } from "../../services/services";

const mapStateToProps = state => {
    return {
        ...state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onVerifyUser: (payload) => {
            verifyUser(payload).then(result => {
                if(result.status){
                    console.log("user verified");
                }
            }).catch(function (err) {
                console.log("error in verify api ", err.message);
            })
        }
    }
}

class VerifyUser extends React.Component {
    constructor(props) {
        super(props);
        this.handleAcceptUser = this.handleAcceptUser.bind(this);
    }

    handleAcceptUser = (data) =>{
        if(data == true){
            this.props.onVerifyUser({data: true});
        }else{
            this.props.onVerifyUser({data: false});
        }
    }


    render() {
        return (
            <Container>
                <Row className="justify-content-center">
                    <Col className="mt-5">
                        <div className="p-3 mx-auto bg-white rounded-3 px-5" style={{width: '50%'}}>
                            <h3>Verify User</h3>
                            <p>Click Accept button to Accept user</p>
                            <div className="d-flex justify-content-between align-items-center">
                                <Button variant="success" onClick={() => this.handleAcceptUser(true)}>
                                    Accept
                                </Button>
                                <Button variant="danger" onClick={() => this.handleAcceptUser(false)}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyUser);



