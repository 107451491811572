import { APP_LOAD, APP_LOAD_TOKEN, CLOSE_MODAL, PROFILE_IMAGE, USER_SETTING_DATA, ADMIN_SETTING_DATA } from "../constants/actions";

export default (state = { Notification: false }, action) => {
    switch (action.type) {
        case APP_LOAD:
            {
                return {
                    ...state,
                    token: action.payload.token,
                    user: action.payload.user
                }
            }
        case APP_LOAD_TOKEN:
            {
                return {
                    ...state,
                    token: action.payload.token
                }
            }
        case CLOSE_MODAL:
            {
                return {
                    close: action.payload ? action.payload : false
                }
            }
        case PROFILE_IMAGE:
            {
                return {
                    profile: action.payload.data
                }
            }

        default:
            {
                return {
                    ...state
                }
            }
    }
}