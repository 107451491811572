import React from "react";
import { Modal, Button } from "react-bootstrap";

const BootstrapModals = (props) => {
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                id="bootstrap_modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {props.heading}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.component}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Chiudi</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default BootstrapModals;