
import React from "react";
import { Col, Row, Form, Button, Container } from '@themesberg/react-bootstrap';
import BgImage from "../../assets/img/illustrations/signin.svg";
import { connect } from "react-redux";
import { createCategory, getAllCategories } from "../../services/services";
import { CATEGORY_LIST } from "../../constants/actions";
import { reduxForm, Field } from "redux-form";
import { renderField, required, notification } from "../../services/utileServices";
import Loader from "react-js-loader";

const mapStateToProps = state => {
  return {
    ...state,
    app: state.app,
    restaurant: state.restaurant
  }
}

const mapDispatchToProps = dispatch => {
  return {
    async onCreateCategory(token, payload) {
      await createCategory(token, payload).then(result => {
        if (result.data.success) {
          notification('Categoria Creata con successo', 'success');
          getAllCategories(token).then(result => {
            if(result.data.success){
              var payload = result.data;
              dispatch({ type: CATEGORY_LIST, payload });
              console.log('fetch successfully');
            }
          }).catch(function (err) {
            notification('Errore nella categoria di recupero' + err.message, 'danger');
          })
        }
      }).catch(function (err) {
        notification('Errore nella categoria Crea ' + err.message, 'danger');
      })
    }
  }
}

class CreateCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      detectedLang: '',
      allLang: {},
      loader:false
    }
    this.onSubmitting = this.onSubmitting.bind(this);
  }

  onSubmitting = async(values) => {
    this.setState({loader: true});
    let that = this;
    // let input_data = await Translation(values.cname);
    var reg_form = { RESTAURANT_ID: values.rest_id, CATEGORY_NAME: values.cnameen, POSITION_MENU: values.cat_pos, CATEGORY_NAME_FRENCH: values.cnamefr,
      CATEGORY_NAME_SPANISH: values.cnamees, CATEGORY_NAME_GERMEN: values.cnamede, CATEGORY_NAME_ITALIAN: values.cnameit }
   
    const CreateCat = async (data) => {
      await this.props.onCreateCategory(this.props.app.token, data).then(function () {
        that.setState({loader: false});
        that.props.onClose();
      });
    }

    CreateCat(reg_form);
    
  }

  render() {
    let restlist = [];
    if (this.props.restaurant.restaurant) {
      const restlists = this.props.restaurant.restaurant;
      restlist = restlists.map((rest, key) => (
        <option key={key} value={rest.RESTAURANT_ID} >{rest.NAME_ITALIAN}</option>
      ))
    }
    const { handleSubmit, submitting, error } = this.props;
    return (
      <main>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h3 className="mb-0">Crea Categoria</h3>
                  </div>
                  {!this.state.loader?
                  <Form className="mt-4" onSubmit={handleSubmit(this.onSubmitting)}>
                    {error && <span className='alert-danger'>{error}</span>}
                    <Form.Group id="fname" className="mb-4">
                      <Field className="form-control"
                        name="rest_id"
                        type="select"
                        component="select"
                        validate={[required]}
                        onChange={() => this.handleSelect}>
                        <option value="">Seleziona Ristorante</option>
                        {
                          restlist
                        }
                      </Field>
                    </Form.Group>
                    <Form.Group id="cname" className="mb-4">
                      <Form.Label>Nome categoria (it)</Form.Label>
                      <Field name="cnameit" component={renderField} type="text" label="Nome categoria" validate={[required]} />
                    </Form.Group>
                    <Form.Group id="cname" className="mb-4">
                      <Form.Label>Nome categoria (en)</Form.Label>
                      <Field name="cnameen" component={renderField} type="text" label="Nome categoria" validate={[required]} />
                    </Form.Group>
                    <Form.Group id="cname" className="mb-4">
                      <Form.Label>Nome categoria (fr)</Form.Label>
                      <Field name="cnamefr" component={renderField} type="text" label="Nome categoria" validate={[required]} />
                    </Form.Group>
                    <Form.Group id="cname" className="mb-4">
                      <Form.Label>Nome categoria (de)</Form.Label>
                      <Field name="cnamede" component={renderField} type="text" label="Nome categoria" validate={[required]} />
                    </Form.Group>
                    <Form.Group id="cname" className="mb-4">
                      <Form.Label>Nome categoria (es)</Form.Label>
                      <Field name="cnamees" component={renderField} type="text" label="Nome categoria" validate={[required]} />
                    </Form.Group>
                    <Form.Group id="cat_pos" className="mb-4">
                      <Form.Label>Posizione</Form.Label>
                        <Field name="cat_pos" component={renderField} label="Posizione" validate={[required]} type="number" />
                    </Form.Group>

                    <Button variant="primary" disabled={submitting} type="submit" className="w-100">
                    Crea
                    </Button>
                  </Form> : <div className="d-flex align-items-center justify-content-center" style={{ height: '100vh' }}><Loader type="bubble-scale" bgColor={"#BE1622"} color={'#BE1622'} size={100} /></div>}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    );
  }
};

CreateCategory = connect(mapStateToProps, mapDispatchToProps)(CreateCategory);

export default reduxForm({
  form: 'create category form'
})(CreateCategory);
