import { CATEGORY_LIST, SINGLE_CATEGORY, RESTAURANT_CATEGORY_LIST} from '../constants/actions';

export default (state = { openAuthLogin: false }, action = {}) => {
    switch (action.type) {
        case CATEGORY_LIST:{
            return{
                ...state,
                category: action.payload.data
            }
        }
        case SINGLE_CATEGORY:{
            return{
                ...state,
                categorydata: action.payload.data
            }
        }
        case RESTAURANT_CATEGORY_LIST: {
            return{
                ...state,
                restcat: action.payload.data
            }
        }  
        default:
            {
                return {
                    ...state,
                };
            }
    }
}