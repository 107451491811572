import React from 'react';
import { HashRouter } from "react-router-dom";
import "./scss/volt.scss";
import "react-datetime/css/react-datetime.css";
import { connect } from 'react-redux';
import { APP_LOAD, VERIFY_ADMIN } from './constants/actions';
import { setSessionItem, getSessionItem, removeSessionItem } from './helpers/sessionHelper';
import HomePage from "./pages/HomePage";
import { verifyAdminToken } from './services/services';
import { ReactNotifications } from 'react-notifications-component'
import './assets/css/index.css'
import 'filepond/dist/filepond.min.css';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css";
import "react-datepicker/dist/react-datepicker.css";

const mapStateToProps = state => {
  const { token, user, fname, lname, id } = getSessionItem();

  return {
    ...state.login,
    token: token,
    user: user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLoad: (payload) => {
      dispatch({ type: APP_LOAD, payload });
    },
    onVerifyToken: (token) => {
      verifyAdminToken(token).then(result => {
        var payload = result.data;
        dispatch({ type: VERIFY_ADMIN, payload });
      })
    }
  }
}

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      componentReady: false
    }
  }


  componentDidMount() {
  //   window.addEventListener(
  //     "beforeunload",
  //     removeSessionItem()
  // );
    const { token, user, fname, lname, id, profile } = getSessionItem();
    this.props.onVerifyToken(token);
    var payload = [];
    payload.token = token ? token : null;
    payload.user = user ? user : null;
    payload.fname = fname ? fname : null;
    payload.lname = lname ? lname : null;
    payload.id = id ? id : null;
    payload.profile = profile ? profile : null;
    this.props.onLoad(payload);
    if (token && token != null) {
      this.setState({ componentReady: true })
      setSessionItem(token, user, fname, lname, id, profile);
    } else {
      this.setState({ componentReady: true })
    }
  }
  render() {
    return (
      <>
        <HashRouter>
          <ReactNotifications />
          <HomePage data={this.props.token} admin={this.props.admin} />
        </HashRouter>
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
