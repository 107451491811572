
import React from "react";
import { Col, Row, Form, Button, Container } from '@themesberg/react-bootstrap';
import BgImage from "../../assets/img/illustrations/signin.svg";
import { connect } from "react-redux";
import { updateUser, getAllUsers } from "../../services/services";
import { USER_LIST, DOB_CHANGE } from "../../constants/actions";
import { reduxForm, Field } from "redux-form";
import { renderEditField, email, renderDatePickerEditField, textAreaField, setCalendarDate, notification, required } from "../../services/utileServices";
import { getSessionItem } from "../../helpers/sessionHelper";
import Loader from "react-js-loader";

const mapStateToProps = state => {
  return {
    ...state.login,
    app: state.app,
    single: state.user.single,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    async onUserUpdate(token, payload) {
      await updateUser(token, payload).then(result => {
        if (result.data.success) {
          notification('Utente aggiornato correttamente', 'success');

          getAllUsers().then(result => {
            if (result.data.success) {
              var payload = result.data;
              dispatch({ type: USER_LIST, payload });
              console.log("Recupero dell'elenco utenti riuscito");
            }
          }).catch(function (err) {
            notification('Errore durante il recupero dei dati utente ' + err.message, 'danger');
          })
        }
      }).catch(function (err) {
        notification("Errore nell'aggiornamento utente " + err.message, 'danger');
      })
    },
  }
}

class EditUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uId: '',
      dob: '',
      address: '',
      showPassword: false,
      loader: false
    }
    this.onSubmitting = this.onSubmitting.bind(this);
    this.handleDateClick = this.handleDateClick.bind(this);
  }
  componentDidMount() {
    if (this.props.single) {
      var singleData = this.props.single;
      this.props.autofill('id', singleData.ID);
      this.props.autofill('fname', singleData.FIRST_NAME);
      this.props.autofill('lname', singleData.LAST_NAME);
      this.props.autofill('phone', singleData.MOBILE_NO);
      this.props.autofill('regEmail', singleData.EMAIL_ID);
      this.setState({ dob: singleData.DOB });
      this.setState({ address: singleData.ADDRESS });
    }
  }

  onSubmitting = (values) => {
    this.setState({ loader: true });
    let that = this;
    var reg_form = { ID: values.id, FIRST_NAME: values.fname, LAST_NAME: values.lname, MOBILE_NO: values.phone, ADDRESS: values.address ? values.address : "", EMAIL_ID: values.regEmail, PASSWORD: values.regPassword }
    if (this.props.app.token) {
      this.props.onUserUpdate(this.props.app.token, reg_form).then(function () {
        that.props.onClose();
      });
    } else {
      const token = getSessionItem().token;
      this.props.onUserUpdate(token, reg_form).then(function () {
        that.setState({ loader: false });
        that.props.onClose();
      });
    }
  }

  handleDateClick(e) {
    let datestring = setCalendarDate(new Date(e));
    this.setState({ dob: datestring });
  }

  handleTextArea = (e) => {
    let add = e.target.value;
    this.setState({ address: add });
  }

  render() {
    const { handleSubmit, submitting, error } = this.props;

    let Dob = ''
    if (this.props.single) {
      Dob = this.props.single.DOB;
    }

    return (
      <main>
        <section className="d-flex align-items-center">
          <Container>
            <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h3 className="mb-0">Modifica Utente</h3>
                  </div>
                  {!this.state.loader ?
                    <Form className="mt-4" onSubmit={handleSubmit(this.onSubmitting)}>
                      {error && <span className='alert-danger'>{error}</span>}
                      <Form.Group id="fname" className="mb-4">
                        <Field name="id" component={renderEditField} type="hidden" />
                        <Form.Label>Nome</Form.Label>
                        <Field name="fname" component={renderEditField} type="text" label="Nome" validate={[required]} />
                      </Form.Group>
                      <Form.Group id="lname" className="mb-4">
                        <Form.Label>Cognome</Form.Label>
                        <Field name="lname" component={renderEditField} type="text" label="Cognome" validate={[required]} />
                      </Form.Group>
                      <Form.Group id="regEmail" className="mb-4">
                        <Form.Label>La Tua Mail</Form.Label>
                        <Field name="regEmail" component={renderEditField} label="La Tua Mail" validate={[email, required]} type="email" />
                      </Form.Group>
                      <Form.Group id="phone" className="mb-4">
                        <Form.Label>Numero di Cellulare</Form.Label>
                        <Field name="phone" component={renderEditField} type="text" label="Numero di Cellulare" validate={[required]} />
                      </Form.Group>
                      <Form.Group id="address" className="mb-4">
                        <Form.Label>Indirizzo</Form.Label>
                        <Field name="address" component={textAreaField} label="Indirizzo" dataValue={this.state.address} onChange={(e) => this.handleTextArea(e)} />
                      </Form.Group>
                      <div className="form-check form-switch mb-4">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" onChange={() => this.setState({ showPassword: !this.state.showPassword })} />
                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Do You want to Update Password?</label>
                      </div>
                      <Form.Group id="regPassword" className={this.state.showPassword ? "mb-4" : "d-none"}>
                        <Form.Label>Password</Form.Label>
                        <Field name={this.state.showPassword ? "regPassword" : ''} type="password" component={renderEditField} label="Password" />
                      </Form.Group>

                      <Button variant="primary" disabled={submitting} type="submit" className="w-100">
                        Applica
                      </Button>
                    </Form> : <div className="d-flex align-items-center justify-content-center" style={{ height: '100vh' }}><Loader type="bubble-scale" bgColor={"#BE1622"} color={'#BE1622'} size={100} /></div>}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    );
  }
};

EditUser = connect(mapStateToProps, mapDispatchToProps)(EditUser);

export default reduxForm({
  form: 'update user'
})(EditUser);
