
import React from "react";
import { Form, Button, Image } from '@themesberg/react-bootstrap';
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { uploadAdminImage, uploadProfileImage } from "../../services/services";
import { Store } from "react-notifications-component";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import { PROFILE_IMAGE } from '../../constants/actions'
import { getSessionItem, updateProfilePic } from "../../helpers/sessionHelper";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileEncode, FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

const mapStateToProps = state => {
    const profile = getSessionItem().profile;
    return {
        ...state.login,
        app: state.app,
        profile: profile
    }

}

const mapDispatchToProps = dispatch => {
    return {
        onFormSubmit: (token, payload) => {
            uploadProfileImage(token, payload).then(result => {
                if (result.status) {
                    var payload = result.data;
                    dispatch({ type: PROFILE_IMAGE, payload });
                    updateProfilePic(result.data.data.path.IMAGE_NAME);
                    Store.addNotification({
                        message: "il file è stato caricato con successo",
                        type: 'success',
                        container: 'top-right',
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 3000
                        }
                    })
                }
            }).catch(function (err) {
                Store.addNotification({
                    message: err.message,
                    type: 'success',
                    container: 'top-right',
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 3000
                    }
                })
            })
        },

        onAdminFormSubmit: (token, payload) => {
            uploadAdminImage(token, payload).then(result => {
                if (result.status) {
                    var payload = result.data;
                    updateProfilePic(result.data.data.path.IMAGE_NAME);
                    dispatch({ type: PROFILE_IMAGE, payload });
                    Store.addNotification({
                        message: "il file è stato caricato con successo",
                        type: 'success',
                        container: 'top-right',
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 3000
                        }
                    })
                }
            }).catch(function (err) {
                Store.addNotification({
                    message: err.message,
                    type: 'success',
                    container: 'top-right',
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 3000
                    }
                })
            })
        }
    }
}

class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imageFile: [],
            file: ''
        }
        this.onSubmitting = this.onSubmitting.bind(this);
    }

    onSubmitting = (values) => {
        let form64 = '';
        if (this.pond.getFiles().length > 0) {
            form64 = this.pond.getFiles()[0].getFileEncodeBase64String();
        }
        if (this.props.admin) {
            this.props.onAdminFormSubmit(this.props.app.token, { new_image: form64 });
        } else {
            this.props.onFormSubmit(this.props.app.token, { new_image: form64 });
        }
    }

    render() {
        const { handleSubmit, submitting, error } = this.props;
        const pond = {
            multiple: false,
            name: 'image',
            allowMultiple: false,
            instantUpload: false,
            fetch: null,
            revert: null,
            allowFileEncode: true,
            allowFileSizeValidation: true,
            maxFileSize: 1024000,
            labelMaxFileSizeExceeded: 'File is too large'
        };
        return (
            <div className="p-4 pb-5 shadow rounded mb-5" >
                <h3 className="mb-4 text-center">Immagine del Profilo</h3>
                <div className="user-avatar xl-avatar">
                    {/* <Image fluid rounded src={process.env.REACT_APP_API_URL+pic} /> */}
                    <img src={`${process.env.REACT_APP_API_URL + this.props.profile}`} className="rounded-circle" alt="profile-image" />
                </div>

                <Form className="mt-4" onSubmit={handleSubmit(this.onSubmitting)}>
                    {error && <span className='alert-danger'>{error}</span>}
                    <Form.Group id="i_image" className="mb-4">
                        <Form.Label>Carica immagine del profilo</Form.Label>
                        <FilePond  {...pond} ref={ref => (this.pond = ref)} files={[{ source: this.props.profile ? process.env.REACT_APP_API_URL + this.props.profile : '/avtar.png' }]} acceptedFileTypes={['image/*']} />
                    </Form.Group>
                    <Button variant="primary" disabled={submitting} type="submit" className="float-end">
                        Carica immagine
                    </Button>
                </Form>
            </div>
        );
    }
};

ResetPassword = connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
export default reduxForm({
    form: 'upload-image'
})(ResetPassword);


