import { ITEMS_LIST, SINGLE_ITEM, UPDATED_ITEM_DATA, CATEGORY_ITEMS, RESTAURANT_ITEMS} from '../constants/actions';

export default (state = { openAuthLogin: false }, action = {}) => {
    switch (action.type) {
        case ITEMS_LIST:{
            return{
                ...state,
                item: action.payload.data
            }
        }
        case SINGLE_ITEM:{
            return{
                ...state,
                itemdata: action.payload.data
            }
        }
        case UPDATED_ITEM_DATA:{
            return{
                ...state,
                editeditem: action.payload.data
            }
        }
        case RESTAURANT_ITEMS:{
            return{
                ...state,
                restitems: action.payload.data
            }
        }
        case CATEGORY_ITEMS:{
            return{
                ...state,
                catitems: action.payload.data
            }
        }
        default:
            {
                return {
                    ...state,
                };
            }
    }
}