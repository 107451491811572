import React from "react";
import { Col, Row, Form, Button, Container } from '@themesberg/react-bootstrap';
import BgImage from "../../assets/img/illustrations/signin.svg";
import { connect } from "react-redux";
import { createRestaurant, getRestaurantList, getAllUsers } from "../../services/services";
import { RESTAURENT_LIST, USER_LIST } from "../../constants/actions";
import { reduxForm, Field } from "redux-form";
import { renderField, required, textAreaField, notification } from "../../services/utileServices";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import Loader from "react-js-loader";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileEncode, FilePondPluginFileValidateType, FilePondPluginFileValidateSize);



const mapStateToProps = state => {
  return {
    ...state,
    app: state.app,
    user: state.user,
    login: state.login,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    async onCreateRestaurant(token, payload) {
      await createRestaurant(token, payload).then(result => {
        if (result.data.success) {
          notification('Ristorante creato con successo', 'success');
          getRestaurantList(token).then(result => {
            if (result.data.success) {
              var payload = result.data;
              dispatch({ type: RESTAURENT_LIST, payload });
              notification('Recupero dati ristorante riuscito', 'success');
            }
          }).catch(function (err) {
            notification('Errore nel recupero dei dati del ristorante ' + err.message, 'danger');
          })
        }
      }).catch(function (err) {
        notification('Errore in Crea ristorante ' + err.message, 'danger');
      })
    },
    onGetAllUser: () => {
      getAllUsers().then(result => {
        if (result.data.success) {
          console.log("fetch user data Successful");
          var payload = result.data;
          dispatch({ type: USER_LIST, payload });
        }
      }).catch(function (err) {
        notification('Errore in Ottieni dati utente ' + err.message, 'danger');
      })
    }
  }
}

class CreateRestaurant extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false
    }
    this.onSubmitting = this.onSubmitting.bind(this);
  }

  componentDidMount() {
    this.props.onGetAllUser();
  }

  async onSubmitting(values) {
    this.setState({ loader: true });
    let Image64 = '';
    let logo64 = '';
    if (this.pond.getFiles().length > 0) {
      Image64 = this.pond.getFiles()[0].getFileEncodeBase64String();
    }
    if (this.pondlogo.getFiles().length > 0) {
      logo64 = this.pondlogo.getFiles()[0].getFileEncodeBase64String();
    }

    let reg_form = {
      NAME: values.rnameen, ID: values.owner_id, RESTAURANT_TYPE: values.rtypeen, SLUG: values.rslug, IMG_SPLASH_SCREEN: Image64, NOTES: values.rnotesen, IMG_LOGO: logo64,
      NAME_FRENCH: values.rnamefr, NAME_ITALIAN: values.rnameit, NAME_GERMEN: values.rnamede, NAME_SPANISH: values.rnamees, RESTAURANT_TYPE_FR: values.rtypefr, RESTAURANT_TYPE_IT: values.rtypeit, RESTAURANT_TYPE_SP: values.rtypees
      , RESTAURANT_TYPE_GR: values.rtypede, NOTES_FR: values.rnotesfr, NOTES_GR: values.rnotesde, NOTES_SP: values.rnoteses, NOTES_IT: values.rnotesit, TEXT_LONG_EN: values.ldescen, TEXT_LONG_ES: values.ldesces, TEXT_LONG_FR: values.ldescfr,
      TEXT_LONG_DE: values.ldescde, TEXT_LONG_IT: values.ldescit, TEXT_SHORT_EN: values.sdescen, TEXT_SHORT_ES: values.sdesces, TEXT_SHORT_FR: values.sdescfr, TEXT_SHORT_DE: values.sdescde, TEXT_SHORT_IT: values.sdescit, MOBILE_NUMBER: values.phone
    }

    var that = this;
    const CreateResto = async (data) => {
      await this.props.onCreateRestaurant(this.props.app.token, data).then(function () {
        that.setState({ loader: false });
        that.props.onClose();
      });
    }

    if (Image64 != '' && Image64 != undefined && logo64 != '' && logo64 != undefined) {
      CreateResto(reg_form);
    } else {
      that.setState({ loader: false });
      notification('Si prega di caricare entrambe le immagini', 'danger');
    }

  }

  render() {
    const { handleSubmit, submitting, error } = this.props;
    let options = [];
    if (this.props && this.props.user.user) {
      const users = this.props.user.user;
      options = users.map((single, key) => (
        <option value={single.ID} key={key}>{single.FIRST_NAME + " " + single.LAST_NAME}</option>
      ))
    }

    const pond = {
      multiple: false,
      name: 'image',
      allowMultiple: false,
      instantUpload: false,
      fetch: null,
      revert: null,
      allowFileEncode: true,
      allowFileSizeValidation: true,
      maxFileSize: 1024000,
      labelMaxFileSizeExceeded: 'File is too large',
      labelIdle: "Trascina e rilascia qui  i tuoi file o Sfoglia"
    };

    const pondlogo = {
      multiple: false,
      name: 'logo',
      allowMultiple: false,
      instantUpload: false,
      fetch: null,
      revert: null,
      allowFileEncode: true,
      allowFileSizeValidation: true,
      maxFileSize: 1024000,
      labelMaxFileSizeExceeded: 'File is too large',
      labelIdle: "Trascina e rilascia qui  i tuoi file o Sfoglia"
    };

    return (
      <main>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h3 className="mb-0">Crea Ristorante</h3>
                  </div>
                  {
                  // this.props.login.admin ?
                    !this.state.loader ?
                      <Form className="mt-4" onSubmit={handleSubmit(this.onSubmitting)}>
                        {error && <span className='alert-danger'>{error}</span>}
                        <Form.Group id="rname" className="mb-4">
                          <Form.Label>Nome Ristorante (it)</Form.Label>
                          <Field name="rnameit" component={renderField} type="text" label="Nome Ristorante" validate={[required]} />
                        </Form.Group>
                        <Form.Group id="rname" className="mb-4">
                          <Form.Label>Nome Ristorante (en)</Form.Label>
                          <Field name="rnameen" component={renderField} type="text" label="Nome Ristorante" validate={[required]} />
                        </Form.Group>
                        <Form.Group id="rname" className="mb-4">
                          <Form.Label>Nome Ristorante (fr)</Form.Label>
                          <Field name="rnamefr" component={renderField} type="text" label="Nome Ristorante" validate={[required]} />
                        </Form.Group>
                        <Form.Group id="rname" className="mb-4">
                          <Form.Label>Nome Ristorante (de)</Form.Label>
                          <Field name="rnamede" component={renderField} type="text" label="Nome Ristorante" validate={[required]} />
                        </Form.Group>
                        <Form.Group id="rname" className="mb-4">
                          <Form.Label>Nome Ristorante (es)</Form.Label>
                          <Field name="rnamees" component={renderField} type="text" label="Nome Ristorante" validate={[required]} />
                        </Form.Group>
                        <Form.Group id="rtype" className="mb-4">
                          <Form.Label>TIPO DI RISTORANTE (it)</Form.Label>
                          <Field name="rtypeit" component={renderField} type="text" label="TIPO DI RISTORANTE" validate={[required]} />
                        </Form.Group>
                        <Form.Group id="rtype" className="mb-4">
                          <Form.Label>TIPO DI RISTORANTE (en)</Form.Label>
                          <Field name="rtypeen" component={renderField} type="text" label="TIPO DI RISTORANTE" validate={[required]} />
                        </Form.Group>
                        <Form.Group id="rtype" className="mb-4">
                          <Form.Label>TIPO DI RISTORANTE (fr)</Form.Label>
                          <Field name="rtypefr" component={renderField} type="text" label="TIPO DI RISTORANTE" validate={[required]} />
                        </Form.Group>
                        <Form.Group id="rtype" className="mb-4">
                          <Form.Label>TIPO DI RISTORANTE (de)</Form.Label>
                          <Field name="rtypede" component={renderField} type="text" label="TIPO DI RISTORANTE" validate={[required]} />
                        </Form.Group>
                        <Form.Group id="rtype" className="mb-4">
                          <Form.Label>TIPO DI RISTORANTE (es)</Form.Label>
                          <Field name="rtypees" component={renderField} type="text" label="TIPO DI RISTORANTE" validate={[required]} />
                        </Form.Group>
                        <Form.Group id="user" className={this.props.login.admin?"mb-4":'d-none'}>
                          <Form.Label>Seleziona Proprietario</Form.Label>
                          <Field className="form-control"
                            name="owner_id"
                            type="select"
                            component="select"
                            onChange={() => this.handleSelect}
                            required>
                            <option value="">Seleziona Proprietario</option>
                            {
                              options
                            }
                          </Field>
                        </Form.Group>
                        <Form.Group id="rslug" className="mb-4">
                          <Form.Label>percorso Url</Form.Label>
                          <Field name="rslug" component={renderField} label="percorso Url" validate={[required]} type="text" />
                        </Form.Group>
                        
                        <Form.Group id="rnotes" className="mb-4">
                          <Form.Label>Note (it)</Form.Label>
                          <Field name="rnotesit" type="textarea" component={textAreaField} validate={[required]} label="Inserisci informazioni  sul tuo ristorante" />
                        </Form.Group>
                        <Form.Group id="rnotes" className="mb-4">
                          <Form.Label>Note (en)</Form.Label>
                          <Field name="rnotesen" type="textarea" component={textAreaField} validate={[required]} label="Inserisci informazioni  sul tuo ristorante" />
                        </Form.Group>
                        <Form.Group id="rnotes" className="mb-4">
                          <Form.Label>Note (fr)</Form.Label>
                          <Field name="rnotesfr" type="textarea" component={textAreaField} validate={[required]} label="Inserisci informazioni  sul tuo ristorante" />
                        </Form.Group>
                        <Form.Group id="rnotes" className="mb-4">
                          <Form.Label>Note (de)</Form.Label>
                          <Field name="rnotesde" type="textarea" component={textAreaField} validate={[required]} label="Inserisci informazioni  sul tuo ristorante" />
                        </Form.Group>
                        <Form.Group id="rnotes" className="mb-4">
                          <Form.Label>Note (es)</Form.Label>
                          <Field name="rnoteses" type="textarea" component={textAreaField} validate={[required]} label="Inserisci informazioni  sul tuo ristorante" />
                        </Form.Group>
                        <Form.Group id="ldesc" className="mb-4">
                          <Form.Label>Descrizione lunga (it)</Form.Label>
                          <Field name="ldescit" type="textarea" component={textAreaField} validate={[required]} label="Scrivi una descrizione qui" />
                        </Form.Group>
                        <Form.Group id="ldesc" className="mb-4">
                          <Form.Label>Descrizione lunga (en)</Form.Label>
                          <Field name="ldescen" type="textarea" component={textAreaField} validate={[required]} label="Scrivi una descrizione qui" />
                        </Form.Group>
                        <Form.Group id="ldesc" className="mb-4">
                          <Form.Label>Descrizione lunga (fr)</Form.Label>
                          <Field name="ldescfr" type="textarea" component={textAreaField} validate={[required]} label="Scrivi una descrizione qui" />
                        </Form.Group>
                        <Form.Group id="ldesc" className="mb-4">
                          <Form.Label>Descrizione lunga (de)</Form.Label>
                          <Field name="ldescde" type="textarea" component={textAreaField} validate={[required]} label="Scrivi una descrizione qui" />
                        </Form.Group>
                        <Form.Group id="ldesc" className="mb-4">
                          <Form.Label>Descrizione lunga (es)</Form.Label>
                          <Field name="ldesces" type="textarea" component={textAreaField} validate={[required]} label="Scrivi una descrizione qui" />
                        </Form.Group>
                        <Form.Group id="sdesc" className="mb-4">
                          <Form.Label>Piccola descrizione (it)</Form.Label>
                          <Field name="sdescit" type="text" component={renderField} validate={[required]} label="Scrivi una descrizione qui" />
                        </Form.Group>
                        <Form.Group id="sdesc" className="mb-4">
                          <Form.Label>Piccola descrizione (en)</Form.Label>
                          <Field name="sdescen" type="text" component={renderField} validate={[required]} label="Scrivi una descrizione qui" />
                        </Form.Group>
                        <Form.Group id="sdesc" className="mb-4">
                          <Form.Label>Piccola descrizione (fr)</Form.Label>
                          <Field name="sdescfr" type="text" component={renderField} validate={[required]} label="Scrivi una descrizione qui" />
                        </Form.Group>
                        <Form.Group id="sdesc" className="mb-4">
                          <Form.Label>Piccola descrizione (de)</Form.Label>
                          <Field name="sdescde" type="text" component={renderField} validate={[required]} label="Scrivi una descrizione qui" />
                        </Form.Group>
                        <Form.Group id="sdesc" className="mb-4">
                          <Form.Label>Piccola descrizione (es)</Form.Label>
                          <Field name="sdesces" type="text" component={renderField} validate={[required]} label="Scrivi una descrizione qui" />
                        </Form.Group>
                        <Form.Group id="sdesc" className="mb-4">
                          <Form.Label>Numero di telefono</Form.Label>
                          <Field name="phone" type="tel" component={renderField} validate={[required]} label="Inserisci il tuo numero di telefono" />
                        </Form.Group>
                        <Form.Group id="rsplash" className="mb-4">
                          <Form.Label>Carica immagine</Form.Label>
                          <FilePond  {...pond} ref={ref => (this.pond = ref)} acceptedFileTypes={['image/*']} />
                        </Form.Group>
                        <Form.Group id="rlogo" className="mb-4">
                          <Form.Label>Carica il tuo Logo</Form.Label>
                          <FilePond  {...pondlogo} ref={ref => (this.pondlogo = ref)} acceptedFileTypes={['image/*']} />
                        </Form.Group>
                        <Button variant="primary" disabled={submitting} type="submit" className="w-100">
                          Crea
                        </Button>
                      </Form> 
                      : 
                      <div className="d-flex align-items-center justify-content-center" style={{ height: '100vh' }}><Loader type="bubble-scale" bgColor={"#BE1622"} color={'#BE1622'} size={100} /></div>
                    }

                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    )}}

CreateRestaurant = connect(mapStateToProps, mapDispatchToProps)(CreateRestaurant);

export default reduxForm({
  form: 'create restaurant form'
})(CreateRestaurant);
