import { APP_LOGIN_AUTH, ADMIN_LIST, ADMIN_DATA_BY_ID, UPDATED_ADMIN_DATA} from '../constants/actions';

export default (state = { openAuthLogin: false }, action = {}) => {
    switch (action.type) {
        case APP_LOGIN_AUTH:
            {
                return {
                    ...state,
                    isLoading: false,
                    openAuthLogin: true,
                    Authenticator: action.payload.Authenticator,
                    msg: action.payload.msg
                };
            }
        case ADMIN_LIST:{
            return{
                ...state,
                adminlist: action.payload.data
            }
        }
        case ADMIN_DATA_BY_ID:{
            return{
                ...state,
                admindata: action.payload.data
            }
        }
        case UPDATED_ADMIN_DATA:{
            return{
                ...state,
                editedadmin: action.payload.data
            }
        }
        
        default:
            {
                return {
                    ...state,
                };
            }
    }
}