
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Button, Container, InputGroup } from '@themesberg/react-bootstrap';
import BgImage from "../../assets/img/illustrations/signin.svg";
import { connect } from "react-redux";
import { createUser, getAllUsers } from "../../services/services";
import { USER_LIST, DOB_CHANGE } from "../../constants/actions";
import { reduxForm, Field } from "redux-form";
import { renderField, email, textAreaField, required, dobCheck, renderDatePickerField, getCalendarMinDate, setCalendarDate, notification } from "../../services/utileServices";
import Loader from "react-js-loader";

const mapStateToProps = state => {
  return {
    ...state,
    app: state.app
  }
}

const mapDispatchToProps = dispatch => {
  return {
    async onUserRegistration(token, payload) {
      await createUser(token, payload).then(result => {
        if (result.data.success) {
          notification('Utente creato con successo', 'success');
          getAllUsers().then(result => {
            if (result.data.success) {
              var payload = result.data;
              dispatch({ type: USER_LIST, payload });
              notification('Recupero dati utente riuscito', 'success');
            }
          }).catch(function (err) {
            notification('Errore durante il recupero dei dati utente ' + err.message, 'danger');
          })
        }
      }).catch(function (err) {
        notification('Errore in Crea utente ' + err.message, 'danger');
      })
    },
    onDateChange: (payload) => {
      dispatch({ type: DOB_CHANGE, payload });
    },
  }
}

class CreateUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dob: '',
      loader: false
    }
    this.onSubmitting = this.onSubmitting.bind(this);
    this.handleDateClick = this.handleDateClick.bind(this);
  }

  onSubmitting = (values) => {
    this.setState({ loader: true });
    let that = this;
    var reg_form = { FIRST_NAME: values.fname, LAST_NAME: values.lname, EMAIL_ID: values.regEmail, PASSWORD: values.regPassword, CONFIRM_PASSWORD: values.regConfirmPassword, MOBILE_NO: values.phone, ADDRESS: values.address }
    if (reg_form.PASSWORD === reg_form.CONFIRM_PASSWORD) {
      this.props.onUserRegistration(this.props.app.token, reg_form).then(function () {
        that.setState({ loader: false });
        that.props.onClose();
      });
    } else {
      console.log('Password Does not match');
    }
  }

  handleDateClick = (e) => {
    var datestring = setCalendarDate(new Date(e));
    this.setState({ dob: datestring });
    var payload = e ? datestring : getCalendarMinDate();
    this.props.onDateChange(payload);
  }

  render() {
    const { handleSubmit, submitting, error } = this.props;
    return (
      <main>
        <section className="d-flex align-items-center">
          <Container>
            <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h3 className="mb-0">Crea Utente</h3>
                  </div>
                  {!this.state.loader ?
                    <Form className="mt-4" onSubmit={handleSubmit(this.onSubmitting)}>
                      {error && <span className='alert-danger'>{error}</span>}
                      <Form.Group id="fname" className="mb-4">
                        <Form.Label>Nome</Form.Label>
                        <Field name="fname" component={renderField} type="text" label="Nome" validate={[required]} />
                      </Form.Group>
                      <Form.Group id="lname" className="mb-4">
                        <Form.Label>Cognome</Form.Label>
                        <Field name="lname" component={renderField} type="text" label="Cognome" validate={[required]} />
                      </Form.Group>
                      <Form.Group id="regEmail" className="mb-4">
                        <Form.Label>La Tua Mail</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faEnvelope} />
                          </InputGroup.Text>
                          <Field name="regEmail" component={renderField} label="La Tua Mail" validate={[required, email]} type="email" />
                        </InputGroup>
                      </Form.Group>

                      <Form.Group id="phone" className="mb-4">
                        <Form.Label>Numero di Cellulare</Form.Label>
                        <Field name="phone" component={renderField} type="text" label="Numero di Cellulare" validate={[required]} />
                      </Form.Group>
                      <Form.Group id="address" className="mb-4">
                        <Form.Label>Indirizzo</Form.Label>
                        <Field name="address" component={textAreaField} type="textarea" label="Indirizzo" />
                      </Form.Group>
                      <Form.Group id="regPassword" className="mb-4">
                        <Form.Label>Password</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUnlockAlt} />
                          </InputGroup.Text>
                          <Field name="regPassword" type="password" component={renderField} validate={[required]} label="Inserisci Password" />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group id="regConfirmPassword" className="mb-4">
                        <Form.Label>Reinserisci Password</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUnlockAlt} />
                          </InputGroup.Text>
                          <Field name="regConfirmPassword" type="password" component={renderField} validate={[required]} label="Reinserisci Password" />
                        </InputGroup>
                      </Form.Group>

                      <Button variant="primary" disabled={submitting} type="submit" className="w-100">
                        Crea
                      </Button>
                    </Form> : <div className="d-flex align-items-center justify-content-center" style={{ height: '100vh' }}><Loader type="bubble-scale" bgColor={"#BE1622"} color={'#BE1622'} size={100} /></div>}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    );
  }
};

CreateUser = connect(mapStateToProps, mapDispatchToProps)(CreateUser);

export default reduxForm({
  form: 'user registration form'
})(CreateUser);
