
import React from "react";
import { Col, Row, Form, Button, Container } from '@themesberg/react-bootstrap';
import BgImage from "../../assets/img/illustrations/signin.svg";
import { connect } from "react-redux";
import { getItems, updateItem } from "../../services/services";
import { ITEMS_LIST } from "../../constants/actions";
import { reduxForm, Field } from "redux-form";
import { renderField, textAreaField, notification, required } from "../../services/utileServices";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import Loader from "react-js-loader";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileEncode, FilePondPluginFileValidateType, FilePondPluginFileValidateSize);


const mapStateToProps = state => {
  return {
    ...state.item,
    app: state.app,
    rest: state.restaurant,

  }
}

const mapDispatchToProps = dispatch => {
  return {
    async onUpdateItem(token, payload) {
      await updateItem(token, payload, payload.ID).then(result => {
        if (result.data.success) {
          notification('Articolo aggiornato con successo', 'success');
          getItems(token).then(result => {
            if (result.data.success) {
              var payload = result.data;
              dispatch({ type: ITEMS_LIST, payload });
              console.log('fetch successfully');
            }
          }).catch(function (err) {
            notification("Errore durante il recupero dei dati dell'articolo " + err.message, 'danger');
          })
        }
      }).catch(function (err) {
        notification("Errore nell'aggiornamento dell'articolo " + err.message, 'danger');
      })
    }
  }
}

class EditItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageFile: '',
      desc: {},
      loader: false
    }
    this.onSubmitting = this.onSubmitting.bind(this);
    this.handleTextArea = this.handleTextArea.bind(this);
  }

  async onSubmitting(values) {
    this.setState({ loader: true });
    let form64 = '';
    if (this.pond.getFiles().length > 0) {
      form64 = this.pond.getFiles()[0].getFileEncodeBase64String();
    }

    // let item_name = await Translation(values.iname);
    // let item_desc = await Translation(values.idesc);

    var reg_form = {
      ITEM_ID: values.id, ITEM_NAME: values.inameen, PRICE: values.iprice, DESCRIPTION: (values.idescen != '')?values.idescen:this.state.desc.en, IMG_ITEM: form64, AVAILABLE: values.iavail, POSITION_MENU: values.ipos,
      ITEM_NAME_FRENCH: values.inamefr, ITEM_NAME_ITALIAN: values.inameit, ITEM_NAME_SPANISH: values.inamees, ITEM_NAME_GERMEN: values.inamede, DESCRIPTION_FRENCH: (values.idescfr != '')?values.idescfr:this.state.desc.fr, DESCRIPTION_ITALIAN: (values.idescit != '')?values.idescit:this.state.desc.it, DESCRIPTION_SPANISH: (values.idesces != '')?values.idesces:this.state.desc.es, DESCRIPTION_GERMEN: (values.idescde != '')?values.idescde:this.state.desc.de
    }

    let that = this;
    const UpdateItem = async (data) => {
      await this.props.onUpdateItem(this.props.app.token, data).then(function () {
        that.setState({ loader: false });
        that.props.onClose();
      });
    }

    UpdateItem(reg_form);
    // var reg_form = { ITEM_ID: values.id, ITEM_NAME: values.iname, PRICE: values.iprice, DESCRIPTION: values.idesc, IMG_ITEM: form64, AVAILABLE: values.iavail, POSITION_MENU: values.ipos }

  }

  componentDidMount() {
    if (this.props.itemdata) {
      var singleData = this.props.itemdata[0];
      this.props.autofill('id', singleData.ITEM_ID);
      this.props.autofill('inameen', singleData.ITEM_NAME.en);
      this.props.autofill('inameit', singleData.ITEM_NAME.it);
      this.props.autofill('inamefr', singleData.ITEM_NAME.fr);
      this.props.autofill('inamede', singleData.ITEM_NAME.de);
      this.props.autofill('inamees', singleData.ITEM_NAME.es);
      this.props.autofill('iprice', singleData.PRICE);
      this.props.autofill('idesc', singleData.DESCRIPTION.es);
      this.props.autofill('iavail', singleData.AVAILABLE);
      this.props.autofill('ipos', singleData.POSITION_MENU);
      this.setState({ desc: singleData.DESCRIPTION});
    }
  }

  handleTextArea = (e) => {
    var description = e.target.value;
    this.setState({ desc: description });
  }

  render() {

    var singleItem = '';
    if (this.props.itemdata) {
      singleItem = this.props.itemdata;
    }
    const { handleSubmit, submitting, error } = this.props;

    const pond = {
      multiple: false,
      name: 'image',
      allowMultiple: false,
      instantUpload: false,
      fetch: null,
      revert: null,
      allowFileEncode: true,
      allowFileSizeValidation: true,
      maxFileSize: 1024000,
      labelMaxFileSizeExceeded: 'File is too large',
      labelIdle: "Trascina e rilascia qui  i tuoi file o Sfoglia"
    }

    return (
      <main>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h3 className="mb-0">Modifica Voce di Menu</h3>
                  </div>
                  {!this.state.loader ?
                    <Form className="mt-4" onSubmit={handleSubmit(this.onSubmitting)}>
                      {error && <span className='alert-danger'>{error}</span>}

                      <Field name="id" component={renderField} type="hidden" />
                      <Form.Group id="iname" className="mb-4">
                        <Form.Label>Voce di Menu (it)</Form.Label>
                        <Field name="inameit" component={renderField} type="text" label="Voce di Menu" validate={[required]} />
                      </Form.Group>
                      <Form.Group id="iname" className="mb-4">
                        <Form.Label>Voce di Menu (en)</Form.Label>
                        <Field name="inameen" component={renderField} type="text" label="Voce di Menu" validate={[required]} />
                      </Form.Group>
                      <Form.Group id="iname" className="mb-4">
                        <Form.Label>Voce di Menu (fr)</Form.Label>
                        <Field name="inamefr" component={renderField} type="text" label="Voce di Menu" validate={[required]} />
                      </Form.Group>
                      <Form.Group id="iname" className="mb-4">
                        <Form.Label>Voce di Menu (de)</Form.Label>
                        <Field name="inamede" component={renderField} type="text" label="Voce di Menu" validate={[required]} />
                      </Form.Group>
                      <Form.Group id="iname" className="mb-4">
                        <Form.Label>Voce di Menu (es)</Form.Label>
                        <Field name="inamees" component={renderField} type="text" label="Voce di Menu" validate={[required]} />
                      </Form.Group>
                      <Form.Group id="iprice" className="mb-4">
                        <Form.Label>Prezzo</Form.Label>
                        <Field name="iprice" component={renderField} type="number" label="Prezzo" validate={[required]} />
                      </Form.Group>
                      <Form.Group id="idesc" className="mb-4">
                        <Form.Label>Descrizione (it)</Form.Label>
                        <Field name="idescit" type="textarea" component={textAreaField} dataValue={this.state.desc.it} onChange={(e) => this.handleTextArea(e)} label="Scrivi una descrizione qui" />
                      </Form.Group>
                      <Form.Group id="idesc" className="mb-4">
                        <Form.Label>Descrizione (en)</Form.Label>
                        <Field name="idescen" type="textarea" component={textAreaField} dataValue={this.state.desc.en} onChange={(e) => this.handleTextArea(e)} label="Scrivi una descrizione qui" />
                      </Form.Group>
                      <Form.Group id="idesc" className="mb-4">
                        <Form.Label>Descrizione (fr)</Form.Label>
                        <Field name="idescfr" type="textarea" component={textAreaField} dataValue={this.state.desc.fr} onChange={(e) => this.handleTextArea(e)} label="Scrivi una descrizione qui" />
                      </Form.Group>
                      <Form.Group id="idesc" className="mb-4">
                        <Form.Label>Descrizione (de)</Form.Label>
                        <Field name="idescde" type="textarea" component={textAreaField} dataValue={this.state.desc.de} onChange={(e) => this.handleTextArea(e)} label="Scrivi una descrizione qui" />
                      </Form.Group>
                      <Form.Group id="idesc" className="mb-4">
                        <Form.Label>Descrizione (es)</Form.Label>
                        <Field name="idesces" type="textarea" component={textAreaField} dataValue={this.state.desc.es} onChange={(e) => this.handleTextArea(e)} label="Scrivi una descrizione qui" />
                      </Form.Group>
                      <Form.Group id="i_image" className="mb-4">
                        <Form.Label>Carica immagine</Form.Label>
                        <FilePond  {...pond} ref={ref => (this.pond = ref)} files={[{ source: singleItem[0].IMG_ITEM ? process.env.REACT_APP_API_URL + singleItem[0].IMG_ITEM : '/demo.png' }]} acceptedFileTypes={['image/*']} />
                      </Form.Group>
                      <Form.Group id="iavail" className="mb-4">
                        <Form.Label>Disponibilità prodotto</Form.Label>
                        <Field className="form-control"
                          name="iavail"
                          type="select"
                          component="select">
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </Field>
                      </Form.Group>
                      <Form.Group id="ipos" className="mb-4">
                        <Form.Label>Posizione</Form.Label>
                        <Field name="ipos" type="number" component={renderField} label="Posizione" validate={[required]} />
                      </Form.Group>

                      <Button variant="primary" disabled={submitting} type="submit" className="w-100">
                        Applica
                      </Button>
                    </Form> : <div className="d-flex align-items-center justify-content-center" style={{ height: '100vh' }}><Loader type="bubble-scale" bgColor={"#BE1622"} color={'#BE1622'} size={100} /></div>}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    );
  }
};

EditItem = connect(mapStateToProps, mapDispatchToProps)(EditItem);

export default reduxForm({
  form: 'update item form'
})(EditItem);
