import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";
import 'react-notifications-component/dist/theme.css';
import 'animate.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect } from 'react-redux';
import Dashboard from "./dashboard/Dashboard";
import Settings from "./Settings";
import PrivateRoute from '../components/PrivateRoute';

import Category from "./tables/Categories";
import UsersList from "./tables/UsersList";
import ItemsList from "./tables/ItemsList";
import Allergens from "./tables/Allergens";
// import RestaurantList from "./tables/RestaurantList";
import RestaurantList from './tables/RestaurantList';
import AdminList from './tables/AdminList';
import OrderDetails from './tables/OrderDetails';

import VerifyUser from './Validation/VerifyUser';
import Signin from "./Validation/Signin";
import Signup from "./Validation/Signup";
import ForgotPassword from "./Validation/ForgotPassword";
import ResetPassword from "./Validation/ResetPassword";
import Lock from "./Validation/Lock";
import NotFoundPage from "./Validation/NotFound";
import ServerError from "./Validation/ServerError";
import Projects from './tables/BootstrapTables';

const mapStateToProps = state => {

  return {
 ...state
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}
const RouteWithLoader = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={(props) => (<> <Component {...props} /> </>)} />
  );
};
class HomePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      componentReady: false
    }
  }
  render()  {
  return(
  <Switch>
    <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
    <RouteWithLoader exact path={Routes.VerifyUser.path} component={VerifyUser} />
    <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
    <RouteWithLoader exact path={Routes.ForgotPassword.path} component={ForgotPassword} />
    <RouteWithLoader exact path={Routes.ResetPassword.path} component={ResetPassword} />
    <RouteWithLoader exact path={Routes.Lock.path} component={Lock} />
    <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
    <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />

    <PrivateRoute exact path={Routes.RestaurantList.path} authed={this.props.data ? true : false} component={RestaurantList} />
    <PrivateRoute exact path={Routes.Dashboard.path} authed={this.props.data ? true : false} component={Dashboard} />
    <PrivateRoute exact path={Routes.Settings.path} authed={this.props.data ? true : false} component={Settings} />
    <PrivateRoute exact path={Routes.UsersList.path} authed={this.props.data ? true : false} component={UsersList} admin={this.props.admin} />
    <PrivateRoute exact path={Routes.Admin.path} authed={this.props.data ? true : false} component={AdminList} admin={this.props.admin} />
    <PrivateRoute exact path={Routes.Category.path} authed={this.props.data ? true : false} component={Category} />
    <PrivateRoute exact path={Routes.ItemsList.path} authed={this.props.data ? true : false} component={ItemsList} />
    <PrivateRoute exact path={Routes.OrderDetails.path} authed={this.props.data ? true : false} component={OrderDetails} />
    <PrivateRoute exact path={Routes.Allergens.path} authed={this.props.data ? true : false} component={Allergens} />
    <PrivateRoute exact path={Routes.BootstrapTables.path} authed={this.props.data ? true : false} component={Projects} />
    <Redirect to={Routes.NotFound.path} />
  </Switch>
  )
}
}
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
