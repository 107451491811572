
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Button, Container, InputGroup } from '@themesberg/react-bootstrap';
import { renderField, required } from "../../services/utileServices";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { updateAdminPassword, updateUserPassword } from "../../services/services";
import { Store } from "react-notifications-component";

const mapStateToProps = state => {
  return {
    ...state.app,
    login: state.login
  }

}

const mapDispatchToProps = dispatch => {
  return {
    async onFormSubmit(token, payload) {
      await updateAdminPassword(token, payload).then(result => {
        if (result.status) {
          Store.addNotification({
            message: "Password aggiornata correttamente",
            type: 'success',
            container: 'top-right',
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 3000
            }
          })
        }
      }).catch(function (err) {
        Store.addNotification({
          message: err.message,
          type: 'success',
          container: 'top-right',
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000
          }
        })
      })
    },
    async onUserFormSubmit(token, payload) {
      await updateUserPassword(token, payload).then(result => {
        if (result.status) {
          Store.addNotification({
            message: "Password aggiornata correttamente",
            type: 'success',
            container: 'top-right',
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 3000
            }
          })
        }
      }).catch(function (err) {
        Store.addNotification({
          message: err.message,
          type: 'success',
          container: 'top-right',
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000
          }
        })
      })
    }
  }
}


class ResetUserPassword extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmitting = this.onSubmitting.bind(this);
  }

  onSubmitting = (values) => {
    var reset_pass = { OLD_PASSWORD: values.old_password, NEW_PASSWORD: values.new_password, CONFIRM_PASSWORD: values.conf_password };
    var that = this;
    if (reset_pass.NEW_PASSWORD === reset_pass.CONFIRM_PASSWORD) {
      if (this.props.login.admin) {
        this.props.onFormSubmit(this.props.token, reset_pass).then(() => {
          that.props.onClose();
        })
      } else {
        this.props.onUserFormSubmit(this.props.token, reset_pass).then(() => {
          that.props.onClose();
        })
      }

    } else {
      console.log("password Does not match");
    }

  }

  render() {
    const { handleSubmit, submitting, error } = this.props;
    return (
      <main>
        <section className="">
          <Container>
            <Row className="justify-content-center">
              <Col>
                <div className="bg-white shadow-soft border rounded border-light mx-auto p-5" style={{ width: '80%', padding: '20px' }}>
                  <h3 className="mb-4">Resetta la password</h3>
                  <Form onSubmit={handleSubmit(this.onSubmitting)}>
                    {error && <span className='alert-danger'>{error}</span>}
                    <Form.Group id="email" className="mb-4">
                      <Form.Label>Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Field type="password" name="old_password" label="Password" validate={[required]} component={renderField} />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Inserisci Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Field type="password" name="new_password" label="Inserisci Password" validate={[required]} component={renderField} />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group id="confirmPassword" className="mb-4">
                      <Form.Label>Reinserisci Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Field type="password" name="conf_password" label="Reinserisci Password" validate={[required]} component={renderField} />
                      </InputGroup>
                    </Form.Group>
                    <Button variant="primary" disabled={submitting} type="submit" className="float-end">
                      Resetta la password
                    </Button>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    );
  }
};

ResetUserPassword = connect(mapStateToProps, mapDispatchToProps)(ResetUserPassword);
export default reduxForm({
  form: 'reset-user-password'
})(ResetUserPassword);
