
import React from "react";
import { Col, Row, Form, Button } from '@themesberg/react-bootstrap';
import { Field, reduxForm } from "redux-form";
import { renderField } from "../../services/utileServices";
import { connect } from "react-redux";
import { updateAdmin, updateUser } from "../../services/services";
import { Store } from "react-notifications-component";
import { getSessionItem, updateSessionUser } from "../../helpers/sessionHelper";

const mapStateToProps = state => {
    return {
      ...state,
      app: state.app
    }
  }

const mapDispatchToProps = dispatch => {
    return {
      async OnAdminUpdate(token, payload) {
        await updateAdmin(token, payload).then(result => {
          if (result.status) {
            Store.addNotification({
              message: "Amministratore aggiornato correttamente",
              type: 'success',
              container: 'top-right',
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 3000
              }
            })
          }
        }).catch(function (err) {
          Store.addNotification({
            message: err.message,
            type: 'warning',
            container: 'top-right',
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 3000
            }
          })
        })
      },
      async onUserUpdate(token, payload) {
        await updateUser(token, payload).then(result => {
          if (result.status) {
            Store.addNotification({
              message: "Utente aggiornato correttamente",
              type: 'success',
              container: 'top-right',
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 3000
              }
            })
          }
        }).catch(function (err) {
          Store.addNotification({
            message: err.message,
            type: 'warning',
            container: 'top-right',
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 3000
            }
          })
        })
      },
  
    }
  }

class AddressUpdate extends React.Component {

    constructor(props) {
        super(props);
        this.onSubmitting = this.onSubmitting.bind(this);
    }

    componentDidMount() {
        if (this.props.app.user != undefined) {
          var singleData = this.props.app.user;
          this.props.autofill('fname', singleData.FIRST_NAME);
          this.props.autofill('lname', singleData.LAST_NAME);
          this.props.autofill('id', singleData.ID);
        } else {
          const { token, user, fname, lname, id } = getSessionItem();
          this.props.autofill('fname', fname);
          this.props.autofill('lname', lname);
          this.props.autofill('id', id);
        }
      }

    onSubmitting = (values) => {
        var reg_form = { ID: values.id, FIRST_NAME: values.fname, LAST_NAME: values.lname }
        if (this.props.login.admin) {
          this.props.OnAdminUpdate(this.props.app.token, reg_form).then(() => {
            updateSessionUser(reg_form.FIRST_NAME, reg_form.LAST_NAME);
          });
        } else {
          this.props.onUserUpdate(this.props.app.token, reg_form).then(() => {
            updateSessionUser(reg_form.FIRST_NAME, reg_form.LAST_NAME);
          });;
        }
      }



    render() {
        const { handleSubmit, submitting, error } = this.props;
        return (
            <>
                <Row>
                    <Col>
                        <div style={{ padding: '20px' }}>
                            <div>
                                <div className="text-center text-md-center mb-4 mt-md-0">
                                    <h3 className="mb-0">Update Your Information</h3>
                                </div>
                                <Form className="mt-4" onSubmit={handleSubmit(this.onSubmitting)}>
                                    {error && <span className='alert-danger'>{error}</span>}
                                    <Form.Group id="fname" className="mb-4">
                                        <Field name="id" component={renderField} type="hidden" />
                                        <Form.Label>Select Country</Form.Label>
                                        <Field name="fname" component={renderField} type="text" label="Enter First Name" />
                                    </Form.Group>
                                    <Form.Group id="lname" className="mb-4">
                                        <Form.Label>Select State</Form.Label>
                                        <Field name="lname" component={renderField} type="text" label="Enter Last Name" />
                                    </Form.Group>
                                    <Form.Group id="dob" className="mb-4">
                                        <Form.Label>Select City</Form.Label>
                                        <Field name="dob" component={renderField} type="text" label="Enter Your Birth Date" />
                                    </Form.Group>
                                    <Form.Group id="phone" className="mb-4">
                                        <Form.Label>Zip code / Pin Code </Form.Label>
                                        <Field name="phone" component={renderField} type="text" label="Enter Your Mobile Number" />
                                    </Form.Group>
                                    <Form.Group id="phone" className="mb-4">
                                        <Form.Label>Street / Town / Village</Form.Label>
                                        <Field name="phone" component={renderField} type="text" label="Enter Your Mobile Number" />
                                    </Form.Group>

                                    <Button variant="primary" disabled={submitting} type="submit" className="float-end">
                                        Update Address
                                    </Button>
                                </Form>
                            </div>

                        </div>
                    </Col>
                </Row>
            </>
        )
    }
}

AddressUpdate = connect(mapStateToProps, mapDispatchToProps)(AddressUpdate);
export default reduxForm({
  form: 'address update form'
})(AddressUpdate);
