
import React from "react";
import { Table } from 'react-bootstrap';
import { connect } from "react-redux";
import { deleteAdmin, getAdminList, getAdminById } from "../../services/services";
import { ADMIN_DATA_BY_ID, ADMIN_LIST } from "../../constants/actions";
import BootstrapModals from "../components/BootstrapModals";
import CreateAdmin from "../form/CreateAdmin";
import EditAdmin from "../form/EditAdmin";
import { confirmAlert } from 'react-confirm-alert';
import { getSessionItem } from "../../helpers/sessionHelper";
import { notification } from "../../services/utileServices";

const mapStateToProps = state => {
    return {
        ...state.adminlist,
        app: state.app
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onGetAdminList: () => {
            getAdminList().then(result => {
                var payload = result.data;
                dispatch({ type: ADMIN_LIST, payload });
                if (result.status) {
                    notification("Tutti i dati dell'amministratore sono stati recuperati correttamente", 'success');
                }
            }).catch(function (err) {
                notification("Errore durante il recupero dei dati dell'amministratore " + err.message, 'danger');
            })
        },

        async onGetSingleAdmin(payload) {
            await getAdminById(payload).then(result => {
                if (result.status) {
                    notification("Recupero dei dati dell'amministratore singolo riuscito", 'success');
                    var payload = result.data;
                    dispatch({ type: ADMIN_DATA_BY_ID, payload });
                }
            }).catch(function (err) {
                notification("Errore durante il recupero dei dati dell'amministratore singolo " + err.message, 'danger');
            });
        },

        onDelete: (token, id) => {
            deleteAdmin(token, id).then(result => {
                if (result.status) {
                    notification("Amministratore eliminato con successo", 'success');

                    getAdminList().then(result => {
                        var payload = result.data;
                        dispatch({ type: ADMIN_LIST, payload });
                        if (result.status) {
                            notification("Tutti i dati dell'amministratore sono stati recuperati correttamente", 'success');
                        }
                    }).catch(function (err) {
                        notification("Errore nel recupero dei dati dell'amministratore " + err.message, 'danger');
                    });
                }
            }).catch(function (err) {
                notification("Errore durante il recupero dei dati dell'amministratore " + err.message, 'danger');
            });
        }

    }
};

class UsersList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            AddModalShow: false,
            EditModalShow: false,
        }

        this.handleDelete = this.handleDelete.bind(this);
        this.onGetAdminById = this.onGetAdminById.bind(this);
    }
    componentDidMount() {
        this.props.onGetAdminList();
    }

    onGetAdminById(update_id) {
        var that = this;
        this.props.onGetSingleAdmin(update_id).then(function () {
            that.setState({ EditModalShow: !that.state.EditModalShow });
        })
    }

    handleDelete(id) {
        confirmAlert({
            title: 'Conferma per eliminare',
            message: 'Sei sicuro di voler cancellare questo.',
            buttons: [
                {
                    label: 'sì',
                    onClick: () => this.props.onDelete(this.props.app.token, id)
                },
                {
                    label: 'No',
                    onClick: () => console.log("closed")
                }
            ]
        });
    }

    handleHide() {
        this.setState({ AddModalShow: !this.state.AddModalShow });
    }

    handleEditHide() {
        this.setState({ EditModalShow: !this.state.EditModalShow });
    }

    render() {
        const id = getSessionItem().id;
        return (
            <>
                <div className="w-full" style={{ minHeight: '100vh' }}>
                    <div className="px-3 py-4">
                        <div className="d-flex justify-content-between items-center">

                            <h4>elenco degli amministratori</h4>
                            <button type="button" className="btn btn-primary" onClick={() => this.setState({ AddModalShow: !this.state.AddModalShow })}>
                            Aggiungi Amministratore
                            </button>

                        </div>
                    </div>
                    <Table responsive className="table-centered table-nowrap rounded mb-0">
                        <thead className="thead-light">
                            <tr>
                                <th>SNo</th>
                                <th>Nome Utente</th>
                                <th>Data di nascita</th>
                                <th>Numero di Telefono</th>
                                <th>Indirizzo</th>
                                <th>Email</th>
                                <th>Azioni</th>
                            </tr>
                        </thead>
                        <tbody className="bg-light">
                            {
                                this.props.adminlist ? this.props.adminlist.map((adminList, key) =>
                                    (id != adminList.ID) ?
                                        <tr key={key}>
                                            <td>{key + 1}</td>
                                            <td>{adminList.FIRST_NAME + " " + adminList.LAST_NAME}</td>
                                            <td>{adminList.DOB}</td>
                                            <td>{adminList.MOBILE_NO}</td>
                                            <td>{adminList.ADDRESS}</td>
                                            <td>{adminList.EMAIL_ID}</td>
                                            <td>
                                                <a className="btn btn-success py-2 px-3 m-2" onClick={() => this.onGetAdminById(adminList.ID)}>Modifica</a>
                                                <a className="btn btn-danger py-2 px-3" onClick={() => this.handleDelete(adminList.ID)}>Elimina</a>
                                            </td>
                                        </tr>
                                        : null
                                ) : <tr><td colSpan={7} className="text-center">Nessun record disponibile</td></tr>
                            }
                        </tbody>
                    </Table>
                    <BootstrapModals component={<CreateAdmin onClose={() => this.handleHide()} />} heading="Aggiungi Amministratore" show={this.state.AddModalShow}
                        onHide={() => this.setState({ AddModalShow: !this.state.AddModalShow })} className="admin_create_modal" />
                    <BootstrapModals component={<EditAdmin edit={this.state.update_id} onClose={() => this.handleEditHide()} />} heading="Modifica Amministratore" show={this.state.EditModalShow}
                        onHide={() => this.setState({ EditModalShow: !this.state.EditModalShow })} className="admin_update_modal" />

                </div>

            </>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
