import { ALLERGEN_LIST, SINGLE_ALLERGEN, RESTAURANT_OF_ALLERGEN, ITEM_ALLERGEN, CLEAR_ITEM_ALLERGEN } from '../constants/actions';

export default (state = {}, action = {}) => {
    switch (action.type) {
        case ALLERGEN_LIST:{
            return{
                ...state,
                allergen: action.payload.data
            }
        }
        case SINGLE_ALLERGEN:{
            return{
                ...state,
                allergendata: action.payload.data
            }
        }
        case RESTAURANT_OF_ALLERGEN:{
            return{
                ...state,
                restallergen: action.payload.data
            }
        }
        case ITEM_ALLERGEN:{
            return{
                ...state,
                itemallergen: action.payload.data
            }
        }
        case CLEAR_ITEM_ALLERGEN:
            return{
                ...state,
                itemallergen: []
            }
            
        default:
            {
                return {
                    ...state,
                };
            }
    }
}