
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Button, Container, InputGroup } from '@themesberg/react-bootstrap';
import { renderField, required } from "../../services/utileServices";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { updateAdminPassword } from "../../services/services";
import { Store } from "react-notifications-component";

const mapStateToProps = state =>{
  return {
    ...state,
    app: state.app
  }

}

const mapDispatchToProps = dispatch =>{
  return {
    onFormSubmit: (token, payload) =>{
      updateAdminPassword(token, payload).then(result =>{
        if(result.status){
          Store.addNotification({
            message: "Password aggiornata correttamente",
            type: 'success',
            container: 'top-right',
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 3000
            }
          })
        }
      }).catch(function(err){
        Store.addNotification({
          message: err.message,
          type: 'success',
          container: 'top-right',
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000
          }
        })
      })
    }
  }
}


class ResetPassword extends React.Component {
  constructor(props){
    super(props);
    this.onSubmitting = this.onSubmitting.bind(this);
  }

  onSubmitting = (values) =>{
    var reset_pass = { OLD_PASSWORD: values.old_password, NEW_PASSWORD: values.new_password, CONFIRM_PASSWORD: values.conf_password };

    if(reset_pass.NEW_PASSWORD === reset_pass.CONFIRM_PASSWORD){
      this.props.onFormSubmit(this.props.app.token, reset_pass);
    }else{
      console.log("password Does not match");
    }

  }

  render(){
    const {handleSubmit, submitting, error} = this.props;
  return (
    <main>
      <section className="">
        <Container>
          <Row className="justify-content-center">
            <Col>
              <div className="bg-white shadow-soft border rounded border-light mx-auto p-5" style={{width: '80%', padding:'20px'}}>
                <h3 className="mb-4">Reset password</h3>
                <Form onSubmit={handleSubmit(this.onSubmitting)}>
                {error && <span className='alert-danger'>{error}</span>}
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Old Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                      <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Field type="password" name="old_password" label="Enter Your Existing Password" validate={[required]} component={renderField}  />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>New Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Field type="password" name="new_password" label="Enter New Password" validate={[required]} component={renderField}  />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="confirmPassword" className="mb-4">
                    <Form.Label>Confirm New Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Field type="password" name="conf_password" label="Confirm New Password" validate={[required]} component={renderField}  />
                    </InputGroup>
                  </Form.Group>
                  <Button variant="primary" disabled={submitting} type="submit" className="float-end">
                    Reset password
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
}
};

ResetPassword = connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
export default reduxForm({
  form: 'reset-password'
})(ResetPassword);
