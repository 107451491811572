
import React from "react";
import { Button, Table } from 'react-bootstrap';
import { connect } from "react-redux";
import { getItems, getRestAllergen, getRestaurantList, getItemById, deleteItem, getAllergenByItemId, getRestItem, getRestaurantCategories, getCatItem, checkToken, getAllCategories } from "../../services/services";
import { ITEMS_LIST, RESTAURENT_LIST, RESTAURANT_OF_ALLERGEN, CLEAR_ITEM_ALLERGEN, RESTAURANT_ITEMS, ITEM_ALLERGEN, CATEGORY_LIST, CATEGORY_ITEMS, SINGLE_ITEM, RESTAURANT_CATEGORY_LIST } from "../../constants/actions";
import BootstrapModals from "../components/BootstrapModals";
import CreateItem from "../form/CreateItem";
import EditItem from "../form/EditItem";
import { confirmAlert } from 'react-confirm-alert';
import { notification } from "../../services/utileServices";
import CreateAllergenMap from "../form/CreateAllergenMap";

const mapStateToProps = state => {
    return {
        ...state.item,
        app: state.app,
        restaurant: state.restaurant,
        category: state.category,
        login: state.login
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetItems: (token) => {
            getItems(token).then(result => {
                if (result.data.success) {
                    var payload = result.data;
                    dispatch({ type: ITEMS_LIST, payload });
                    notification("Tutti gli elenchi di elementi sono stati recuperati correttamente", 'success');
                }
            }).catch(function (err) {
                notification("Errore nel recupero dell'elenco degli elementi ", 'danger');
            })
        },
        onGetRestaurants: (token) => {
            getRestaurantList(token).then(result => {
                if (result.data.success) {
                    var payload = result.data;
                    dispatch({ type: RESTAURENT_LIST, payload });
                    console.log('Recupero dei dati del ristorante riuscito');
                }
            }).catch(function (err) {
                notification("Errore durante il recupero del ristorante ", 'danger');
            })
        },
        onGetCategory: (token) => {
            getAllCategories(token).then(result => {
                if (result.data.success) {
                    var payload = result.data;
                    dispatch({ type: CATEGORY_LIST, payload });
                    console.log('Recupero di tutte le categorie riuscito');
                }
            }).catch(function (err) {
                notification("Errore nella categoria di recupero ", 'danger');
            })
        },

        async onGetSingleItem(id) {
            await getItemById(id).then(result => {
                if (result.data.success) {
                    notification("Recupero dei dati dei singoli elementi correttamente", 'success');
                    var payload = result.data;
                    dispatch({ type: SINGLE_ITEM, payload })
                }
            }).catch(function (err) {
                notification("Errore durante il recupero dei dati dei singoli elementi ", 'danger');
            })
        },

        onDelete: (token, id) => {
            deleteItem(token, id).then(result => {
                if (result.data.success) {
                    notification("Elementi eliminati con successo", 'success');
                    getItems(token).then(result => {
                        if (result.data.success) {
                            var payload = result.data;
                            dispatch({ type: ITEMS_LIST, payload });
                            console.log('Tutti gli elenchi di elementi sono stati recuperati correttamente');
                        }
                    }).catch(function (err) {
                        notification("Errore nel recupero dei dati degli elementi ", 'danger');
                    })
                }
            }).catch(function (err) {
                notification("Errore durante il recupero dei dati Elimina elemento ", 'danger');
            })
        },

        onGetRestItem: (id) => {
            getRestItem(id).then(result => {
                if (result.data.success) {
                    notification("Recupero dell'oggetto a riposo riuscito", 'success');
                    var payload = result.data;
                    dispatch({ type: RESTAURANT_ITEMS, payload });
                }
            }).catch(function (err) {
                notification("Errore durante il recupero dei dati Elimina elemento ", 'danger');
            })
        },
        onGetRestCategory: (id) => {
            getRestaurantCategories(id).then(result => {
                if (result.data.success) {
                    var payload = result.data;
                    dispatch({ type: RESTAURANT_CATEGORY_LIST, payload });
                    notification("Tutti gli elenchi delle categorie di ristoranti sono stati recuperati correttamente", 'success');
                }
            }).catch(function (err) {
                notification("Errore durante il recupero dei dati della categoria ristorante ", 'danger');
            })
        },

        onGetCatItem: (id) => {
            getCatItem(id).then(result => {
                if (result.data.success) {
                    var payload = result.data;
                    dispatch({ type: CATEGORY_ITEMS, payload });
                    notification("Recupero dell'elenco di elementi della categoria riuscito", 'success');
                }
            }).catch(function (err) {
                notification("Errore nel recupero dei dati dell'elemento della categoria ", 'danger');
            })
        },
        onGetAllergenByRestId: (id) => {
            getRestAllergen(id).then(result => {
                if (result.data.success) {
                    var payload = result.data;
                    dispatch({ type: RESTAURANT_OF_ALLERGEN, payload });
                    notification("Riposo Recupero dell'allergene riuscito", 'success');
                }
            }).catch(function (err) {
                notification("Errore durante il recupero dei dati sugli allergeni residui ", 'danger');
            })
        },
        onGetAllergenByItemId: (id, rest_id) => {
            dispatch({ type: CLEAR_ITEM_ALLERGEN });
            getAllergenByItemId(id, rest_id).then(result => {
                if (result.data.success) {
                    var payload = result.data;
                    dispatch({ type: ITEM_ALLERGEN, payload });
                    notification("Recupero dell'allergene dell'oggetto riuscito", 'success');
                }
            }).catch(function (err) {
                notification("Errore durante il recupero dei dati sugli allergeni dell'articolo ", 'danger');
            })
        },

    }
}


class ItemsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            AddModalShow: false,
            EditModalShow: false,
            update_id: '',
            RestFilter: false,
            CatFilter: false,
            AddAllergenModal: false,
            ViewAllergenModal: false,
            item_id: '',
            rest_id: ''
        }

        this.handleDelete = this.handleDelete.bind(this);
        this.handleCreateItem = this.handleCreateItem.bind(this);
        this.handleEditItem = this.handleEditItem.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleSelectCat = this.handleSelectCat.bind(this);
    }

    componentDidMount() {
        const token = checkToken(this.props.app.token);
        this.props.onGetItems(token);
        this.props.onGetRestaurants(token);
        this.props.onGetCategory(token);
    }

    handleDelete(id) {
        confirmAlert({
            title: 'Conferma per eliminare',
            message: 'Sei sicuro di voler cancellare questo.',
            buttons: [
                {
                    label: 'sì',
                    onClick: () => this.props.onDelete(this.props.app.token, id)
                },
                {
                    label: 'No',
                    onClick: () => console.log("closed")
                }
            ]
        });
    }

    handleCreateItem() {
        this.setState({ AddModalShow: !this.state.AddModalShow });
        this.props.onGetRestaurants(this.props.app.token);
        this.props.onGetCategory(this.props.app.token);
    }

    handleSelect = (e) => {
        var rest_id = e.target.value;
        if (rest_id != '' && rest_id != undefined) {
            this.setState({ CatFilter: false });
            this.setState({ RestFilter: true });
            this.props.onGetRestItem(rest_id);
            this.props.onGetRestCategory(rest_id);

        } else {
            this.setState({ RestFilter: false });
            this.props.onGetCategory(this.props.app.token);
            this.props.onGetItems(this.props.app.token);
        }
    }

    handleSelectCat = (e) => {
        var cat_id = e.target.value;
        if (cat_id != '' && cat_id != undefined) {
            this.setState({ CatFilter: true });
            this.props.onGetCatItem(cat_id);
        } else {
            this.setState({ CatFilter: false });
            this.props.onGetItems(this.props.app.token);
        }
    }

    handleEditItem(id) {
        var that = this;
        this.props.onGetSingleItem(id).then(function () {
            that.setState({ EditModalShow: !that.state.EditModalShow });
        });
    }

    handleViewAllergen = (id) => {
        this.setState({ ViewAllergenModal: !this.state.ViewAllergenModal });
        this.setState({ item_id: id });
    }
    handleAddAllergen = (item_id, rest_id) => {
        this.setState({ AddAllergenModal: !this.state.AddAllergenModal });
        this.setState({ item_id: item_id });
        this.setState({ rest_id: rest_id });
        this.props.onGetAllergenByRestId(rest_id);
        this.props.onGetAllergenByItemId(item_id, rest_id);
    }

    render() {
        let itemsList = [];
        let restlist = [];
        let catlist = [];


        if (this.props) {
            if (this.props.item && !this.state.RestFilter && !this.state.CatFilter) {
                itemsList = this.props.item;
            } else if (this.props.restitems && this.state.RestFilter && !this.state.CatFilter) {
                itemsList = this.props.restitems;
            } else if ((this.props.catitems && this.state.CatFilter) || (this.props.catitems && this.state.CatFilter && this.state.RestFilter)) {
                itemsList = this.props.catitems;
            }
        }

        if (this.props.restaurant.restaurant) {
            var rests = this.props.restaurant.restaurant;
            restlist = rests.map((rest, key) => (
                <option key={key} value={rest.RESTAURANT_ID}>{rest.NAME_ITALIAN}</option>
            ))
        }

        if (this.props.category) {
            if (this.props.category.restcat && this.state.RestFilter) {
                var cats = this.props.category.restcat;
                catlist = cats.map((cat, key) => (
                    <option key={key} value={cat.CATEGORY_ID}>{cat.CATEGORY_NAME_ITALIAN}</option>
                ))
            }
            else if (this.props.category.category) {
                var cats = this.props.category.category;
                catlist = cats.map((cat, key) => (
                    <option key={key} value={cat.CATEGORY_ID}>{cat.CATEGORY_NAME_ITALIAN}</option>
                ))
            }

        }

        return (
            <>
                <div className="w-full" style={{ minHeight: '100vh' }}>
                    <div className="px-3 py-4">
                        <div className="d-flex justify-content-between items-center">
                            <h4>Voce del Menu</h4>
                            <button type="button" className="btn btn-primary" onClick={() => this.handleCreateItem()}>
                                Aggiungi Voce di Menu
                            </button>
                        </div>
                        <div className="py-4" style={{ width: '45%' }}>
                            <h3>Ordina</h3>
                            <div className="py-3 d-flex justify-content-around items-center">
                                <div>
                                    <select className="form-control" name="rest_id" onChange={(e) => this.handleSelect(e)}>
                                        <option value="">Tutti i ristoranti</option>
                                        {
                                            restlist
                                        }
                                    </select>
                                </div>
                                <div>
                                    <select className="form-control" name="rest_id" onChange={(e) => this.handleSelectCat(e)}>
                                        <option value="" selected={!this.state.CatFilter}>Tutte le categorie</option>
                                        {
                                            catlist
                                        }
                                    </select>
                                </div>

                            </div>
                        </div>
                    </div>
                    <Table responsive className="align-items-center table-flush">
                        <thead className="thead-light">
                            <tr>
                                <th>SNo</th>
                                <th>Nome della Voce del Menu</th>
                                <th>Nome Ristorante</th>
                                <th>Nome Categoria</th>
                                <th>Prezzo</th>
                                <th>Disponibilità</th>
                                <th>Immagine</th>
                                <th>Azioni</th>
                            </tr>

                        </thead>
                        <tbody className="bg-light">
                            {

                                (itemsList && itemsList != '' && itemsList != undefined) ?
                                    itemsList.map((item, key) =>
                                        <tr key={key}>
                                            <td>{key + 1}</td>
                                            <td>{item.ITEM_NAME_ITALIAN}</td>
                                            <td>{item.NAME_ITALIAN}</td>
                                            <td>{item.CATEGORY_NAME_ITALIAN}</td>
                                            <td>{item.PRICE}</td>
                                            {(item.AVAILABLE != 1) ? <td style={{ color: 'red' }}>No</td> : <td style={{ color: 'green' }}>Yes</td>}
                                            <td><img src={`${item.IMG_ITEM?process.env.REACT_APP_API_URL + item.IMG_ITEM:'/demo.png'}`} alt="item-image" style={{ width: '100px', height: "100px" }} /></td>
                                            <td>
                                                <a className="btn btn-success py-2 px-3 m-2" onClick={() => this.handleEditItem(item.ITEM_ID)}>Modifica</a>
                                                <a className="btn btn-danger py-2 px-3" onClick={() => this.handleDelete(item.ITEM_ID, item.RESTAURANT_ID)}>Elimina</a>
                                                <a className="btn btn-success py-2 px-3 m-2" onClick={() => this.handleAddAllergen(item.ITEM_ID, item.RESTAURANT_ID)}>Aggiungi/visualizza/Allergeni</a>
                                            </td>
                                        </tr>) : (<tr><th colSpan={9} className="text-center">Nessun record disponibile</th></tr>)
                            }

                        </tbody>
                    </Table>
                    <BootstrapModals component={<CreateItem onClose={() => this.setState({ AddModalShow: !this.state.AddModalShow })} />} heading="Aggiungi Voce di Menu" show={this.state.AddModalShow}
                        onHide={() => this.setState({ AddModalShow: !this.state.AddModalShow })} className="item_create_modal" />
                    <BootstrapModals component={<EditItem edit={this.state.update_id} onClose={() => this.setState({ EditModalShow: !this.state.EditModalShow })} />} heading="Modifica Voce di Menu" show={this.state.EditModalShow}
                        onHide={() => this.setState({ EditModalShow: !this.state.EditModalShow })} className="item_update_modal" />
                    <BootstrapModals component={<CreateAllergenMap itemId={this.state.item_id} restId={this.state.rest_id} onClose={() => this.setState({ AddAllergenModal: !this.state.AddAllergenModal })} />} heading="Aggiungi Allergeni" show={this.state.AddAllergenModal}
                        onHide={() => this.setState({ AddAllergenModal: !this.state.AddAllergenModal })} />

                </div>
            </>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemsList);
