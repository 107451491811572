
import React from "react";
import { Col, Row, Form, Button, Container } from '@themesberg/react-bootstrap';
import BgImage from "../../assets/img/illustrations/signin.svg";
import { connect } from "react-redux";
import { updateAdmin, getAdminList } from "../../services/services";
import { ADMIN_LIST } from "../../constants/actions";
import { reduxForm, Field } from "redux-form";
import { renderEditField, dobCheck, renderDatePickerEditField, setCalendarDate, notification, textAreaField } from "../../services/utileServices";
import { getSessionItem } from "../../helpers/sessionHelper";
import Loader from "react-js-loader";

const mapStateToProps = state => {
  return {
    ...state.login,
    app: state.app,
    singleAdmin: state.adminlist
  }
}

const mapDispatchToProps = dispatch => {
  return {
    async OnAdminUpdate(token, payload) {
      await updateAdmin(token, payload).then(result => {
        if (result.data.success) {
          notification('Amministratore aggiornato correttamente', 'success');
          getAdminList().then(result => {
            if (result.data.success) {
              var payload = result.data;
              dispatch({ type: ADMIN_LIST, payload });
              console.log('admin data fetched');
            }
          }).catch(function (err) {
            notification("Errore durante il recupero dei dati dell'amministratore " + err.message, 'danger');
          });
        }
      }).catch(function (err) {
        notification("Errore nell'aggiornamento dell'amministratore " + err.message, 'danger');
      })
    },
  }
}

class EditAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dob: '',
      address: '',
      loader: false
    }
    this.onSubmitting = this.onSubmitting.bind(this);
    this.handleDateClick = this.handleDateClick.bind(this);
    this.handleTextArea = this.handleTextArea.bind(this);
  }

  componentDidMount() {
    if (this.props.singleAdmin.admindata) {
      var singleData = this.props.singleAdmin.admindata;
      this.props.autofill('fname', singleData.FIRST_NAME);
      this.props.autofill('lname', singleData.LAST_NAME);
      this.props.autofill('id', singleData.ID);
      this.props.autofill('phone', singleData.MOBILE_NO);
      this.props.autofill('address', singleData.ADDRESS);
      this.props.autofill('dob', singleData.DOB);
      this.setState({ dob: singleData.DOB });
      this.setState({ address: singleData.ADDRESS });
    }
  }

  onSubmitting = (values) => {
    this.setState({ loader: true });
    var that = this;
    var reg_form = { ID: values.id, FIRST_NAME: values.fname, LAST_NAME: values.lname, DOB: this.state.dob, MOBILE_NO: values.phone, ADDRESS: values.address }
    if (this.props.app.token != undefined) {
      this.props.OnAdminUpdate(this.props.app.token, reg_form).then(function () {
        that.setState({ loader: false });
        that.props.onClose();
      });
    } else {
      const token = getSessionItem().token;
      this.props.OnAdminUpdate(this.props.app.token, reg_form).then(function () {
        that.setState({ loader: false });
        that.props.onClose();
      });
    }
  }

  handleTextArea = (e) => {
    var add = e.target.value;
    this.setState({ address: add });
  }

  handleDateClick = (e) => {
    var datestring = setCalendarDate(new Date(e));
    this.setState({ dob: datestring });
  }

  render() {
    const { handleSubmit, submitting, error } = this.props;

    return (
      <main>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h3 className="mb-0">Modifica Amministratore</h3>
                  </div>
                  {!this.state.loader ?
                    <Form className="mt-4" onSubmit={handleSubmit(this.onSubmitting)}>
                      {error && <span className='alert-danger'>{error}</span>}
                      <Form.Group id="fname" className="mb-4">
                        <Field name="id" component={renderEditField} type="hidden" />
                        <Form.Label>Nome </Form.Label>
                        <Field name="fname" component={renderEditField} type="text" label="Nome" />
                      </Form.Group>
                      <Form.Group id="lname" className="mb-4">
                        <Form.Label>Cognome</Form.Label>
                        <Field name="lname" component={renderEditField} type="text" label="Cognome" />
                      </Form.Group>
                      <Form.Group id="dob" className="mb-4">
                        <Form.Label>Data di nascita</Form.Label>
                        <Field label="Data di nascita" component={renderDatePickerEditField} name="dob" dataValue={this.state.dob} validate={[dobCheck]} onChange={(e) => this.handleDateClick(e)} />
                      </Form.Group>
                      <Form.Group id="phone" className="mb-4">
                        <Form.Label>Numero di Cellulare</Form.Label>
                        <Field name="phone" component={renderEditField} type="text" label="Numero di Cellulare" />
                      </Form.Group>
                      <Form.Group id="address" className="mb-4">
                        <Form.Label>Indirizzo</Form.Label>
                        <Field name="address" component={textAreaField} label="Indirizzo" dataValue={this.state.address} onChange={(e) => this.handleTextArea(e)} />
                      </Form.Group>

                      <Button variant="primary" disabled={submitting} type="submit" className="w-100">
                        Applica
                      </Button>
                    </Form> : <div className="d-flex align-items-center justify-content-center" style={{ height: '100vh' }}><Loader type="bubble-scale" bgColor={"#BE1622"} color={'#BE1622'} size={100} /></div>}

                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    );
  }
};

EditAdmin = connect(mapStateToProps, mapDispatchToProps)(EditAdmin);

export default reduxForm({
  form: 'admin update form'
})(EditAdmin);
